require(['highstock','angular'], function (Highcharts) {

    // Load module after Highcharts is loaded
    require('highcharts-more')(Highcharts);
    require('highcharts-3d')(Highcharts);
    require('highcharts-exporting')(Highcharts);
    require('highcharts-treemap')(Highcharts);
    require('highcharts-variable-pie')(Highcharts);
    require('highcharts-drilldown')(Highcharts);

    Highcharts.setOptions({
        lang: {
            numericSymbols: ['K', 'M', 'B','T']
        }
    });
    //TODO: MOVE TO GLOBAL FUNCTIONS


    function offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
    }


    function numberFormater(_value) {

        var neg = false;


        if (_value < 0) {

            _value = Math.abs(_value);
            neg = true;
        }

        var num = _value;
        var digits = 1;
        var si = [{
                value: 1E18,
                symbol: "E"
            },
                {
                    value: 1E15,
                    symbol: "P"
                },
                {
                    value: 1E12,
                    symbol: "T"
                },
                {
                    value: 1E9,
                    symbol: "B"
                },
                {
                    value: 1E6,
                    symbol: "M"
                },
                {
                    value: 1E3,
                    symbol: "k"
                }
            ],
            rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
            i;

        for (i = 0; i < si.length; i++) {
            if (num >= si[i].value) {
                var newNum = (neg) ? "-" + (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol : (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
                return newNum;
            }
        }


        num = num.toFixed(digits).replace(rx, "$1");

        return num;


    }

    var ngModule = angular.module('slimScroll', []);
    ngModule.value("slimScrollConfig", {});

    angular.module('app.directives', [])

        .directive('hcBarChart', ['$document', '$timeout', '$window', 'workspaceFactory', function ($document, $timeout, $window, workspaceFactory) {
            return {
                restrict: 'E',
                template: '<div ng-if="settings.loading" >loading</div><div ng-if="!settings.loading" class="chart-container"></div>',
                scope: {
                    series: '=',
                    settings: '=',
                },
                link: function (scope, element) {


                    var width = element.parent().parent().innerWidth();
                    var height = null;


                    if (scope.series && scope.series[0].data && scope.settings.scaleHeight != false) {
                        height = scope.series[0].data.length * scope.settings.barHeight + scope.settings.chartHeightPadding;
                    }


                    function numberFormater(_value) {

                        var num = _value;
                        var digits = 1;
                        var si = [
                            {value: 1E18, symbol: "E"},
                            {value: 1E15, symbol: "P"},
                            {value: 1E12, symbol: "T"},
                            {value: 1E9, symbol: "B"},
                            {value: 1E6, symbol: "M"},
                            {value: 1E3, symbol: "k"}
                        ], rx = /\.0+$|(\.[0-9]*[1-9])0+$/, i;
                        for (i = 0; i < si.length; i++) {
                            if (num >= si[i].value) {
                                return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
                            }
                        }
                        return num.toFixed(digits).replace(rx, "$1");


                    }


                    Highcharts.setOptions({
                        lang: {
                            numericSymbols: scope.settings.numericSymbols,
                            thousandsSeparator: ','
                        }
                    });


                    var chart = new Highcharts.chart(element[0], {
                        chart: {
                            type: 'bar',
                            width: width,
                            height: height
                        },
                        lang: {
                            thousandsSep: ','
                        },
                        title: {
                            text: ''
                        },
                        subtitle: {
                            text: ''
                        },
                        xAxis: {
                            type: 'category',
                            lineWidth: 2,
                            min: 0,
                            offset: 10,
                            categories: scope.settings.xAxisCategories
                        },
                        yAxis: {
                            min: 0,
                            labels: {
                                y: 25,

                                formatter: function () {

                                    if (scope.settings.yAxisFormat && scope.settings.yAxisFormat === 'percent') {
                                        return Highcharts.numberFormat(this.value, 0, ',') + '%';
                                    } else {

                                    }

                                }
                            },
                            title: {
                                text: scope.settings.title
                            }

                        },


                        legend: {
                            enabled: true
                        },
                        tooltip: {
                            pointFormat: ' <b>{point.y:.1f}</b>',
                            borderWidth: 1,
                            borderColor: '#AAA',

                            formatter: function () {


                                var extender = "";
                                var num = this.y;

                                ////console.log(this.point);

                                if (typeof this.point.pbt !== 'undefined') {
                                    var pbt = numberFormater(this.point.pbt)
                                    extender = ' - PBT: ' + pbt;
                                }

                                return this.key + ' : ' + numberFormater(num) + extender;
                            }

                        },


                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: scope.settings.dataLabels,
                                    formatter: function () {
                                        //////console.log("=====================" , Highcharts, this);
                                        var num = this.y;
                                        var digits = 1;
                                        var si = [
                                            {value: 1E18, symbol: "E"},
                                            {value: 1E15, symbol: "P"},
                                            {value: 1E12, symbol: "T"},
                                            {value: 1E9, symbol: "B"},
                                            {value: 1E6, symbol: "M"},
                                            {value: 1E3, symbol: "k"}
                                        ], rx = /\.0+$|(\.[0-9]*[1-9])0+$/, i;
                                        for (i = 0; i < si.length; i++) {
                                            if (num >= si[i].value) {
                                                return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
                                            }
                                        }
                                        return num.toFixed(digits).replace(rx, "$1");
                                    }
                                },


                            },


                            series: {
                                // pointWidth: 15,
                                //  pointPadding: 15,
                                // groupPadding: 15,
                                cursor: 'pointer',
                                point: {
                                    events: {
                                        click: function () {


                                            var gridFilters = [];
                                            if (scope.settings.drillDown) {

                                                var gridFilters = [];
                                                var screen_key = scope.settings.drillDown.screen_key;
                                                var dataObj = scope.settings.drillDown;

                                                var index = _.findIndex(workspaceFactory.screens, {'screen_key': screen_key});
                                                var _value = this.name;


                                                ////console.log("scope.series" ,scope.series)

                                                ////console.log("this ------------------" ,this.options)


                                                var options = this.options;

                                                _.forEach(dataObj.filters, function (value, key) {


                                                    ////console.log( "value " , value);
                                                    ////console.log( "key " , key);
                                                    ////console.log( "options[value] " , options[value]);


                                                    if (options && options[value]) {

                                                        var dataValue = options[value];
                                                        var obj = {};
                                                        obj.predicate = value;
                                                        obj.value = [];
                                                        obj.type = "array";
                                                        obj.reverse = false;
                                                        obj.operator = "[]";
                                                        obj.value.push(dataValue);
                                                        gridFilters.push(obj);

                                                    }

                                                    /*
                                                    if( scope.settings.drillDownFunction ){
                                                        dataValue = scope.settings.drillDownFunction(_value,key,this);
                                                    }
                                                */


                                                });

                                                if (index >= 0) {
                                                    workspaceFactory.loadDrillDown(workspaceFactory.screens[index], gridFilters);
                                                }
                                            }

                                        }
                                    }
                                }


                            }

                        },


                        series: scope.series

                    });


                    scope.$watch('series', function (newVal, oldVal) {
                        $timeout(function () {
                            if (newVal) {
                                chart.series[0].setData(newVal[0].data, false);
                                resize();
                            }
                        }, 0);
                    }, true);


                    scope.$watch('settings.loading', function (val) {
                        if (val) {
                            chart.showLoading();
                        } else {
                            chart.hideLoading()
                        }
                    });


                    function resize() {
                        var width = element.parent().parent().innerWidth();
                        if (scope.series[0].data && scope.settings.scaleHeight != false) {
                            height = scope.series[0].data.length * scope.settings.barHeight + scope.settings.chartHeightPadding;
                        }
                        chart.setSize(width, height, doAnimation = true);
                    }


                    angular.element($window).bind('resize', function () {
                        resize();
                    });


                    scope.$on('$destroy', function () {
                        angular.element($window).unbind('resize');
                        angular.element($window);
                        if (chart) chart.destroy();
                        element.remove();
                    });


                    var width = element.parent().parent().innerWidth();
                    chart.setSize(width, height, doAnimation = true);

                }
            };
        }])





        // PIE CHART ---------------------------------------------------------------------------------------------------------------------------------------------------------

        .directive('hcPieChart', ['$document', '$timeout', '$window', 'workspaceFactory', '$rootScope', function ($document, $timeout, $window, workspaceFactory, $rootScope) {
            return {
                restrict: 'E',
                template: '<div ng-if="settings.loading" >loading</div><div ng-if="!settings.loading" class="chart-container"></div>',
                scope: {
                    series: '=',

                    settings: '=',
                },
                link: function (scope, element) {


                    console.log("===========================================............................=================", scope.series)


                    var width = element.parent().parent().innerWidth();
                    var window = angular.element($window);


                    var height = 200;
                    var size = 150;


                    var chart = new Highcharts.chart(element[0], {
                        chart: {
                            renderTo: 'container',
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: 'pie',
                            width: element.parent().parent().innerWidth(),
                            height: height
                        },

                        title: {
                            text: ''
                        },


                        legend: {
                            enabled: true
                        },


                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                size: '75%',
                                dataLabels: {
                                    enabled: true,
                                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                                    style: {
                                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                                    }
                                }
                            }
                        },

                        series: scope.series

                    });


                    /*
                     series: [{
                                            name: 'Brands',
                                            colorByPoint: true,
                                            data: null
                                        }]
                     */


                    scope.$watch('series', function (newVal, oldVal) {
                        $timeout(function () {
                            if (newVal) {

                                console.log("==================in the directive  ==============================", newVal)

                                chart.series[0].setData(newVal[0].data, false);
                                resize();
                                chart.redraw();
                            }
                        }, 0);
                    }, true);


                    scope.$watch('settings.loading', function (val) {
                        if (val) {
                            chart.showLoading();
                        } else {
                            chart.hideLoading();
                        }
                    });


                    var unregister = $rootScope.$on('dash-resize', function (evt, data) {
                        console.log("I heard resize");


                        $timeout(function () {
                            height = 300;
                            size = 200;
                            resize();
                        }, 110);


                    });


                    function resize() {
                        var width = element.parent().parent().innerWidth();


                        // var width = element.parent().parent().innerWidth();

                        /* if (scope.series[0].data && scope.settings.scaleHeight != false) {
                             height = scope.series[0].data.length * scope.settings.barHeight + scope.settings.chartHeightPadding;
                         }
                        */

                        // chart.setSize(1000, 1000, true);

                        chart.setSize(width, height, false);
                        chart.reflow();
                    }


                    window.bind('resize', resize);


                    scope.$on('$destroy', function () {
                        unregister();
                        window.unbind('resize', resize);
                        angular.element($window);
                        if (chart) chart.destroy();
                        chart = null;
                        element.remove();
                    });


                }
            };
        }])





        // COLUMN CHART ---------------------------------------------------------------------------------------------------------------------------------------------------------



        .directive('hcColumnChart', ['$rootScope', '$document', '$timeout', '$window', 'workspaceFactory', function ($rootScope, $document, $timeout, $window, workspaceFactory) {
            return {
                restrict: 'E',
                template: '<div ng-if="settings.loading" >loading</div><div ng-if="!settings.loading" class="chart-container"></div>',
                scope: {
                    series: '=',
                    settings: '=',
                },
                link: function (scope, element) {


                    var width = element.parent().parent().innerWidth();
                    var window = angular.element($window);

                    //console.log("scope.settings " , scope.settings.xAxis.categories);


                    Highcharts.setOptions({
                        lang: {
                            numericSymbols: scope.settings.numericSymbols,
                            thousandsSeparator: ','
                        }
                    });


                    var chart = new Highcharts.chart(element[0], {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: ''
                        },
                        xAxis: {
                            categories: scope.settings.xAxis.categories
                        },

                        series: scope.series,

                        credits: {
                            enabled: false
                        },
                        plotOptions: {

                            series: {
                                pointWidth: 20,

                                borderWidth: 0,
                                dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                        var num = this.y;
                                        return numberFormater(num);
                                    }
                                }

                            },


                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true,
                                    color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                                }
                            }
                        },


                        labels: {
                            enabled: true,
                            formatter: function () {
                                return this.axis.defaultLabelFormatter.call(this) + 'M';
                            }
                        },

                    });

                    scope.$watch('settings.loading', function (val) {
                        if (val) {
                            chart.showLoading();
                        } else {
                            chart.hideLoading();
                        }
                    });

                    var unregister = $rootScope.$on('dash-resize', function (evt, data) {
                        $timeout(function () {
                            resize();
                        }, 110);
                    });


                    function resize() {
                        var width = element.parent().parent().innerWidth();
                        var height = 390;
                        if (chart) {
                            chart.setSize(width, height, doAnimation = true);
                        }
                    }


                    window.bind('resize', resize);


                    scope.$on('$destroy', function () {
                        unregister();
                        window.unbind('resize', resize);
                        angular.element($window);
                        if (chart) chart.destroy();
                        chart = null;
                        element.remove();
                    });


                }
            };
        }])


        /////////////////////////--- Profile-Image Directive---//////////////////////////////

        .directive('profileImage', function () {
            var imagekSrc = {
                link: function postLink(scope, iElement, iAttrs) {
                    setTimeout(function () {
                        var applyProfileLogo = function () {
                            setTimeout(function () {
                                var displayNm = iElement.attr('display_name');
                                if (!displayNm)
                                    return;
                                var height = iElement.attr('height');
                                var width = iElement.attr('width');
                                var textFill = iElement.attr('textFill');
                                height = height ? height : 45;
                                width = width ? width : 45;
                                textFill = textFill ? textFill : '#fff';
                                var cx = iElement.attr('cx');
                                var cy = iElement.attr('cy');
                                cx = cx ? cx : 22.5;
                                cy = cy ? cy : 22.5;
                                var r = iElement.attr('r');
                                r = r ? r : 22.5;
                                var font_size = iElement.attr('font-size');
                                font_size = font_size ? font_size : 20;
                                // font-size="20"
                                var x = iElement.attr('x');
                                var y = iElement.attr('y');
                                x = x ? x : 50 + '%';
                                y = y ? y : 54 + '%';
                                var fill = iElement.attr('fill');
                                if (['a', 'b', 'm'].indexOf((displayNm.substring(0, 1)).toLowerCase()) > -1) {
                                    fill = '#33cc33';
                                } else if (['n', 's', 'h'].indexOf((displayNm.substring(0, 1)).toLowerCase()) > -1) {
                                    fill = '#8000ff';
                                } else {
                                    /*  fill = '#29c7f7'; */
                                    fill = fill ? fill : '#cd5763';
                                }
                                //fill = fill ? fill : '#29c7f7';style="margin-top:5px"
                                if (displayNm.indexOf(' ') > -1)
                                    displayNm = displayNm.split(' ')[0].slice(0, 1) + displayNm.split(' ')[1].slice(0, 1);
                                else
                                    displayNm = displayNm.slice(0, 1);
                                var svgImg = `<svg height="` + height + `" width="` + width + `">
                                                <circle cx="` + cx + `" cy="` + cy + `" r="` + r + `" stroke-width="3" fill="` + fill + `" />
                                                <text fill="` + textFill + `" font-size="` + font_size + `"font-family="Arial"  x="` + x + `" y="` + y + `"  alignment-baseline="middle" text-anchor="middle">
                                                      ` + displayNm + `</text>
                                          </svg>`;
                                $(svgImg).insertAfter(iElement);
                                iElement.hide();
                            }, 200);
                        }
                        iElement.bind('error', function () {
                            //angular.element(this).attr("src", 'assets/img/default-user-icon.png');
                            applyProfileLogo();
                        });
                        // || !iElement.attr('display_name')
                        if (iElement.attr('profile-image')) {
                            iElement.attr("src", iElement.attr('profile-image')).addClass('img-circle');
                        } else {
                            applyProfileLogo();
                        }
                    }, 200);
                }
            }
            return imagekSrc;
        })


        .controller('gtDashboardCtrl', ['$rootScope', function ($rootScope) {

            this.open = 'true';
            this.expandAll = function () {
                this.open = true;
                $rootScope.$broadcast('showHideAll', true);
            }
            this.collapseAll = function () {
                this.open = false
                $rootScope.$broadcast('showHideAll', false);
            }
        }])


        .directive('gtDashboardControls', ['$compile', '$parse', '$state', '$rootScope', function ($compile, $parse, $state, $rootScope) {
            return {
                restrict: 'E',
                controller: 'gtDashboardCtrl',
                controllerAs: 'ctrl2',
                template: '<div gt-dashboard-container ng-click="ctrl2.expandAll()" class="btn btn-default"> Open <i class="fa fa-plus-square"></i> </div> ' +
                    '<div ng-click="ctrl2.collapseAll()" class="btn btn-default"> Close <i class="fa fa-minus-square"></i> </div>',
                replace: false,
                link: function (scope, element, attrs) {

                }
            }
        }])


        .directive('gtDashboardContainer', ['$document', '$timeout', 'workspaceFactory', '$rootScope', function ($document, $timeout, workspaceFactory, $rootScope) {
            return {
                restrict: 'E',
                controller: 'gtDashboardCtrl',
                controllerAs: 'ctrl2',
                scope: {
                    'gtTitle': '=',
                    'openClose': '='
                },
                template: '<div class="gt-dashboard"> ' +
                    '<div class="gt-dashboard-header"> ' +
                    '<h4 class="pull-left"> {{gtTitle}} </h4> ' +
                    '<div class="btn pull-right" ng-click="showHide()"><i ng-class="{\'fa fa-minus-square\' : showBody  ,\'fa fa-plus-square\' : !showBody  }"  ></i> </div> ' +
                    '<div class="btn pull-right"><i class="fa fa-cog "></i> </div> ' +
                    '</div> ' +
                    '<div class="gt-dashboard-body" ng-show="showBody"> ' +
                    '<ng-transclude></ng-transclude> ' +
                    '</div> ' +
                    '<div class="gt-dashboard-footer"> ' +
                    '</div> ' +
                    '</div>',
                transclude: true,
                link: function (scope, element, attr, ctrl) {
                    scope.showBody = true;
                    scope.showHide = function () {
                        scope.showBody = !scope.showBody;
                    }


                    var unregister = $rootScope.$on('showHideAll', function (evt, data) {
                        scope.showBody = data;
                    });


                }
            };
        }])


        .directive('gtDashboardContainerEx', ['$document', '$timeout', 'workspaceFactory', '$rootScope','USER_SETTINGS',
        function ($document, $timeout, workspaceFactory, $rootScope,USER_SETTINGS) {
            return {
                restrict: 'E',
                controller: 'gtDashboardCtrl',
                controllerAs: 'ctrl2',
                replace: true,
                scope: {
                    'gtTitle': '=',
                    'openClose': '=',
                    'gtHeight': '=',
                    'settings': '=',
                    'category': '@',
                    'sheetData': '=',
                    'groupBy': '=',
                    'isCorner': '=',
                    'parentTableColumnProperties': '=',
                    'actions': '=',
                    'component': '='
                },
                template: '<div class="gt-dashboard-ex collapsed" ng-style="{height:gtHeight}"> ' +
                    '<div class="gt-dashboard-ex-header"> ' +
                    '<h4 class="pull-left" title="{{gtTitle}}"> {{gtTitle}} </h4> ' +
                    '<div class="btn pull-right" ng-click="expandClose($event)"><i ng-class="{\'fa fa-times\' : expand  ,\'fa fa-expand\' : !expand  }"  ></i> </div> ' +
                    // '<div class="btn pull-right" uib-popover-template="\'dashboardContainerSettings.html\'" popover-placement="left" popover-title="Settings" popover-trigger="\'outsideClick\'"><i class="fa fa-cog "></i> </div> ' +
                    // '<div class="btn pull-right"><i class="fa fa-cog "></i> </div> ' +
                    '<div class="btn pull-right" ng-if="!expand" ng-click="toggleCollapse(!collapse)" uib-tooltip="Expand/Collapse Container" tooltip-placement="left" tooltip-trigger="mouseenter"><i class="fa fa-plus-square" ng-class="{\'fa fa-plus-square\' : collapse  ,\'fa fa-minus-square\' : !collapse  }"></i></div> ' +
                    '<div ng-if="actions" ng-repeat="action in actions | reverse" class="btn pull-right" ng-click="action.onClick(component)" uib-tooltip="{{action.title}}" tooltip-placement="left" tooltip-trigger="mouseenter"><i class="fa" ng-class="action.icon"></i></div>' +
                    '</div>' +
                    '<div class="gt-dashboard-ex-body"> ' +
                    '<ng-transclude></ng-transclude> ' +
                    '</div> ' +
                    '<div class="gt-dashboard-ex-footer"> ' +
                    '</div> ' +
                    '</div>',
                transclude: true,
                link: function (scope, element, attr, ctrl) {
                    scope.expand = false;
                    scope.config = {};
                    scope.safeHeightCopy = scope.gtHeight;
                    scope.marginTop = 0;
                    var initialWidth;
                    var initialHeight;
                    USER_SETTINGS.user.user_type !== "Super Admin" && _.remove(scope.actions,action=>action.adminOnly);
                    initContainer();

                    function initContainer() {
                        $(element).height(scope.safeHeightCopy);
                    }

                    scope.toggleSettings = function (event) {
                        if (scope.expand) {
                            $(element[0]).find('.popover').addClass('popover-full');
                            $(element[0]).find('.arrow').addClass('popover-arrow-expand')
                        } else {
                            if ($(element[0]).find('.popover-full')) {
                                $(element[0]).find('.popover').removeClass('popover-full');
                                $(element[0]).find('.arrow').removeClass('popover-arrow-expand');
                            }
                        }
                    }
                    scope.toggleCollapse = function (collapse) {
                        console.log('collapse: ', collapse)

                        if (scope.collapse === collapse) {
                            return;
                        }

                        scope.collapse = collapse;
                        if (collapse) {
                            scope.actualHeight = $(element).height();
                            $(element).animate({
                                height: 40
                            }, 500, function () {
                                $(element).css({"overflow": "hidden"})
                            });


                            // scope.gtHeight = 40;
                            // scope.marginTop = -parseInt(scope.safeHeightCopy);
                        } else {

                            $(element).animate({
                                height: scope.actualHeight
                            }, 500, function () {
                                $(element).css({"overflow": ""})
                            });

                            // scope.gtHeight = parseInt(scope.safeHeightCopy);
                            // scope.marginTop = 0;
                        }
                    }

                    $rootScope.$on('containerCollapseToggle', function (event, collapse) {
                        scope.toggleCollapse(collapse);
                    })
                    scope.selectChartType = function (chartType) {
                        if (scope.settings.chart.type !== chartType) {
                            if (scope.expand) {
                                scope.settings.chart.width = $(element[0]).find('.highcharts-container').width();
                                scope.settings.chart.height = $(element[0]).find('.highcharts-container').height();
                                scope.settings.chart.type = chartType;
                            } else {

                                scope.settings.chart.width = $(element[0]).find('.highcharts-container').width();
                                scope.settings.chart.height = scope.settings.chart.initialHeight
                                scope.settings.chart.type = chartType;
                            }
                        }
                    }

                    scope.selectTableType = function (type) {
                        scope.groupBy = type

                    }

                    if (scope.category === 'table') {
                        scope.config.tableType = scope.groupBy
                        scope.groupBySelectionList = [];// Only non revenue properties
                        scope.allGroupBySelectionList = [];//All revenue and non revenue properties
                        console.log('table', scope.settings)
                        scope.sheetDataKeys = _.keys(scope.sheetData[0]);


                        _.forEach(scope.sheetDataKeys, function (value, i) {


                            if (value !== 'object_id' && value !== 'AMOUNT' && value !== 'TAX_YEAR' && !scope.parentTableColumnProperties.aggregateColumns.includes(value)) {
                                var newValue = value.split("_").join(" ");
                                scope.groupBySelectionList.push({name: newValue, oldValue: value})
                            }

                        })


                    } else if (scope.category === 'multiSeries' || scope.category === 'singleSeries') {
                    }

                    scope.expandClose = function ($event) {
                        // $(element)
                        scope.expand = !scope.expand;

                        // var panel = $event.currentTarget.parentElement.parentElement;
                        console.log(element);
                        if (scope.expand) {
                            console.log('expand')
                            scope.originalPosition = $(element).offset();
                            scope.originalWidth = $(element).width();
                            scope.originalHeight = $(element[0]).outerHeight();
                            $(element).css({
                                position: 'fixed',
                                'z-index': 1000,
                                left: scope.originalPosition.left,
                                top: scope.originalPosition.top - $(window).scrollTop(),
                                width: scope.originalWidth,
                                height: scope.originalHeight,
                                'min-width':'unset'
                            });
                            console.log('start animation with width:' + scope.originalWidth + 'height: ' + scope.originalHeight);
                            $(element).animate({width: '100%', height: '100%', left: 0, top: 0, 'max-height': '1500px'}, 500);
                            // $(element[0]).find('.popover').addClass('popover-full');
                            $(element).removeClass('collapsed');
                        } else {
                            // $(element).width(scope.originalWidth)
                            $(element).animate({
                                position: 'fixed',
                                height: scope.originalHeight,
                                width: scope.originalWidth,
                                left: scope.originalPosition.left,
                                top: scope.originalPosition.top - $(window).scrollTop()
                            }, 500, function () {
                                console.log('end animation with width:' + scope.originalWidth + 'height: ' + scope.originalHeight);
                                $(element).css({
                                    position: 'relative',
                                    'z-index': 'unset',
                                    top: 0,
                                    left: 0,
                                    'max-height': 'unset',
                                    'min-width':'100%',
                                    width: 'unset',
                                    height: scope.originalHeight
                                });
                            });
                            $(element).addClass('collapsed');
                        }


                        //220 and 55 being the side bar and header sizes
                        // $(element).toggleClass('gt-dash-expand',1000)
                        // $(element).animate({  });

                        var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                        //  $rootScope.$broadcast('dash-resize', true);

                    }


                    var unregister = $rootScope.$on('showHideAll', function (evt, data) {
                        scope.showBody = data;
                    });

                }
            };
        }])


        .directive('gtBreadcrumb', ['$document', '$timeout', 'workspaceFactory', function ($document, $timeout, workspaceFactory) {
            return {
                restrict: 'aC',
                scope: {},
                template: '<ul>' +
                    '<li data-ng-repeat="label in breadcrumbArray track by $index"" ><i ng-show="$index != 0" class="fa fa-chevron-right"></i> {{ label }}</li></ul>' +
                    '' +
                    '<div class="gt-breadcrumb-sm">{{breadcrumbArray[breadcrumbArray.length -1]}}</div>',

                replace: false,
                link: function (scope, element, attr) {
                    scope.breadcrumbArray = [];
                    scope.$watch(function () {
                        return workspaceFactory.activeScreen
                    }, function (val) {
                        scope.breadcrumbArray = [];
                        buildBreadCrumbData(val)
                    })

                    function buildBreadCrumbData(val) {

                        scope.breadcrumbArray.push(val.label);

                        if (val.parent) {
                            buildBreadCrumbData(val.parent)
                        } else {
                            scope.breadcrumbArray.reverse();
                        }

                    }

                }
            };

        }])


        .controller('gtMenuScrollCtrl', ['$rootScope', 'workspaceFactory', '$uibModalInstance', function ($rootScope, workspaceFactory, $uibModalInstance) {

            var vm = this;
            vm.screens = workspaceFactory.screens;
            vm.title = 'Show / Hide Screens ';


            console.log(vm.screens);


            vm.showScreen = function (_screen) {
                _screen.show_tab = true;
                workspaceFactory.loadTab(_screen, {});
            }


            vm.hideScreen = function (_screen) {
                _screen.show_tab = false;
            }


            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };


            vm.showAll = function () {
                _.forEach(vm.screens, function (value, key) {
                    value.show_tab = true;
                });
            }


            vm.clearSearch = function () {

                vm.filterData.label = "";

            }


            vm.hideAll = function () {
                _.forEach(vm.screens, function (value, key) {
                    if (key !== 0) {
                        value.show_tab = false;
                    } else {
                        workspaceFactory.loadTab(value, {});
                    }
                });
            }


        }])


        .directive('gtMenuScrollDrop', ['$timeout', '$window', '$uibModal', 'workspaceFactory', function ($timeout, $window, $uibModal, workspaceFactory) {
            return {
                restrict: 'E',
                template: '  <li id="screen-drop-down" ng-show="hidden" ng-click="openModal()" ><i class="fa fa-caret-down"></i> <span class="badge badge-red">{{numScreens}}</span></li>',
                replace: true,

                scope: {
                    title: '@',
                    data: '='
                },


                link: function (scope, element) {
                    var vm = this;
                    var startItem = document.getElementsByClassName("gt-workspace-tabs")[0];
                    var TABID = "gt-scroll_item_";
                    scope.openModal = function () {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: 'app/components/workspace/workspace-screen-drop-tpl.html',
                            controller: 'gtMenuScrollCtrl',
                            controllerAs: 'ctrl',
                            windowClass: "modal-below-header",
                            size: 'lg'
                        });

                        modalInstance.result.then(function (selectedItem) {
                            scope.selected = selectedItem;
                        }, function () {

                        });
                    };

                    _.forEach(scope.screens, function (value, key) {
                        value.show_tab = true;
                    })

                    scope.$watch(function () {
                        return workspaceFactory.activity
                    }, function (val) {
                        scope.screens = workspaceFactory.screens;
                        scope.numScreens = scope.screens.length;
                        scope.hidden = (workspaceFactory.activity.show_drop) ? workspaceFactory.activity.show_drop : false;
                        if (typeof workspaceFactory.activity.screens_set === 'undefined') {
                            $timeout(function () {
                                setTabs();
                            });
                        }
                    })


                    function setTabs() {
                        scope.hidden = false;
                        var screen = document.getElementById("screen-drop-down");
                        var scrollWidth = document.body.clientWidth - (screen.offsetLeft + screen.offsetWidth);
                        var tabsWidth = 0


                        var activeScreenKey = workspaceFactory.activeScreen.screen_key;

                        _.forEach(scope.screens, function (value, key) {
                            var scrollElem = document.getElementById(TABID + value.screen_key);
                            value.tab_width = (scrollElem.offsetWidth > 0) ? scrollElem.offsetWidth : 0;
                            tabsWidth += value.tab_width

                        });

                        if (tabsWidth > scrollWidth) {
                            _.forEach(scope.screens, function (value, key) {

                                if (activeScreenKey == value.screen_key) {
                                    value.show_tab = true;
                                } else {
                                    value.show_tab = false;
                                }
                            });

                            scope.hidden = true;
                            workspaceFactory.activity.show_drop = true;
                        } else {
                            workspaceFactory.activity.show_drop = false;
                        }

                        workspaceFactory.activity.screens_set = true;

                    }

                }
            };
        }])


        .directive('format', ['$filter', function ($filter) {
            return {
                require: '?ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    if (!ctrl) return;

                    ctrl.$formatters.unshift(function (a) {
                        return $filter(attrs.format)(ctrl.$modelValue)
                    });

                    ctrl.$parsers.unshift(function (viewValue) {
                        // var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');           
                        var plainNumber = viewValue.toString().replace(/[^0-9.-]/g, '');

                        if (plainNumber == "-") {
                            return viewValue;
                        }
                        elem.val($filter(attrs.format)(plainNumber));
                        return plainNumber;
                    });
                }
            };
        }])


        .directive('ngReallyClick', ['$uibModal', 'GlobalService', function ($uibModal, GlobalService) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    element.bind('click', function () {
                        scope.header = attrs.ngReallyHeader;
                        scope.message = attrs.ngReallyMessage;
                        var isTypeOk = attrs.ngReallyOk;
                        var clickAction = attrs.ngReallyClick;
                        var clickCancel = attrs.ngReallyNo;
                        var isIssueChangeAction = attrs.isIssueChangeAction;
                        scope.globalIssue = attrs.globalIssue;
                        scope.rowIssue = attrs.rowIssueId;
                        if (isTypeOk) {
                            templateUrl = 'app/templates/modals/confirm-ok.html';
                        } else if(isIssueChangeAction) {
                            templateUrl = 'app/templates/modals/confirm-issue-change.html';
                        } else {
                            templateUrl = 'app/templates/modals/confirm-action.html';
                        }
                        scope.confirmModal = $uibModal.open({
                            templateUrl: templateUrl,
                            //backdrop: 'static',
                            scope: scope,
                            windowClass: 'custom',
                            controller: ['$scope', '$stateParams', '$uibModalInstance',
                                function ($scope, $stateParams, $uibModalInstance) {
                                    //  $scope.countdown = $scope.$parent.countdown;
                                    var audio = new Audio('assets/sound/voice_alert.mp3');
                                    audio.play();
                                    $scope.cancel = function () {
                                        if(clickCancel){
                                            $scope.$eval(clickCancel);
                                        }
                                        $uibModalInstance.close();
                                    }

                                    $scope.confirm = function () {
                                        $scope.$eval(clickAction)
                                        $uibModalInstance.close();
                                    }

                                    $scope.saveWithIssueId = function (isGlobalIssue) {
                                        if(isGlobalIssue){
                                            GlobalService.globalParams.issue_id_for_tx_save = attrs.globalIssue;
                                        } else {
                                            GlobalService.globalParams.issue_id_for_tx_save = attrs.rowIssueId;
                                        }                                        
                                        $scope.$eval(clickAction);
                                        $uibModalInstance.close();
                                    }

                                    $scope.$on('$destroy', function () {
                                        ////////////console.log("ngReally Destroyed");
                                    });

                                }
                            ]
                        });

                    });
                }
            }

        }])


        .directive('ngReallyChange', ['$injector', '$uibModal', function ($injector, $uibModal) {
            return {
                restrict: 'A',
                //                scope:{
                //                    callBackService: '=',
                //                    data: '='
                //                },
                link: function (scope, element, attrs) {
                    element.bind('change', function () {
                        //                    	callBackService    = attrs.callBackService;
                        //                    	var proxyService   = $injector.get(callBackService);
                        scope.header = attrs.ngReallyHeader;
                        scope.message = attrs.ngReallyMessage;
                        var clickAction = attrs.ngReallyChange;
                        scope.confirmModal = $uibModal.open({
                            templateUrl: 'app/templates/modals/confirm-action.html',
                            //backdrop: 'static',
                            scope: scope,
                            windowClass: 'custom',
                            controller: ['$scope', '$stateParams', '$uibModalInstance',
                                function ($scope, $stateParams, $uibModalInstance) {
                                    //  $scope.countdown = $scope.$parent.countdown;
                                    var audio = new Audio('assets/sound/voice_alert.mp3');
                                    audio.play();
                                    $scope.cancel = function () {
                                        //proxyService.changeSourceByOnPopUp(scope.data);
                                        $uibModalInstance.close();
                                    }

                                    $scope.confirm = function () {
                                        $scope.$eval(clickAction);
                                        $uibModalInstance.close();
                                    }

                                    $scope.$on('$destroy', function () {
                                        ////////////console.log("ngReally Destroyed");
                                    });

                                }
                            ]
                        });

                    });
                }
            }

        }])


        .factory('menuDataService', ['$state', '$parse', 'USER_MENU', '$timeout', function ($state, $parse, USER_MENU, $timeout) {
            var menuData = {};
            menuData.selected = null;

            function setActiveMenu(menu, activeState) {
                angular.forEach(menu, function (item) {
                    if ($parse('type')(item) === 'screen' && $parse('link')(item) === activeState) {
                        menuData.setSelected(item);
                    } else if (angular.isArray(item.children) || angular.isArray(item.screens)) {
                        setActiveMenu(item.children || item.screens, activeState)
                    }
                });
            }

            menuData.setData = function (_data) {
                mergeNonMatchedTaskHierachy(_data);
                buildMenuIds(_data);
                setTreeParent(_data, 'children');
                menuData.setOpen(_data, true, false);
                $timeout(function () {
                    if ($state.current && $state.current.name) {
                        setActiveMenu(_data, $state.current.name);
                    }
                }, 3000);
                return _data;
            }

            menuData.setOpen = function (menu, isOpen, isChildOpen) {
                if(menu && menu.length >= 0) {
                    menu.forEach(item => {
                        item.collapsed = !isOpen;
                        if (angular.isArray(item.children)) {
                            menuData.setOpen(item.children, isChildOpen, isChildOpen);
                        }
                    });
                }
            };


            menuData.pipe = function (_search) {
                this.toggleMenu(false);
                setTreeAttribute(menuData.data, 'hidden', 'children', false);
                filterTree(menuData.data, _search, 'label', 'children');
            }

            menuData.toggleMenu = function (value) {
                setTreeAttribute(menuData.data, 'collapsed', 'children', value);
            }


            menuData.setSelected = function (obj) {
                menuData.selected = obj;

                function setParent(obj) {
                    if (obj.parent) {
                        obj.parent.selected = true;
                        obj.parent.collapsed = false;
                        setParent(obj.parent);
                    }
                }

                setParent(obj);
            }

            menuData.removeSelected = function () {
                removeTreeAttribute(menuData.data, 'selected', 'children');
            }

            menuData.data = menuData.setData(USER_MENU);

            function buildMenuIds(jsonArray) {
                for (var i = 0; i < jsonArray.length; i++) {
                    jsonArray[i].menu_id = jsonArray[i].label.replace(/ /g, "-");
                    if (jsonArray[i].children !== undefined) {
                        buildMenuIds(jsonArray[i].children)
                    }
                }
            }

            function buildMenuIds(jsonArray) {
                for (var i = 0; i < jsonArray.length; i++) {
                    jsonArray[i].menu_id = jsonArray[i].label.replace(/ /g, "-");
                    if (jsonArray[i].children !== undefined) {
                        buildMenuIds(jsonArray[i].children)
                    }
                }
            }

            function mergeNonMatchedTaskHierachy(jsonAry) {
                var doProcess1 = function () {
                    for (var i = 0; i < jsonAry.length; i++) {
                        var chlObj = jsonAry[i];
                        process11(chlObj);
                        chlObj = process12(chlObj);
                    }
                }
                var process11 = function (chlObj) {
                    if (chlObj.children && chlObj.children.length) {
                        for (var c = 0; c < chlObj.children.length; c++) {
                            var childTaskObj = chlObj.children[c];
                            // check any parent key for the current task
                            // if present, add
                            var childObjList = _.filter(chlObj.children, {parentTaskKey: childTaskObj.id});
                            if (childObjList.length) {
                                childTaskObj.processed = true;
                                for (var i = 0; i < childObjList.length; i++) {
                                    var childObj = childObjList[i];
                                    if (childTaskObj.children)
                                        childTaskObj.children.unshift(childObj);
                                    else
                                        childTaskObj.children = [childObj];
                                    // process11(chlObj);
                                }
                            }
                        }
                    }
                }
                var process12 = function (chlObj) {
                    chlObj.children = _.filter(chlObj.children, {parentTaskKey: null});
                    return chlObj;
                }

                doProcess1();


            }


            var filterTree = function (collection, value, predicate, children) {
                for (var i = 0; i < collection.length; i++) {
                    var n = collection[i][predicate];
                    var checkChildren = true;

                    if (n) {

                        if (n && _.includes(n.toLowerCase(), value.toLowerCase())) {
                            ////////////console.log( n , " - stop here")
                            collection[i].hidden = false;
                            checkChildren = false;
                        } else {
                            collection[i].hidden = true;
                        }


                        if (checkChildren && collection[i][children] && collection[i][children].length) {
                            filterTree(collection[i][children], value, predicate, children)
                            var parentHidden = true;
                            var innerChildren = collection[i][children];
                            if (innerChildren.length) {
                                for (var p = 0; p < innerChildren.length; p++) {
                                    if (!innerChildren[p].hidden) {
                                        parentHidden = false;
                                    }
                                }
                            }

                            if (checkChildren) {
                                collection[i].hidden = parentHidden;
                            }
                        }
                    } //end  if( n  )


                } //end for

            }; //end filterTree

            var removeTreeAttribute = function (_collection, _predicate, _children) {
                for (var i = 0; i < _collection.length; i++) {
                    var n = _collection[i][_predicate];
                    var children = _collection[i][_children];
                    if (children && children.length) {
                        removeTreeAttribute(children, _predicate, _children)
                    }
                    if (n && _predicate) {
                        _collection[i][_predicate] = undefined;
                    }
                }

            }

            var setTreeAttribute = function (_collection, _predicate, _children, value) {
                for (var i = 0; i < _collection.length; i++) {
                    var n = _collection[i][_predicate];
                    var children = _collection[i][_children];
                    if (children && children.length) {
                        setTreeAttribute(children, _predicate, _children, value)
                    }
                    _collection[i][_predicate] = value;
                }
            }

            function setTreeParent(_collection, _children) {
                for (var i = 0; i < _collection.length; i++) {
                    var thisChildren = _collection[i][_children];
                    if (thisChildren && thisChildren.length) {
                        setTreeParent(thisChildren, _children);
                    }
                    for (var n in thisChildren) {
                        thisChildren[n].parent = _collection[i];
                    }

                    //SET SCREEN PARENT
                    if (_collection[i].type === 'activity' && _collection[i].screens.length) {
                        var screens = _collection[i].screens;
                        for (var n in screens) {
                            screens[n].parent = _collection[i];
                        }
                    }
                }
            }

            return menuData;
        }])

        .directive('gtMenuSearch', ['$compile', '$parse', '$state', 'menuDataService', function ($compile, $parse, $state, menuDataService) {
            return {
                restrict: 'A',
                template: '<div class="search-input"><i class="fa fa-search search"></i><input placeholder="filter navigation" ng-modal="filterData" ng-keyup="filterMenu()" type="text" ><i class="fa fa-times close-x" data-ng-click="resetFilter()"> </i></div>',
                replace: true,
                link: function (scope, element, attrs) {
                    scope.filterData = '';
                    scope.filterMenu = function () {
                        var searchString = element.find("input").val();
                        menuDataService.pipe(searchString);
                    }
                    scope.resetFilter = function () {
                        element.find("input").val("");
                        scope.filterMenu();
                    };
                }
            };
        }])


        .directive('gtGlobalSearch', ['$compile', '$parse', '$state', '$timeout', '$window', 'menuDataService', 'workspaceFactory', function ($compile, $parse, $state, $timeout, $window, menuDataService, workspaceFactory) {
            return {
                restrict: 'A',
                template: '<div ng-click="toggle()" class="gt-header-search "> ' +
                    '<i class="fa fa-search"></i> ' +
                    '</div>  ' +
                    '<div ng-show="open" class="search-box">  ' +
                    '<div class="input-group">  ' +
                    '<div class="input-group-btn">  ' +
                    '<button type="button" class="btn btn-sm btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action <span class="caret"></span></button>  ' +
                    '<ul class="dropdown-menu">  ' +
                    '<li><a href="#">All</a></li>  ' +
                    '<li><a href="#">Channels</a></li>  ' +
                    '<li><a href="#">Tasks</a></li>  ' +
                    '<li><a href="#">Activities</a></li>  ' +
                    '<li><a href="#"></a></li>  ' +
                    '<li role="separator" class="divider"></li>  ' +
                    '<li><a href="#">Separated link</a></li>  ' +
                    '</ul>  ' +
                    '</div>  ' +
                    '<input type="text" class="form-control input-sm" ng-model="search" ngFocus="focusOn"  placeholder="search" aria-label="">' +
                    '<div class="input-group-btn"> ' +
                    '<button ng-click="clear()" type="button" class="btn btn-default  btn-white btn-sm" aria-label="Help">' +
                    '<i ng-show="!loading" class="fa fa-times"></i>' +
                    '<i ng-show="loading" class="fa fa-spinner fa-pulse fa-lg"></i>' +
                    '</button> ' +
                    '</div>  ' +
                    '</div>  ' +
                    ' </div>',


                replace: false,
                link: function (scope, element, attrs) {
                    
                    element.bind('click', function(event) {
                        workspaceFactory.loadLink("link","{}","7069","10906");
                    })
                           
                    // var input = element.find('input');
                    // scope.open = false;
                    // scope.focusOn = false;
                    // scope.search;
                    // scope.loading = false;


                    // scope.toggle = function () {
                    //     scope.open = !scope.open;
                    //     scope.focusOn = !scope.focusOn;
                    //     scope.search = '';
                    //     scope.loading = false;
                    //     $timeout(function () {
                    //         input.focus();
                    //     });
                    //     if (scope.open) {
                    //         $window.onclick = function (event) {
                    //             var clickedElement = event.target;
                    //             if (!element[0].contains(event.target)) {
                    //                 scope.toggle();
                    //             }
                    //         };
                    //     } else {
                    //         $window.onclick = null;

                    //     }

                    // }


                    // scope.clear = function () {
                    //     scope.search = "";
                    // }


                    // scope.$watch('search', function (val) {
                    //     ////////////console.log(val);
                    //     if (val != undefined && val.length > 2) {
                    //         scope.loading = true;
                    //     } else {
                    //         scope.loading = false;
                    //     }


                    // });


                }
            }
        }])

        /** Custom Activity tracker directive */
        .directive('activityTracker', [function() {
            return {
                restrict: 'A',
                scope: {
                    activityObj: '='
                },
                template:  
                '<div class="activity">'+
                '    <label style="font-size: 16px; margin-bottom: 15px;">{{activityObj.title}}</label>'+
                '    <div class="activity-wrapper">'+
                '        <div class="list" ng-repeat="act in activityObj.eachActivity">'+
                '            <li>{{act.date}}'+
                '                <div>{{act.time}}</div>'+
                '            </li>'+
                '            <li><span class= "seperator" ng-style="{\'border-color\': act.color}"></span></li>'+
                '            <li class="text-nowrap">{{act.desc}}</li>'+
                '        </div>'+
                '    </div>'+
                '</div>',
                link: function() {
                }
            }
        }])


        .directive('gtDatePickerPopup', ['$compile', '$parse', '$state', '$document', function ($compile, $parse, $state, $document) {

            return {
                scope: {
                    cell: '=',
                    ngModel: '=',
                    occurrence: '=',
                },
                template: '<p class="input-group"><input type="checkbox" ng-change="setVarious(selectDate)" ng-model="selectDate" ng-value="setCheckValue" ng-disabled="cellReadOnly"/>VARIOUS<input class="form-control date-picker" type="hidden" name="el_{{occurrence}}_{{cell.key}}" ng-model="ngModel" ng-value ="setTextValue" ng-style="{\'background-color\': cell.property.backgroundColor}" style="width:94px;height:10px" ng-disabled="selectDate || cellReadOnly"/><input class="form-control date-picker" type="text" ng-model="ngModel" ng-value ="setTextValue" ng-style="{\'background-color\': cell.property.backgroundColor}" style="width:94px;height:10px" uib-datepicker-popup datepicker-popup-template-url="app/templates/date-picker-popup-tpl.html"  is-open="popup1.opened" ng-click="open1($event)" alt-input-formats="altInputFormats" close-text="Close" ng-disabled="selectDate || cellReadOnly"/><span class="input-group-btn"><input type="button" style="visibility:hidden" ng-click="open1($event)" ng-disabled="selectDate || cellReadOnly" class="btn btn-default" "><i class="glyphicon glyphicon-calendar"></i></input></span></p>',
                //template: '<p class="input-group"><input type="checkbox" ng-change="setVarious(selectDate)" ng-model="selectDate" ng-value="setCheckValue" ng-disabled="cellReadOnly"/>VARIOUS<input class="form-control date-picker" type="hidden" name="el_{{occurrence}}_{{cell.key}}" ng-model="ngModel" ng-value ="setTextValue" ng-style="{\'background-color\': cell.property.backgroundColor}" style="width:94px;height:10px" ng-disabled="selectDate || cellReadOnly"/><input class="form-control date-picker" type="text" ng-model="ngModel" ng-value ="setTextValue" ng-style="{\'background-color\': cell.property.backgroundColor}" style="width:94px;height:10px" uib-datepicker-popup datepicker-popup-template-url="app/templates/date-picker-popup-tpl.html"  is-open="popup1.opened" ng-click="open1($event)" alt-input-formats="altInputFormats" close-text="Close" ng-disabled="selectDate || cellReadOnly"/><span class="input-group-btn"><button type="button" ng-click="open1($event)" ng-disabled="selectDate || cellReadOnly" class="btn btn-default" "><i class="glyphicon glyphicon-calendar"></i></button></span></p>',
                link: function (scope, element, attrs) {

                    ////console.log(attrs);

                    scope.open1 = function (event) { // alert('Open');
                        event.stopPropagation();
                        scope.popup1.opened = true;
                    };

                    scope.open2 = function () {
                        scope.popup2.opened = true;
                    };

                    scope.setDate = function (year, month, day) {
                        scope.dt = new Date(year, month, day);
                    };

                    scope.setVarious = function (selectDate) {
                        if (selectDate) {
                            scope.ngModel = 'VARIOUS';
                        }
                    };

                    scope.cellReadOnly = scope.cell.property.smartReadonly;

                    scope.setCheckValue = function () {
                        scope.cellVal = scope.cell.value;
                        if (scope.cellVal === 'VARIOUS') {
                            scope.selectDate = true;
                        } else {
                            scope.selectDate = false;
                        }

                        return scope.selectDate;
                    };

                    scope.setTextValue = function () {
                        scope.cellVal = scope.cell.value;
                        if (scope.cellVal !== 'VARIOUS' && scope.cellval !== null) {
                            scope.setVal = scope.cellVal;
                        } else {
                            scope.setVal = null;
                        }

                        return scope.setVal;
                    }

                    scope.popup1 = {
                        opened: false
                    };

                    scope.popup2 = {
                        opened: false
                    };

                }

            }

        }])


        .directive('gtMenuExpand', ['$compile', '$parse', '$state', 'menuDataService', function ($compile, $parse, $state, menuDataService) {
            return {
                restrict: 'A',
                template: '<i class="fa fa-folder-open" data-ng-click="expand()"></i><i class="fa fa-folder" data-ng-click="collapse()"></i>',
                replace: false,
                link: function (scope, element, attrs) {
                    scope.expand = function () {
                        menuDataService.toggleMenu(false);
                    }

                    scope.collapse = function () {
                        menuDataService.toggleMenu(true);
                    }
                }
            }
        }])


        .directive('gtMenuTree', ['$compile', '$parse', '$state', 'AlertService', 'menuDataService', 'workspaceFactory', function ($compile, $parse, $state, AlertService, menuDataService, workspaceFactory) {
            return {
                restrict: 'A',
                scope: {
                    gtMenuTree: '=',
                    level: '@',
                },
                link: function (scope, element, attrs) {
                    var treeId = attrs.treeId;
                    scope.nextLevel = parseInt(scope.level, 10) + 1;
                    var nodeLabel = attrs.nodeLabel || 'label';
                    var nodeChildren = attrs.nodeChildren || 'children';
                    scope.$on("currentTheme", function(event, val) {
                        scope.arrowIcn = val;
                    });
                    var template = '' +
                        '<ul><li data-ng-repeat="node in gtMenuTree" data-ng-hide="node.hidden" class="level-{{level}}">' +
                        '<div data-ng-click="clickAction(node)" data-ng-class="{\'selected\': node.selected, \'screen\': !node.children.length}">' +
                        '<div class="pull-left" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block; max-width: 165px;" id="{{node.menu_id}}"><div class="pull-left cap"></div>{{node.' + nodeLabel + '}}</div>' +
                        '<div class="pull-right">' +
                        // '<div data-ng-click="clickAction(node)" data-ng-class="[node.type ,node.selected]" >' +
                        // '<div class="pull-left"><div class="pull-left cap"></div>{{node.'+nodeLabel+'.length>20 ? '...' : ''}}</div>' +
                        // '<div class="pull-right">' +
                        '<span data-ng-show="node.type === \'activity\' && node.cachedData" class="circle pull-right"></span>' +
                        '<i data-ng-show="node.' + nodeChildren + '.length && node.collapsed" class="fa" data-ng-class="{\'fa-chevron-right\':arrowIcn === \'theme-white\', \'fa-folder\':arrowIcn !== \'theme-white\'}"></i>' +
                        '<i data-ng-show="node.' + nodeChildren + '.length && !node.collapsed" class="fa" data-ng-class="{\'fa-chevron-right rotate-down\':arrowIcn === \'theme-white\', \'fa-folder-open\':arrowIcn !== \'theme-white\'}"></i>' +
                        // '<i class="circle"></i>' +
                        '</div>' +
                        '</div>' +
                        '<div data-ng-hide="node.collapsed" level="{{nextLevel}}" gt-menu-tree="node.children" ></div>' +
                        '</li></ul>';

                    scope.clickAction = function (_node) {

                        console.log("activity:", _node);
                        console.log("menuDataService:", menuDataService);




                        if (_node.children && _node.children.length) {
                            _node.collapsed = !_node.collapsed;
                        } else {
                            menuDataService.removeSelected();
                            _node.selected = true;
                            menuDataService.setSelected(_node); //THIS DOES THE HIGHLIGHTING OF PARENT NODES
                            scope.currentNode = _node;
                            if (_node.screens && _node.screens.length && _node.screens[0].link) {
                                workspaceFactory.setActivity(_node, true);
                            } else {
                                $state.go('404', {}, {
                                    inherit: true
                                });
                                AlertService.add("warning", "No screens defined for this activity");
                            }
                        }
                    }


                    element.html('').append($compile(template)(scope));

                }
            };
        }])

        .directive('gtMenu', ['$compile', '$parse', '$state', 'menuDataService', function ($compile, $parse, $state, menuDataService) {
            return {
                restrict: 'A',
                template: '<div gt-menu-tree="menuData" level="0"></div>',
                link: function (scope, element, attrs) {

                    scope.$watch(function () {
                        return menuDataService.data
                    }, function (val) {
                        scope.menuData = val;
                    });
                }
            };
        }])

        /* Selection Directives Starts */
        .service('selectMenuService', [function () {
            var service = {};
            service.pipe = function (search, menuData, childKey) {
                service.toggleMenu(menuData, false);
                setTreeAttribute(menuData, 'hidden', childKey || 'children', false);
                filterTree(menuData, search, 'label', 'children');
            };

            service.updateSelection = function (tree, node, selected, childKey) {
                var treeNode = searchTree(tree, childKey || 'children', 'child_id', node.child_id);
                if (treeNode) {
                    treeNode.selected = selected
                    setChilds(treeNode, selected, childKey || 'children');
                    setParent(treeNode, childKey || 'children');
                }
            };

            service.toggleMenu = function (menuData, value, childKey) {
                setTreeAttribute(menuData, 'collapsed', childKey || 'children', value);
            };

            function setParent(node) {
                if (node && node.parent && angular.isArray(node.parent.children)) {
                    var hasSelected = node.parent.children.some(function (sibling) {
                        return sibling.selected;
                    });
                    node.parent.selected = hasSelected;
                    setParent(node.parent);
                }
            }

            function setChilds(node, selected, childKey) {
                if (node && node[childKey]) {
                    angular.forEach(node[childKey], function (childNode) {
                        childNode.selected = selected;
                        setChilds(childNode, selected, childKey);
                    });
                }
            }

            function searchTree(tree, childKey, property, value) {
                var result = null;
                if (tree && tree.length > 0) {
                    for (var i = 0; result == null && i < tree.length; i++) {
                        if (tree[i][property] === value) {
                            result = tree[i];
                        } else if (tree[i][childKey]) {
                            result = searchTree(tree[i][childKey], childKey, property, value);
                        }
                    }
                }
                return result;
            }

            var filterTree = function (collection, value, predicate, children) {
                for (var i = 0; i < collection.length; i++) {
                    var n = collection[i][predicate];
                    var checkChildren = true;
                    if (n) {
                        if (n && _.includes(n.toLowerCase(), value.toLowerCase())) {
                            ////////////console.log( n , " - stop here")
                            collection[i].hidden = false;
                            checkChildren = false;
                        } else {
                            collection[i].hidden = true;
                        }
                        if (checkChildren && collection[i][children] && collection[i][children].length) {
                            filterTree(collection[i][children], value, predicate, children)
                            var parentHidden = true;
                            var innerChildren = collection[i][children];
                            if (innerChildren.length) {
                                for (var p = 0; p < innerChildren.length; p++) {
                                    if (!innerChildren[p].hidden) {
                                        parentHidden = false;
                                    }
                                }
                            }

                            if (checkChildren) {
                                collection[i].hidden = parentHidden;
                            }
                        }
                    } //end  if( n  )
                } //end for
            }; //end filterTree

            var removeTreeAttribute = function (_collection, _predicate, _children) {
                for (var i = 0; i < _collection.length; i++) {
                    var n = _collection[i][_predicate];
                    var children = _collection[i][_children];
                    if (children && children.length) {
                        removeTreeAttribute(children, _predicate, _children)
                    }
                    if (n && n === _predicate) {
                        _collection[i][_predicate] = undefined;
                    }
                }

            }

            var setTreeAttribute = function (_collection, _predicate, _children, value) {
                for (var i = 0; i < _collection.length; i++) {
                    var n = _collection[i][_predicate];
                    var children = _collection[i][_children];
                    if (children && children.length) {
                        setTreeAttribute(children, _predicate, _children, value)
                    }
                    _collection[i][_predicate] = value;
                }
            }

            function setTreeParent(_collection, _children) {
                for (var i = 0; i < _collection.length; i++) {
                    var thisChildren = _collection[i][_children];
                    if (thisChildren && thisChildren.length) {
                        setTreeParent(thisChildren, _children);
                    }
                    for (var n in thisChildren) {
                        thisChildren[n].parent = _collection[i];
                    }

                    //SET SCREEN PARENT
                    if (_collection[i].type === 'activity' && _collection[i].screens.length) {
                        var screens = _collection[i].screens;
                        for (var n in screens) {
                            screens[n].parent = _collection[i];
                        }
                    }
                }
            }

            return service;
        }])
        .directive('gtSelectMenuTree', ['$compile', 'selectMenuService', function ($compile, selectMenuService) {
            return {
                restrict: 'A',
                scope: {
                    level: '@',
                    allowAdd: '=',
                    allowRemove: '=',
                    allowSelect: '=',
                    gtSelectMenuTree: '=',
                    onAdd: '&',
                    onRemove: '&'
                },
                link: function (scope, element, attrs) {
                    scope.nextLevel = parseInt(scope.level, 10) + 1;
                    var nodeLabel = attrs.nodeLabel || 'label';
                    var nodeChildren = attrs.nodeChildren || 'children';
                    var template = '' +
                        '<ul class="select-menu"><li data-ng-repeat="node in gtSelectMenuTree" data-ng-hide="node.hidden || (!node.selected && allowRemove)" class="level-{{level}}">' +
                        '<div data-ng-click="clickAction(node)" data-ng-class="{\'selected\': node.selected, \'screen\': !node.children.length}">' +
                        '<div class="pull-left"><div class="pull-left cap"></div>{{node.' + nodeLabel + '}}</div>' +
                        '<div class="pull-right">' +
                        '<i ng-if="node.type!==\'activity\'" data-ng-hide="node.collapsed" class="fa fa-folder-open" data-ng-click="expand()"></i>' +
                        '<i ng-if="node.type!==\'activity\'" data-ng-hide="!node.collapsed" class="fa fa-folder" data-ng-click="collapse()"></i>' +
                        '<i class="text-muted fa fa-info-circle"></i>' +
                        '<i data-ng-show="allowAdd" data-ng-click="onSelect(node, $event)" class="add-btn fa fa-plus-square"></i>' +
                        '<i data-ng-show="allowRemove" data-ng-click="onUnSelect(node, $event)" class="remove-btn fa fa-minus-square"></i>' +
                        '</div>' +
                        '</div>' +
                        '<div data-ng-hide="node.collapsed" on-add="onAdd()" on-remove="onRemove({\'$node\': $node})" level="{{nextLevel}}" gt-select-menu-tree="node.' + nodeChildren + '" allow-select="allowSelect" allow-add="allowAdd" allow-remove="allowRemove"></div>' +
                        '</li></ul>';

                    scope.clickAction = function (_node) {
                        _node.collapsed = !_node.collapsed;
                    };

                    scope.onSelect = function (node, $event) {
                        selectMenuService.updateSelection(scope.gtSelectMenuTree, node, true, nodeChildren);
                        $event.stopPropagation();
                        scope.onAdd();
                    };

                    scope.onUnSelect = function (node, $event) {
                        selectMenuService.updateSelection(scope.gtSelectMenuTree, node, false, nodeChildren);
                        $event.stopPropagation();
                        scope.onRemove({
                            $node: node
                        });
                    }

                    element.html('').append($compile(template)(scope));

                }
            };
        }])
        .directive('gtSelectMenuSearch', ['selectMenuService', function (selectMenuService) {
            return {
                restrict: 'A',
                scope: {
                    menuTree: "="
                },
                template: '<div class="search-input">' +
                    '<i class="fa fa-search search"></i>' +
                    '<input placeholder="filter navigation" ng-model="filterData" ng-change="filterMenu()" type="text" />' +
                    '<i class="fa fa-times close-x" data-ng-click="resetFilter()"> </i>' +
                    '</div>',
                replace: true,
                link: function (scope, element, attrs) {
                    scope.filterData = '';
                    scope.filterMenu = function () {
                        selectMenuService.pipe(scope.filterData, scope.menuTree, 'children', false);
                    };
                    scope.resetFilter = function () {
                        scope.filterData = '';
                        scope.filterMenu();
                    };
                }
            };
        }])

        .directive('gtSelectMenuExpand', ['$compile', '$parse', '$state', function ($compile, $parse, $state) {
            return {
                restrict: 'A',
                template: '<i class="fa fa-folder-open" data-ng-click="expand()"></i><i class="fa fa-folder" data-ng-click="collapse()"></i>',
                replace: false,
                link: function (scope, element, attrs) {
                    scope.expand = function () {
                        menuDataService.toggleMenu(false);
                    }

                    scope.collapse = function () {
                        menuDataService.toggleMenu(true);
                    }
                }
            }
        }])
        /* Selection Directvies Ends */


        .directive("slimScroll", ['$window', function ($window) {
            var options = {};
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    // instance-specific options
                    
                    $(window).resize(function () {
                        var elem = element[0];
                        var viewHeight = $window.innerHeight - elem.getBoundingClientRect().top;
                        var elemHeight = elem.getBoundingClientRect().height;
                        element.css('height', viewHeight + 'px')
                        element.parent().css('height', viewHeight + 'px')
                    });

                    function setUp() {
                        var elem = element[0];
                        var viewHeight = $window.innerHeight - elem.getBoundingClientRect().top;
                        var elemHeight = elem.getBoundingClientRect().height;
                        var opts = angular.extend({}, options, scope.$eval(attrs.slimScroll));
                        angular.element(element).slimScroll(opts);
                    }

                    setUp();

                }
            };
        }])

        .directive("meSlimScroll", ['$window', function ($window) {
            var options = {};
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    var copyRightFooterHeight = document.getElementsByClassName('copyright')[0] ? document.getElementsByClassName('copyright')[0].clientHeight 
                    :document.getElementsByClassName('cookieAcceptance')[0]? document.getElementsByClassName('cookieAcceptance')[0].clientHeight 
                    : 50;
                    // instance-specific options
                    $(window).resize(function () {
                        var elem = element[0];
                        //var viewHeight = $window.innerHeight - elem.getBoundingClientRect().top;
                        //var elemHeight = elem.getBoundingClientRect().height;
                        //element.css('height', viewHeight + 'px');
                        //element.parent().css('height', viewHeight + 'px');
                        var opts = angular.extend({}, options, scope.$eval(attrs.meSlimScroll));
                        opts['height'] = opts['height']-copyRightFooterHeight;
                        angular.element(element).slimScroll(opts);
                    });

                    function setUp() {
                        
                        var elem = element[0];
                        var viewHeight = $window.innerHeight - elem.getBoundingClientRect().top;
                        var elemHeight = elem.getBoundingClientRect().height;
                        var opts = angular.extend({}, options, scope.$eval(attrs.meSlimScroll));
                            opts['height'] = opts['height']-copyRightFooterHeight;

                        angular.element(element).slimScroll(opts);
                    }

                    setUp();
                }
            };
        }])


        .directive('onlyDigits', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.toString().replace(/[^0-9.-]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return parseFloat(digits);
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })

        .directive('onlyPositiveDigits', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.toString().replace(/[^0-9.-]/g, '');
                            var parsedValue = parseFloat(digits);
                            if (parsedValue < 0) {
                                parsedValue = 0;
                            }
        
                            if (parsedValue !== val) {
                                ctrl.$setViewValue(parsedValue);
                                ctrl.$render();
                            }
                            return parsedValue;
                        }
                        return undefined;
                    }
        
                    ctrl.$parsers.push(inputValue);
                    
                    // Watch for changes to ngModel
                    scope.$watch(attr.ngModel, function(newValue, oldValue) {
                        if (newValue !== oldValue) {
                            inputValue(newValue);
                        }
                    });
                }
            };
        })
        

        // RM-24383 External Partner Edit - Allow leading zero
        .directive('onlyDigitsString', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.toString().replace(/[^0-9.-]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return digits;
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })




        .directive('gtwOnlyIntegers', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9-]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return parseInt(digits);
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })

        .directive('gtwOnlyPositiveIntegers', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9]/g, '');
                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return parseInt(digits);
                        }
                        return undefined;
                    }
                    ctrl.$parsers.push(inputValue);
                }
            };
        })
        ///////Accepts only positive or negative integers with comma separated and max length of 15 digits
        .directive('onlyIntegersWithLength', ['$filter', function ($filter) {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9-]/g, '');

                            if (digits.indexOf('-') == 0) {
                                if (digits.length > 1) {
                                    digits = $filter('limitTo')(digits, 16);
                                    digits = $filter('number')(digits, 0);
                                }
                            } else {
                                digits = $filter('limitTo')(digits, 15);
                                digits = $filter('number')(digits, 0);
                            }

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return digits.replace(/[^0-9-]/g, '');
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        }])

        .directive("notZero", function () {
            // requires an isloated model
            return {
                // restrict to an attribute type.
                restrict: 'A',
                // element must have ng-model attribute.
                require: 'ngModel',
                link: function (scope, ele, attrs, ctrl) {
                    ctrl.$parsers.unshift(function (value) {
                        if (value) {
                            var valid = value != 0;
                            ctrl.$setValidity('notZero', valid);
                        } else {
                            ctrl.$setValidity('notZero', true);
                        }
                        return value;
                    });

                }
            };
        })


        .directive("pageDataLoader", function () {
            var options = {};
            return {
                restrict: "A",
                scope: {loading: '='},
                transclude: true,
                template: '<div class="gtw-web-components gg-loader gg-loader-blur-bg z-index-gg-loader" ng-show="loading"> <div class="gg-loader-text viewport-center"><div class="d-inline-block a-p-20 text-center rounded"><span class="a-loading loading-large a-dark"></span></div></div> </div>',
                link: function (scope, element, attrs) {
                }
            };
        })


        .directive("dashboardLoader", function () {
            var options = {};
            return {
                restrict: "A",
                scope: {
                    loading: '='
                },
                transclude: true,
                template: '<div>' +
                    '<div class="dashboard-loading" ng-show="loading"><i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i></div>' +
                    '<ng-transclude ng-show="!loading"></ng-transclude>' +
                    '</div>',
                link: function (scope, element, attrs) {
                }
            };
        })


        .directive("modalLoader", function () {
            var options = {};
            return {
                restrict: "A",
                scope: {
                    loading: '='
                },
                transclude: true,
                template: '<div>' +
                    '<div class="modal-loading" ng-show="loading"><i style="margin-top:100px; color: #e12827" class="fa fa-spinner fa-pulse fa-5x fa-fw"></i></div>' +
                    '<ng-transclude ng-show="!loading"></ng-transclude>' +
                    '</div>',
                link: function (scope, element, attrs) {
                }
            };
        })


        .directive('gtSystemAccess', ['$compile', '$parse', '$state', 'USER_SETTINGS', function ($compile, $parse, $state, USER_SETTINGS) {
            var options = {};
            return {
                restrict: "A",
                scope: {
                    gtSystemAccess: '='
                },
                link: function (scope, element, attrs) {
                    if (scope.gtSystemAccess > USER_SETTINGS.user.system_access_level) {
                        element.hide();
                    }
                }
            }
        }])


        .directive("selectLoader", function () {
            var options = {};
            return {
                restrict: "A",
                scope: {
                    loading: '='
                },
                transclude: true,
                template: '<div>' +
                    '<div class="input-group" ng-show="loading" > ' +
                    '<input type="text" class="form-control" id="" placeholder="loading...">' +
                    '<div class="input-group-addon"><i class="fa fa-spinner fa-spin  fa-fw margin-bottom"></i></div>' +
                    '</div>' +
                    '<ng-transclude ng-show="!loading"></ng-transclude>' +
                    '</div>',
                link: function (scope, element, attrs) {
                }
            }
        })


        .directive('ggBrowserDetect', ['$uibModal', function ($uibModal) {
            return {
                restrict: 'A',

                link: function (scope, element, attrs) {


                    function openBrowserDetection() {
                        scope.confirmModal = $uibModal.open({
                            templateUrl: 'app/templates/modals/browser-detection-tpl.html',
                            scope: scope,
                            windowClass: 'custom',
                            backdrop: 'static',
                            keyboard: false,
                            controllerAs: 'ctrl',
                            controller: ['$scope', '$stateParams', '$uibModalInstance',
                                function ($scope, $stateParams, $uibModalInstance) {
                                    var vm = this;
                                    vm.title = 'GTW';
                                    vm.url = location.href;//"http://localhost:8080/global-tax-syst/#/workspace/bep-by-country";
                                    vm.close = function () {
                                        $uibModalInstance.close();
                                    };
                                }
                            ]
                        });
                    }

                    var isChromium = window.chrome,
                        winNav = window.navigator,
                        vendorName = winNav.vendor,
                        isOpera = winNav.userAgent.indexOf("OPR") > -1,
                        isIEedge = winNav.userAgent.indexOf("Edge") > -1,
                        isIOSChrome = winNav.userAgent.match("CriOS");

                    if (isIOSChrome) {
                        //   will add action
                    } else if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
                        //   will add action
                    } else {
                        openBrowserDetection()
                    }

                }

            }
        }])


        .directive("ggBrowserDetect", function () {
            var options = {};
            return {
                restrict: "A",
                scope: {
                    loading: '='
                },
                transclude: true,
                template: '<div></div>',
                link: function (scope, element, attrs) {

                    var isChromium = window.chrome,
                        winNav = window.navigator,
                        vendorName = winNav.vendor,
                        isOpera = winNav.userAgent.indexOf("OPR") > -1,
                        isIEedge = winNav.userAgent.indexOf("Edge") > -1,
                        isIOSChrome = winNav.userAgent.match("CriOS");

                    if (isIOSChrome) {
                        //////console.log("IPAD OR IPHONE CHROME")
                    } else if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
                        //////console.log("BROWSER CHROME")
                    } else {
                        //////console.log("NOT CHROME")
                    }

                }
            }
        })


        .directive("netToZero", function () {
            // requires an isloated model
            return {
                // restrict to an attribute type.
                restrict: 'A',
                // element must have ng-model attribute.
                require: 'ngModel',
                link: function (scope, ele, attrs, ctrl) {
                    ctrl.$formatters.unshift(function (value) {
                        if (value) {
                            var valid = value == 0;
                            ctrl.$setValidity('netToZero', valid);
                        }
                        return value;
                    });

                }
            }
        })


        .directive('environmentChange', ['GENERAL_CONFIG', '$rootScope', '$window', '$document', function (GENERAL_CONFIG, $rootScope, $window, $document) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {


                    function upDateBodyClass() {

                        element.trigger('resizeGrid', {});

                        var body = angular.element(document).find('body');
                        var editedSettings = $rootScope.editedSettings;
                        var window = angular.element($window);


                        var html = angular.element(document).find('html');
                        html.addClass('hidden-menu-mobile-lock');


                        if (editedSettings.hasOwnProperty('fixedHeader') && editedSettings.fixedHeader == 'fixed') {
                            body.addClass("fixed-header ");
                        } else {
                            body.removeClass("fixed-header ");
                        }


                        if (editedSettings.hasOwnProperty('navPanel') && editedSettings.navPanel == 'open') {
                            body.addClass("nav-open");
                            body.removeClass("nav-closed");
                        } else {
                            body.addClass("nav-closed");
                            body.removeClass("nav-open");
                        }


                        ////////////console.log("LOADING environmentChange ===============================================================, " , editedSettings)
                        /*
                         body.addClass("fixed-header");
                         body.addClass("fixed-navigation");

                         // {"fixed_container":false,"fixed_footer":false,"fixed_ribbon":true,"top_menu":false,"fixed_header":true,"fixed_navigation":false,"skin":"smart-style-0"}

                         if(editedSettings.hasOwnProperty('fixed_ribbon') && editedSettings.fixed_ribbon == true  ){
                         body.addClass("fixed-ribbon");
                         }else{
                         body.removeClass("fixed-ribbon");
                         }

                         if(editedSettings.hasOwnProperty('fixed_footer') && editedSettings.fixed_footer == true  ){
                         body.addClass("fixed-page-footer");
                         }else{
                         body.removeClass("fixed-page-footer");
                         }

                         if(editedSettings.hasOwnProperty('fixed_container') && editedSettings.fixed_container == true  ){
                         body.addClass("container");
                         }else{
                         body.removeClass("container");
                         }

                         if(editedSettings.hasOwnProperty('skin')  ){
                         body.removeClassPrefix('smart-style').addClass(editedSettings.skin);
                         }*/

                    }


                    upDateBodyClass();

                    $rootScope.$watchCollection('editedSettings', function () {

                        ////////////console.log('WE CHANGED THE SETTINGS XXXXXXXXXXXXXXXXXXXXXXXXX')

                        upDateBodyClass();
                    })

                }
            }

        }])


        .directive('gtwCharactersAvailable', ['$log', function ($log) {

            return {

                scope: {

                    name: '@',
                    list: '=',
                    charlimit: '@'
                }

                ,
                template: "<div><style>.gtw-warning {color: #ED8000;} .gtw-ok {color: #76B900;}</style> <div data-ng-class=\"{'gtw-ok': ( !isCharLimitReached() && !isCharWarningReached() ) , 'error': isCharLimitReached(), 'gtw-warning': isCharWarningReached() }\" ><small><i class='glyphicon glyphicon-warning-sign' data-ng-show=\"isCharWarningReached()\"></i> <i class='glyphicon glyphicon-exclamation-sign' data-ng-show=\"isCharLimitReached()\"></i><span data-ng-bind='showAvailableCharacters()'></span></small></div></div>"

                ,
                replace: true

                ,
                link: function (scope, element, attrs) {

                    var elem;
                    var list;
                    var isElement = false;
                    var isList = false;
                    var maxlength = 0;

                    if ((angular.isDefined(name)) && (angular.element("[name='" + scope.name + "']").length > 0)) {

                        elem = angular.element("[name='" + scope.name + "']");
                        isElement = true;

                        if (angular.isDefined(scope.charlimit)) {
                            maxlength = scope.charlimit;
                        } else {
                            maxlength = elem.attr('maxlength');
                        }

                    }

                    if (angular.isDefined(scope.list)) {
                        list = scope.list;
                        isList = true;
                        maxlength = attrs.maxlength;
                    }

                    var getVal = function () {

                        var retVal = '';
                        var elemVal = '';
                        var listVal = '';

                        if (isElement) {

                            elemVal = elem.val();
                        }

                        if (isList) {

                            angular.forEach(scope.list, function (element, index) {

                                listVal += ',' + element.name;
                            });

                            if (listVal) {

                                listVal = listVal.substring(1);
                            }
                        }

                        if (elemVal && listVal) {

                            retVal = elemVal + ',' + listVal;

                        } else if (elemVal) {

                            retVal = elemVal;

                        } else if (listVal) {

                            retVal = listVal;
                        }

                        return retVal;
                    };

                    var getAvailableCharacters = function () {

                        var availableChars = parseInt(maxlength) - parseInt(getVal().length);

                        if (isList && (availableChars == 1)) {
                            availableChars = 0;
                        }

                        return availableChars;
                    };

                    scope.isCharLimitReached = function () {

                        var availableChars = getAvailableCharacters();
                        return (availableChars <= 0) ? (true) : (false);
                    };

                    scope.isCharWarningReached = function () {

                        var availableChars = getAvailableCharacters();
                        return ((availableChars != 0) && ((availableChars - 6) < 0) && !scope.isCharLimitReached()) ? (true) : (false);
                    };

                    scope.showAvailableCharacters = function () {

                        var availableChars = getAvailableCharacters();
                        return (availableChars > 0) ? (availableChars + ' characters available') : ('Limit of ' + maxlength + ' characters reached');
                    };


                }
            };

        }])

        // Start of Directive for select/unselect all inside the Workflow steps dropdown for WorkFlow.
        .directive('multiSelectDropdown', [function () {
            return {
                restrict: 'E',
                require: 'ngModel',
                scope: {
                    label: '@',
                    ngModel: '=',
                    options: '=',
                    config: '=?',
                    containerClass: '@',
                    btnClass: '@'
                },
                template: '<div class="btn-group dropdown {{containerClass}}" uib-dropdown="" auto-close="outsideClick">' +
                    '<button id="split-button" type="button" class="btn {{btnClass}}" uib-dropdown-toggle="">{{label}}</button>' +
                    '<button type="button" class="btn split-btn" uib-dropdown-toggle="" aria-haspopup="true" aria-expanded="false">' +
                    '<span class="caret"></span>' +
                    '<span class="sr-only">Split button!</span>' +
                    '</button>' +
                    '<ul class="dropdown-menu" uib-dropdown-menu="" role="menu" aria-labelledby="split-button" style="width: 93%; top: 31px; left: 1px; padding: 5px;">' +
                    '<li role="menuitem" ng-show="options.length" style="font-size: 15px; cursor: pointer; text-decoration: underline;" ng-if="config.selectAll" ng-click="selectAllClick()">' +
                    '<i class="fa fa-check"></i>Select All' +
                    '</li>' +
                    '<li role="menuitem" ng-show="options.length" style="font-size: 15px; cursor: pointer; text-decoration: underline;" ng-if="config.unSelectAll" ng-click="unSelectAllClick()">' +
                    '<i class="fa fa-times"></i>Un Select All' +
                    '</li><hr />' +
                    '<li role="menuitem" style="font-size: 15px;" ng-repeat="child in options">' +
                    '<input type="checkbox" id="cb-id{{child.id}}" name="option" ng-model="child.selected" ng-change="onChangeSelection()">' +
                    '<label class="ng-binding" for="cb-id{{child.id}}">{{child.name}}</label>' +
                    '</li>' +
                    '</ul>' +
                    '</div>',
                controller: 'multiSelectDropdownCtrl as ctrl'
            };
        }])

        .controller('multiSelectDropdownCtrl', ['$scope', function ($scope) {
            var defaultConfig = {
                selectAll: true,
                unSelectAll: true
            };
            $scope.config = angular.merge($scope.config || {}, defaultConfig);

            $scope.selectAllClick = function () {
                updateSelected(true);
                updateModel();
            };

            $scope.unSelectAllClick = function () {
                updateSelected(false);
                updateModel();
            };

            $scope.onChangeSelection = updateModel;

            function updateSelected(selected) {
                angular.forEach($scope.options, function (opt) {
                    opt.selected = selected;
                });
            }

            function updateModel() {
                var steps = [];
                if ($scope.options && $scope.options.length > 0) {
                    steps = $scope.options.filter(function (child) {
                        return child.selected;
                    });
                }
                $scope.ngModel = steps;
            }
        }])
        // End of Directive functionality for select/unselect all inside the Workflow steps dropdown for WorkFlow.
        .directive('onSizeChanged', ['$window', function ($window) {
            return {
                restrict: 'A',
                scope: {
                    onSizeChanged: '&'
                },
                link: function (scope, $element, attr) {
                    var element = $element[0];

                    cacheElementSize(scope, element);
                    $window.addEventListener('resize', onWindowResize);

                    function cacheElementSize(scope, element) {
                        scope.cachedElementWidth = element.offsetWidth;
                        scope.cachedElementHeight = element.offsetHeight;
                    }

                    function onWindowResize() {
                        var isSizeChanged = scope.cachedElementWidth != element.offsetWidth || scope.cachedElementHeight != element.offsetHeight;
                        if (isSizeChanged) {
                            var expression = scope.onSizeChanged();
                            expression && expression();
                        }
                    };
                }
            }
        }])
        // Directive fucntionaity to format the entered number in to (example:11-11111) formatt not allowed other alphabetical or special characters.
        .directive('inputFormat', ['$browser',function ($browser) {
            return {
            	restrict: 'EA',
                require: 'ngModel',
                link: function ($scope, $element, $attrs, ngModelCtrl) {
                    var format = function (val) {
                        if (val && val.length > 2) {
                            return val.substr(0, 2) + '-' + val.substr(2, val.length);
                        } else {
                            return val;
                        }
                    };
                    var listener = function () {
                        var value = $element.val().replace(/-/g, '');
                        $element.val(format(value));
                    };

                    // This runs when we update the text field
                    ngModelCtrl.$parsers.push(function (viewValue) {
                        return viewValue.replace(/-/g, '');
                    });


                    // This runs when the model gets updated on the scope directly and keeps our view in sync
                    ngModelCtrl.$render = function () {
                        $element.val(format(ngModelCtrl.$viewValue));
                    };

                    $element.bind('change', listener);
                    $element.bind('keydown', function (event) {
                        var key = event.keyCode
                        // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                        // This lets us support copy and paste too
                        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40))
                            return;
                        else if (key > 31 && (key < 48 || key > 57))
                            return false;
                        $browser.defer(listener) // Have to do this or changes don't get picked up properly
                    })

                    $element.bind('paste cut', function () {
                        $browser.defer(listener)
                    })
                }

            }
        }])

        // directive for selecting "DatePicker date selection" and "VARIOUS" based on checkbox selection..
        .directive('formDatePicker', [function () {
            return {
                restrict: 'E',
                require: "?ngModel",
                scope: {
                    ngModel: '=',
                    disableChecbox: '=?',
                },
                templateUrl: 'app/templates/form-date-picker-tpl.html',
                controller: 'formDatePickerCtrl as $ctrl'
            }
        }])


        .directive('irsFormDatePicker', [function () {
            return {
                restrict: 'E',
                require: "?ngModel",
                scope: {
                    ngModel: '=',
                    startDate: '=',
                    endDate: '=',
                    disableChecbox: '=?',

                },
                templateUrl: 'app/templates/form-date-picker-tpl.html',
                controller: 'formDatePickerCtrl as $ctrl',
                link: function ($scope, element, attrs) {

                    $scope.$ctrl.dateOptions = {
                        dateDisabled: false,
                        formatYear: 'yyyy',
                        startingDay: 1,
                        minDate: new Date($scope.startDate),
                        maxDate: new Date($scope.endDate),
                        initDate: new Date($scope.endDate),
                        showWeeks: false
                    };


//                if($scope.ngModel != null && $scope.ngModel == 'VARIOUS'){
//                $scope.isVarious = true;
//                    }


                }
            }
        }])


        .directive('filtersSelectMultiple', ['$log', '$timeout', function ($log, $timeout) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter: '=',
                    callbackFn: '&',
                    callbackModel: '=',
                    btnClass: '@',
                    fromTask: '=',
                    multiSelectWithCtrl:'='
                },

                controller: ['$scope', function ($scope) {

                    var vm = this;
                    vm.values = [];
                    vm.model = [];
                    // vm.model = $scope.filter.selected;
                    // var filterSelectionWatcher = function () {
                    //     console.log(newVal, oldVal)
                    // }
                    vm.fromfilter = $scope.fromTask


                    $scope.$watch('filter.selected', function (newVal, oldVal) {
                        vm.model = newVal
                        // vm.model = [vm.values[0]];
                        console.log(" vm.model", vm.model);
                    }, true);


                    $scope.$watch('filter.values', function (newVal, oldVal) {
                        if (typeof newVal !== "undefined" && newVal != null) {
                            var array = $scope.filter.values;
                            vm.values = array;
                        }
                    }, true);


                    vm.change = function () {
                        $scope.filter.selected = vm.model;
                        $scope.callbackModel = _.map($scope.filter.selected, 'value')
                        $timeout(function () {
                            $scope.callbackFn({arg1: 22});
                        }, 10);

                    }


                    // vm.change = function (item) {

                    //     // if(vm.model.length === 0){
                    //     //     vm.model.push(item)
                    //     // }

                    //     // $scope.filter.selected = vm.model.slice(0);
                    //     console.log(vm.model)
                    //     $scope.callbackModel = _.map($scope.filter.selected, 'value')
                    //     $timeout(function () {
                    //         $scope.callbackFn({ arg1: 22 });
                    //     }, 10);
                    //     console.log(vm.values)
                    // }


                    vm.settings = {
                        enableSearch: true,
                        displayProp: 'name',
                        idProperty: 'value',
                        externalIdProp: '',
                        //selectionLimit: 2,
                        scrollableHeight: '400px',
                        scrollable: true,
                        keyboardControls:true,
                        multiSelectWithCtrl:$scope.multiSelectWithCtrl,
                        buttonClasses: $scope.fromTask == "1" ? $scope.btnClass : 'btn btn-default btn-filter btn-sm'
                    };


                    var customTexts = "Select  " + $scope.filter.name;
                    vm.example5customTexts = {buttonDefaultText: customTexts};

                    vm.events = {
                        // onInitDone : function(){
                        //     // var a = "init done";
                        //     // vm.model.push({value: "IF2000", name: "Baker Hughes GE"})
                        //     // console.log("init message" , vm.model)
                        //     // vm.change();
                        // },
                        onItemSelect: function (item) {
                            vm.change(item);
                        },
                        onItemDeselect: function (item) {
                            vm.change(item);
                        },
                        onSelectAll: function () {
                            $timeout(function () {
                                vm.change(null);
                            }, 10)

                        },
                        onDeselectAll: function () {
                            $timeout(function () {
                                vm.change(null);
                            }, 10)
                        }
                    }
                }],


                template: '<div>' +
                    '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                    '<div ng-show="!filter.loading" ng-dropdown-multiselect  events="ctrl.events" search-filter="customFilter" options="ctrl.values" selected-model="ctrl.model" extra-settings="ctrl.settings" translation-texts="ctrl.example5customTexts"></div>' +
                    '</div>',

                link: function ($scope, element, attrs) {

                }
            };
        }])
           .directive('pdfPreviewNew', [function () {

               // 1. Takes LEID, FormName and divHeight as params
               // 2. Checks if the form pdf exits in s3
               // 3. Fills form and renders the PDf
               return {
                   controllerAs: 'ctrl',
                   bindToController: true,
                   controller: ['$scope', 'WorkflowService', '$sce', 'AlertService', 'workspaceFactory', 'NewProformaFactory','NewProformaFormReviewFactory', 'NewProformaEfileViewFactory', '$rootScope', 'GlobalService', PDFPreviewNewCtrl],
                   template: '<div modal-loader loading="ctrl.loading"></div><span ng-if="ctrl.noDataAvail" style="color: white;font-size: 20px;text-align: center;width: 100%;display: flex;height: 100%;align-items: center;justify-content: center;">No Data Available</span><span ng-if="ctrl.formNotAvail">Error loading the requested form!</span><iframe src="{{ctrl.content}}" ng-if="ctrl.formLoaded && !ctrl.noDataAvail && ctrl.content" ng-style="{\'height\': \'{{ctrl.divHeight}}\'}" style="width:100%;"></iframe>',
                   scope: {
                       parentLeId: '=',
                       leId: '=',
                       formName: '=',
                       formNameIdent: '=',
                       divHeight: '=',
                       taxYear: '=',
                       scenarioLocal: '=',
                       cdrNo: '=',
                       rptPeriod: '=',
                       leType: '=',
                       systemFlag: '=',
                       s3UrlList: '=',
                       isMerge: '=',
                       irsFormName: '=',
                       wpIrsFormNo: '=',
                       jobId: '=',
                       schedule: '=',
                       line: '=',
                       partNo: '=',
                       // callIrsNewProforma: '=',
                       // callNewProforma: '=',
                       // isNewProforma: '=',
                       srcType: '=',
                       formSub: '=',
                       formSubCat: '=',
                       leName: '=',
                       ein: '=',
                       selectedEntityType: '=',
                       selectedConsolGroup: '=',
                       isAttachment: '=',
                       consolType: '=',
                       useFromCache:'=',
                       isState:'=',
                       keyId:'=',
                       formType: '='
                   },
                   link: link
               }

               function link(scope, element, attr, ctrl) {

                setTimeout(function() {
                    //adding delay to make sure the digest cycle finishes hence expand-pdf icon is rendered
                    var $expandPdf = $('#expand-pdf');

                   if ($expandPdf.length > 0) {
                       // unbind previous event listeners
                       $expandPdf.off('click');
                       $expandPdf.on('click', function () {
                           if (ctrl.content)
                               window.open(ctrl.content, '_blank');
                       })
                   }
                },500);
                   
               }

               function PDFPreviewNewCtrl($scope, WorkflowService, $sce, AlertService, workspaceFactory, NewProformaFactory,NewProformaFormReviewFactory, NewProformaEfileViewFactory, $rootScope, GlobalService) {
                   var vm = this;
                   vm.formLoaded = false;
                   vm.loading = false;
                   var taxYear = vm.taxYear || 2018;
                   var cdrNo = vm.cdrNo || 'LY1';
                   var rptPeriod = vm.rptPeriod || 1;
                   var leType = $scope.$parent.leType ? $scope.$parent.leType : vm.leType ? vm.leType : 'HO';
                   var systemFlag = $scope.$parent.systemFlag ? $scope.$parent.systemFlag : vm.systemFlag ? vm.systemFlag : 'F';
                   if(vm.srcType ==='DOM'){
                       var cacheStore = vm.isState ? 'stateViewStore' : 'gtwViewStore';
                   }
                   else{
                        var cacheStore = vm.isState ? 'STATE' : 'FED';
                   }

                   $scope.$watch('ctrl.formNameIdent', function (newValue, oldValue) {
                       if (newValue && newValue !== oldValue && newValue.length > 0) {
                           vm.formLoaded = false;
                           vm.loadPdf();
                       }
                   });

                   vm.loadPdf = function () {
                    vm.cacheParams = vm.taxYear +'_'+vm.scenarioLocal+'_'+vm.leId +'_'+vm.cdrNo+'_'+vm.rptPeriod+'_'+vm.systemFlag +'_'+vm.formNameIdent;

                       if(vm.useFromCache){
                           if(vm.srcType ==='DOM' && NewProformaFormReviewFactory[cacheStore].gtwViewPdf[vm.cacheParams]){
                               vm.content = NewProformaFormReviewFactory[cacheStore].gtwViewPdf[vm.cacheParams];
                               vm.formLoaded = true;
                                vm.formNotAvail = false;
                                vm.noDataAvail = false;
                                return;
                           }else if(NewProformaEfileViewFactory.cache[cacheStore] && NewProformaEfileViewFactory.cache[cacheStore].pdfBinary[vm.cacheParams]){
                                vm.content = NewProformaEfileViewFactory.cache[cacheStore].pdfBinary[vm.cacheParams];
                                vm.formLoaded = true;
                                vm.formNotAvail = false;
                                vm.noDataAvail = false;
                                return;
                           }
                            
                       }
                       vm.loading = true;
                       vm.formNotAvail = false;
                       vm.noDataAvail = false;
                       

                       if (vm.isMerge) {
                           NewProformaFactory.fillFormAndMergeNewPorformaAsyncService(vm.formSub, vm.jobId, vm.irsFormName, vm.formName, vm.s3UrlList,
                               vm.formSubCat, vm.leId, vm.taxYear, vm.scenarioLocal, vm.cdrNo, vm.rptPeriod, vm.leType, vm.systemFlag, vm.schedule, vm.line, vm.partNo, vm.leName,
                               vm.ein, vm.selectedEntityType, vm.selectedConsolGroup, vm.srcType, vm.parentLeId, vm.consolType, vm.keyId, vm.formType)
                                             .then((response) => {
                                                 if (response) {
                                                    if(response.data && response.data.byteLength === 0){
                                                        vm.noDataAvail = true;
                                                        return;
                                                    }
                                                     vm.formLoaded = true;
                                                     vm.name = '5471';
                                                     vm.file = new Blob([response.data], {type: 'application/pdf'});
                                                     vm.pdfPath = URL.createObjectURL(vm.file);
                                                     vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                                 } else {
                                                     AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                                 }
                                             }).finally(() => {
                                                vm.loading = false;
                                            });
                       } else {
                           vm.noDataAvail = false;
                           if (vm.isAttachment === 1){
                                NewProformaEfileViewFactory.getPdfAttachmentData(vm.taxYear, vm.parentLeId, vm.formName, vm.formType)
                                .then((response) => {
                                    if (!response.data) {
                                        AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                        return;
                                    }
                                    if(response.data && response.data.byteLength === 0){
                                        vm.noDataAvail = true;
                                        return;
                                    }

                                    vm.formLoaded = true;
                                    vm.name = vm.formName;
                                    vm.file = new Blob([response.data], {type: 'application/pdf'});
                                    vm.pdfPath = URL.createObjectURL(vm.file);
                                    vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                
                                }).finally(() => {
                                    vm.loading = false;
                                });
                           }
                           else {
                               WorkflowService.doesTaxFormExist(vm.formName, vm.taxYear, vm.formType).then(function (data) {
                                   if (angular.equals({}, data) || data.fileNotFound.length > 0) {
                                       vm.loading = false;
                                       vm.formNotAvail = true;
                                       $rootScope.$broadcast('pdf form is not found');
                                       AlertService.add("warning", angular.equals({}, data) ? 'Form ' + vm.formName + ' is not found! ' : data.fileNotFound, 4000);
                                   } else {
                                       if (data.error.length > 0) {
                                           vm.loading = false;
                                           AlertService.add("error", data.error, 4000);
                                           return;
                                       }
                                       NewProformaEfileViewFactory.fillNewProformaForm(
                                           vm.formName,
                                           data.result,
                                           vm.formSubCat,
                                           vm.formSub,
                                           vm.leId,
                                           vm.parentLeId,
                                           vm.taxYear,
                                           vm.cdrNo || '',
                                           vm.rptPeriod || '',
                                           vm.systemFlag,
                                           vm.irsFormName,
                                           vm.schedule,
                                           vm.line,
                                           vm.partNo,
                                           vm.leName,
                                           vm.leType,
                                           vm.ein,
                                           vm.isState ? '253': '250',
                                           vm.scenarioLocal,
                                           vm.srcType,
                                           vm.jobId,
                                           vm.selectedEntityType,
                                           vm.selectedConsolGroup,
                                           vm.consolType,
                                           vm.keyId,
                                           vm.formType
                                   ).then((response) => {
                                            if (!response.data) {
                                                AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                                return;
                                            }
                                            if(response.data && response.data.byteLength === 0){
                                                vm.noDataAvail = true;
                                                return;
                                            }
    
                                            vm.formLoaded = true;
                                            vm.name = vm.formName;
                                            vm.file = new Blob([response.data], {type: 'application/pdf'});
                                            vm.pdfPath = URL.createObjectURL(vm.file);
                                            vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                            if(vm.srcType === 'DOM'){
                                                NewProformaFormReviewFactory[cacheStore].gtwViewPdf[vm.cacheParams] = vm.content;
                                            }else{
                                                NewProformaEfileViewFactory.cache[cacheStore].pdfBinary[vm.cacheParams] = vm.content;

                                            }
                                        
                                       }).finally(() => {
                                           vm.loading = false;
                                       });
                                   }
                               });
                           }
                       }

                   }

                   vm.loadPdf();
               }
           }])
        .directive('pdfPreview', [function () {

            // 1. Takes LEID, FormName and divHeight as params
            // 2. Checks if the form pdf exits in s3
            // 3. Fills form and renders the PDf
            return {
                controllerAs: 'ctrl',
                bindToController: true,
                controller: ['$scope', 'WorkflowService', '$sce', 'AlertService', 'workspaceFactory', 'NewProformaFactory', 'NewProformaEfileViewFactory', '$rootScope','GlobalService', PDFPreviewCtrl],
                template: '<div modal-loader loading="ctrl.loading"></div><span ng-if="ctrl.formNotAvail">Error loading the requested form!</span><iframe src="{{ctrl.content}}" ng-if="ctrl.formLoaded && ctrl.content" ng-style="{\'height\': \'{{ctrl.divHeight}}\'}" style="width:100%;"></iframe>',
                scope: {
                    parentLeId: '=',
                    leId: '=',
                    formName: '=',
                    formNameIdent: '=',
                    divHeight: '=',
                    taxYear: '=',
                    scenarioLocal: '=',
                    cdrNo: '=',
                    rptPeriod: '=',
                    leType: '=',
                    systemFlag: '=',
                    s3UrlList: '=',
                    isMerge: '=',
                    irsFormName: '=',
                    wpIrsFormNo: '=',
                    jobId: '=',
                    schedule: '=',
                    line: '=',
                    partNo: '=',
                    callIrsNewProforma: '=',
                    callNewProforma: '=',
                    isNewProforma: '=',
                    formSub:'=',
                    formSubCat:'=',
                    leName:'=',
                    ein:'=',
                    selectedEntityType: '=',
                    selectedConsolGroup: '='
                },
                link: link
            }

            function link(scope, element, attr, ctrl) {
                var $expandPdf = $('#expand-pdf');

                if ($expandPdf.length > 0) {
                    $expandPdf.on('click', function () {
                        if (ctrl.content)
                            window.open(ctrl.content, '_blank');
                    })
                }
            }

            function PDFPreviewCtrl($scope, WorkflowService, $sce, AlertService, workspaceFactory, NewProformaFactory, NewProformaEfileViewFactory, $rootScope,GlobalService) {
                var vm = this;
                vm.formLoaded = false;
                vm.loading = false;
                var taxYear = vm.taxYear || 2018;
                var cdrNo = vm.cdrNo || 'LY1';
                var rptPeriod = vm.rptPeriod || 1;
                var leType = $scope.$parent.leType ? $scope.$parent.leType : vm.leType ? vm.leType : 'HO';
                var systemFlag = $scope.$parent.systemFlag ? $scope.$parent.systemFlag : vm.systemFlag ? vm.systemFlag : 'F';
                var scenario = GlobalService.globalParams.scenario;
                // console.log($scope.$parent.ctrl.workflow.details);
                // var entityId = workspaceFactory.getDataCache("entity_id");

                $scope.$watch('ctrl.formNameIdent', function (newValue, oldValue) {
                    if (newValue && newValue !== oldValue && newValue.length > 0) {
                        vm.formLoaded = false;
                        vm.loadPdf();
                    }
                });

                vm.loadPdf = function () {
                    var form_name = vm.formName;
                    vm.loading = true;
                    vm.formNotAvail = false;
                    var irs_form_name = vm.irsFormName;

                    if (vm.isMerge) {
                        if (vm.callNewProforma) {
                            NewProformaFactory.fillFormAndMergeNewPorformaAsync(vm.formSub, vm.jobId, irs_form_name, form_name, vm.s3UrlList, vm.formSubCat,
                                vm.leId, taxYear, scenario, cdrNo, rptPeriod, leType, systemFlag, vm.schedule, vm.line, vm.partNo, vm.leName, vm.ein ,vm.selectedEntityType,
                                vm.selectedConsolGroup)
                                              .then((response) => {
                                                  if (response) {
                                                      vm.loading = false;
                                                      vm.formLoaded = true;
                                                      vm.name = '5471';
                                                      vm.file = new Blob([response.data], {type: 'application/pdf'});
                                                      vm.pdfPath = URL.createObjectURL(vm.file);
                                                      vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                                  } else {
                                                      vm.loading = false;
                                                      AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                                  }
                                              })
                        } else {
                            WorkflowService.fillFormAndMergePorforma(vm.jobId, vm.isNewProforma, vm.formSub, irs_form_name, form_name, vm.s3UrlList, vm.formSubCat,
                                vm.leId, taxYear, cdrNo, rptPeriod, leType, systemFlag, vm.leName, vm.ein,vm.schedule, vm.line, vm.partNo, vm.selectedEntityType,
                                vm.selectedConsolGroup).then(
                                function (response) {
                                    if (response) {
                                        vm.loading = false;
                                        vm.formLoaded = true;
                                        vm.name = '5471';
                                        vm.file = new Blob([response], {type: 'application/pdf'});
                                        vm.pdfPath = URL.createObjectURL(vm.file);
                                        vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                    } else {
                                        vm.loading = false;
                                        AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                    }
                                });
                        }
                    } else {
                        WorkflowService.doesTaxFormExist(form_name).then(function (data) {
                            if (angular.equals({}, data) || data.fileNotFound.length > 0) {
                                vm.loading = false;
                                vm.formNotAvail = true;
                                $rootScope.$broadcast('pdf form is not found');
                                AlertService.add("warning", angular.equals({}, data) ? 'Form ' + form_name + ' is not found! ' : data.fileNotFound, 4000);
                            } else {
                                if (data.error.length > 0) {
                                    vm.loading = false;
                                    AlertService.add("", data.error, 4000);
                                    return;
                                }

                                if (vm.callNewProforma) {
                                    NewProformaFactory.fillFormNewProforma(vm.formSub, form_name, vm.jobId, data.result,data.form_sub_cat, vm.leId, taxYear,
                                        cdrNo, rptPeriod, systemFlag, irs_form_name, vm.schedule, vm.line, vm.partNo, vm.leName, vm.ein, scenario, leType,
                                        vm.selectedEntityType, vm.selectedConsolGroup)
                                                      .then((response) => {
                                                          if (response.data) {
                                                              vm.formLoaded = true;
                                                              vm.name = form_name;
                                                              vm.file = new Blob([response.data], {type: 'application/pdf'});
                                                              vm.pdfPath = URL.createObjectURL(vm.file);
                                                              vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                                          } else {
                                                              AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                                          }
                                                      })
                                                      .finally(() => {
                                                          vm.loading = false;
                                                      });
                                } else if (vm.callIrsNewProforma) {
                                    NewProformaEfileViewFactory.fillNewProformaForm(
                                                                irs_form_name,
                                                                data.result,
                                                                vm.formSubCat,
                                                                vm.formSub,
                                                                vm.leId,
                                                                vm.parentLeId,
                                                                vm.taxYear,
                                                                vm.cdrNo,
                                                                vm.rptPeriod,
                                                                vm.systemFlag,
                                                                vm.wpIrsFormNo,
                                                                vm.schedule,
                                                                vm.line,
                                                                vm.partNo,
                                                                vm.leName,
                                                                vm.ein,
                                                                vm.jcdKey,
                                                                vm.scenarioLocal,
                                                                vm.srcType)
                                                             .then((response) => {
                                                                 if (response.data) {
                                                                     vm.formLoaded = true;
                                                                     vm.name = form_name;
                                                                     vm.file = new Blob([response.data], {type: 'application/pdf'});
                                                                     vm.pdfPath = URL.createObjectURL(vm.file);
                                                                     vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                                                 } else {
                                                                     AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                                                 }
                                                             })
                                                             .finally(() => {
                                                                 vm.loading = false;
                                                             });
                                } else {
                                    WorkflowService.fillFormPorforma(vm.formSub, irs_form_name, form_name, vm.leId, data.result, data.form_sub_cat, taxYear,
                                        cdrNo, rptPeriod, leType, systemFlag, vm.leName, vm.ein, vm.schedule, vm.line, vm.partNo, vm.selectedEntityType,
                                        vm.selectedConsolGroup).then(function (response) {
                                        // skip deferred response where the latest form selection does not match the incoming response
                                        if (form_name !== vm.formName) { return; }
                                        if (data) {
                                            vm.loading = false;
                                            vm.formLoaded = true;
                                            // vm.isDelete(id);
                                            vm.name = form_name;
                                            vm.file = new Blob([response], {type: 'application/pdf'});
                                            vm.pdfPath = URL.createObjectURL(vm.file);
                                            vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                                        } else {
                                            vm.loading = false;
                                            AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                        }
                                    });
                                }
                            }
                        });
                    }

                }

                vm.loadPdf();
            }
        }])
        .directive('filtersSelectSingle', ['$log', '$timeout', function ($log, $timeout) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                scope: {
                    filter: '=',
                    callbackFn: '&',
                    callbackModel: '=',
                },
                controller: ['$scope', function ($scope) {

                    var vm = this;
                    vm.values = [];
                    vm.model = [];


                    $scope.$watch('filter.selected', function (newVal, oldVal) {
                        vm.model = newVal
                        console.log(" vm.model", vm.model);
                    }, true);


                    $scope.$watch('filter.values', function (newVal, oldVal) {
                        if (typeof newVal !== "undefined" && newVal != null) {
                            var array = $scope.filter.values;
                            vm.values = array;
                        }
                    }, true);


                    vm.change = function () {
                        $scope.filter.selected = vm.model;
                        $scope.callbackModel = _.map($scope.filter.selected, 'value')
                        $timeout(function () {
                            $scope.callbackFn({arg1: 22});
                        }, 10);

                    }


                    vm.settings = {
                        enableSearch: true,
                        displayProp: 'name',
                        idProp: 'value',
                        externalIdProp: '',
                        selectionLimit: 1,
                        scrollableHeight: '400px',
                        scrollable: true,
                        buttonClasses: 'btn btn-default btn-filter btn-sm',
                        showUncheckAll: false
                    };


                    var customTexts = "Select  " + $scope.filter.name;
                    vm.example5customTexts = {buttonDefaultText: customTexts};
                }],


                template: '<div>' +
                    '<div class="gt-filter-loading" ng-show="filter.loading"><i class="fa fa-spinner fa-spin"></i></div>' +
                    '<div ng-show="!filter.loading" ng-dropdown-multiselect ng-click="ctrl.change()"  search-filter="customFilter" options="ctrl.values" selected-model="ctrl.model" extra-settings="ctrl.settings" translation-texts="ctrl.example5customTexts"></div>' +
                    '</div>',

                link: function ($scope, element, attrs) {

                }
            };
        }])


        .directive("fixedHeader", ['$window', function ($window) {
            return function (scope, element, attrs) {
                // var startPoint = element[0].offsetTop;
                // angular.element($window).bind("scroll", function () {
                //     var offset = startPoint + this.pageYOffset;
                //     element[0].style.top = offset + 'px';
                // });

                // var container = $(element);
                // container.on("mousewheel", scrollHorizontally);//chrome, IE
                // container.on("DOMMouseScroll", scrollHorizontally);//firefox


                // function scrollHorizontally(e) {

                //     e = window.event || e.originalEvent;
                //     var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                //     console.log("delta", delta)
                //     container[0].scrollLeft -= delta * 100;
                //     e.preventDefault();
                // }
            };
        }])


        .controller('formDatePickerCtrl', ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
            var vm = this;
            var ngModel = $element.controller('ngModel');
            vm.disableCheckbox = $attrs.disableCheckbox ? $attrs.disableCheckbox : false;
            vm.inputModel = 'VARIOUS';

            ngModel.$render = function () {
                vm.isVarious = ngModel.$modelValue === 'VARIOUS';
                vm.dateModel = vm.isVarious ? null : ngModel.$modelValue;
            }

            vm.dateOptions = {
                dateDisabled: false,
                formatYear: 'yyyy',
                startingDay: 1,
                showWeeks: false
            };
            vm.onChange = function () {
                if (vm.isVarious) {
                    ngModel.$setViewValue(vm.inputModel);
                } else {
                    ngModel.$setViewValue(vm.dateModel);
                }
            };
        }])

        // directive for selecting alpha-numeric validation

        .directive('alphaNumericValidator', function () {
            function link(scope, elem, attrs, ngModel) {
                ngModel.$parsers.push(function (viewValue) {
                    var reg = '^[a-zA-Z0-9 ]*$';
                    // if view values matches regexp, update model value
                    if (viewValue.match(reg)) {
                        return viewValue;
                    }
                    // keep the model value as it is
                    var transformedValue = ngModel.$modelValue;
                    ngModel.$setViewValue(transformedValue);
                    ngModel.$render();
                    return transformedValue;
                });
            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: link
            };
        })

        .directive('alphabaticValidator', function () {
            function link(scope, elem, attrs, ngModel) {
                ngModel.$parsers.push(function (viewValue) {
                    var reg = '^[a-zA-Z]*$';
                    // if view values matches regexp, update model value
                    if (viewValue.match(reg)) {
                        return viewValue;
                    }
                    // keep the model value as it is
                    var transformedValue = ngModel.$modelValue;
                    ngModel.$setViewValue(transformedValue);
                    ngModel.$render();
                    return transformedValue;
                });
            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: link
            };
        })

        .directive('capitalizeInputText', function() {
            function link(scope, elem, attrs, ngModel) {
                ngModel.$parsers.push(function (viewValue) {
                    let capitalizedValue = viewValue.toUpperCase();
                    if(viewValue === undefined) {
                        viewValue = "";
                    }
                    else {
                        if (capitalizedValue !== viewValue) {
                            ngModel.$setViewValue(capitalizedValue);
                            ngModel.$render();
                        }
                    }
                    return capitalizedValue;
                });
                //capitalizeInputText(scope[attrs.ngModel]);
            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: link
            };
        })

        .directive('gtwOnlyZeroThroughNine', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return digits;
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })

        .directive('addressValidator', function () {
            function link(scope, elem, attrs, ngModel) {
                ngModel.$parsers.push(function (viewValue) {
                    var reg = '^[#.0-9a-zA-Z\s,- ]+$';
                    // if view values matches regexp, update model value
                    if (viewValue.match(reg)) {
                        return viewValue;
                    }
                    // keep the model value as it is
                    var transformedValue = ngModel.$modelValue;
                    ngModel.$setViewValue(transformedValue);
                    ngModel.$render();
                    return transformedValue;
                });
            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: link
            };
        })

        // helps to pass ng-controller dynamically (directive) for worfklow entity Mapping 04/04/2019
        .directive('dynamicNgCtrl', ['$compile', '$parse', function ($compile, $parse) {
            return {
                restrict: 'A',
                terminal: true,
                priority: 100000,
                link: function (scope, elem) {
                    var ctrlName = $parse(elem.attr('dynamic-ng-ctrl'))(scope);
                    elem.removeAttr('dynamic-ng-ctrl');
                    elem.attr('ng-controller', ctrlName);
                    $compile(elem)(scope);
                }
            };
        }])

        /* 08/26/2019 dvalaboju001

        allow only digits
        allow -ve number if  attr(allow-negative  = "true")
        allow decimals
        allow decimals with given number of precisions : attr ex: decimal-upto="7(anyvalue)"
        allow integers with length : pass the limitTo attr value
       ex: <input class="form-control" valid-number ng-model="negativeNumber" placeholder="" allow-decimal="false" allow-negative="true" type="text">
           <input class="form-control" valid-number ng-model="decimal" placeholder="" allow-decimal="true" allow-negative="false" type="text">
           	<input class="form-control" valid-number ng-model="decimalUpto" placeholder="" allow-decimal="true" allow-negative="false" decimal-upto="7" type="text">*/
        .directive('validNumber', function () {
            return {
                require: '?ngModel',
                link: function (scope, element, attrs, ngModelCtrl) {

                    element.on('keydown', function (event) {
                        var keyCode = []
                        if (attrs.allowNegative == "true") {
                            keyCode = [8, 9, 36, 35, 37, 39, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 173, 190, 189];
                        } else {
                            var keyCode = [8, 9, 36, 35, 37, 39, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 173, 190];
                        }
                        /* integer with length */
                        if (attrs && attrs.allowDecimal == "false" && attrs.limitTo && attrs.limitTo.length > 0) {
                            var index = keyCode.indexOf(190);
                            var limit = parseInt(attrs.limitTo);
                            if (index > -1) {
                                keyCode.splice(index, 1);
                                if (event.keyCode > 47 && event.keyCode < 127) {
                                    if (element.val().length == limit)
                                        return false;
                                }
                            }

                        } else if (attrs.allowDecimal == "false") {
                            var index = keyCode.indexOf(190);
                            if (index > -1) {
                                keyCode.splice(index, 1);
                            }
                        }

                        if ($.inArray(event.which, keyCode) == -1) event.preventDefault();
                        else {
                            console.log(2);
                            var oVal = ngModelCtrl.$modelValue || '';
                            if ($.inArray(event.which, [109, 173]) > -1 && oVal.indexOf('-') > -1) event.preventDefault();
                            else if ($.inArray(event.which, [110, 190]) > -1 && oVal.indexOf('.') > -1) event.preventDefault();
                        }
                    })
                        .on('blur', function () {
                            /* by default two decimal points */
                            if (element.val() == '' || parseFloat(element.val()) == 0.0 || element.val() == '-') {
                                ngModelCtrl.$setViewValue('0.00');
                            } else if (attrs.allowDecimal == "false") {
                                ngModelCtrl.$setViewValue(element.val());
                            } else {
                                if (attrs.decimalUpto) {
                                    var fixedValue = parseFloat(element.val()).toFixed(attrs.decimalUpto);
                                } else {
                                    var fixedValue = parseFloat(element.val()).toFixed(2);
                                }
                                ngModelCtrl.$setViewValue(fixedValue);
                            }


                            ngModelCtrl.$render();
                            scope.$apply();
                        });

                    ngModelCtrl.$parsers.push(function (text) {
                        var oVal = ngModelCtrl.$modelValue;
                        var nVal = ngModelCtrl.$viewValue;
                        console.log(nVal);
                        if (parseFloat(nVal) != nVal) {

                            if (nVal === null || nVal === undefined || nVal == '' || nVal == '-') oVal = nVal;

                            ngModelCtrl.$setViewValue(oVal);
                            ngModelCtrl.$render();
                            return oVal;
                        } else {  /* given number of decimal points */
                            var decimalCheck = nVal.toString().split('.');
                            if (!angular.isUndefined(decimalCheck[1])) {
                                if (attrs.decimalUpto)
                                    decimalCheck[1] = decimalCheck[1].slice(0, attrs.decimalUpto);
                                else
                                    decimalCheck[1] = decimalCheck[1].slice(0, 2);
                                nVal = decimalCheck[0] + '.' + decimalCheck[1];
                            }

                            ngModelCtrl.$setViewValue(nVal);
                            ngModelCtrl.$render();
                            return nVal;
                        }
                    });

                    ngModelCtrl.$formatters.push(function (text) {
                        if (text == '0' || text == null && attrs.allowDecimal == "false") return '0';
                        else if (text == '0' || text == null && attrs.allowDecimal != "false" && attrs.decimalUpto == undefined) return '0.00';
                        else if (text == '0' || text == null && attrs.allowDecimal != "false" && attrs.decimalUpto != undefined) return parseFloat(0).toFixed(attrs.decimalUpto);
                        else if (attrs.allowDecimal != "false" && attrs.decimalUpto != undefined) return parseFloat(text).toFixed(attrs.decimalUpto);
                        else return parseFloat(text).toFixed(2);
                    });
                }
            };
        })

        .provider('scrollableTabsetConfig', function () {

                //the default options
                var defaultConfig = {
                    showDropDown: true,
                    showTooltips: false,
                    tooltipLeftPlacement: 'right',
                    tooltipRightPlacement: 'left',
                    scrollBy: '50',
                    autoRecalculate: false,
                    leftScrollAddition: '0'
                };

                var config = angular.extend({}, defaultConfig);

                return {
                    setShowDropDown: function (value) {
                        config.showDropDown = (value == true);
                    },
                    setShowTooltips: function (value) {
                        config.showTooltips = (value == true);
                    },
                    setTooltipLeftPlacement: function (value) {
                        config.tooltipLeftPlacement = value;
                    },
                    setTooltipRightPlacement: function (value) {
                        config.tooltipRightPlacement = value;
                    },
                    setScrollBy: function (value) {
                        config.scrollBy = value;
                    },
                    setAutoRecalculate: function (value) {
                        config.autoRecalculate = (value == true);
                    },
                    setLeftScrollAddition: function (value) {
                        config.leftScrollAddition = value;
                    },
                    $get: function () {
                        return {
                            showDropDown: config.showDropDown,
                            showTooltips: config.showTooltips,
                            tooltipLeftPlacement: config.tooltipLeftPlacement,
                            tooltipRightPlacement: config.tooltipRightPlacement,
                            scrollBy: config.scrollBy,
                            autoRecalculate: config.autoRecalculate,
                            leftScrollAddition: config.leftScrollAddition
                        };
                    }
                };
            }
        )
        .directive('scrollableTabset', [
            'scrollableTabsetConfig', '$window', '$interval', '$timeout', '$sce',
            function (scrollableTabsetConfig, $window, $interval, $timeout, $sce) {

                return {
                    restrict: 'AE',
                    transclude: true,

                    scope: {
                        showDropDown: '@',
                        showTooltips: '@',
                        tooltipLeftPlacement: '@',
                        tooltipRightPlacement: '@',
                        scrollBy: '@',
                        autoRecalculate: '@',
                        isButtonsVisible: '=?',
                        dropDownClass: '@?',
                        dropDownMenuClass: '@?',
                        dropDownHeaderTemplateUrl: '@?',
                        dropDownHeaderClass: '@?',
                        api: '=?',
                        leftScrollAddition: '@',
                        updateFn: '='
                    },

                    template: [
                        '<div class="ui-tabs-scrollable" ng-class="{\'show-drop-down\': !hideDropDown}">',
                        '<button type="button" ng-mousedown="scrollButtonDown(\'left\', $event)" ng-mouseup="scrollButtonUp()" ng-hide="hideButtons"' +
                        ' ng-disabled="disableLeft" class="btn nav-button left-nav-button"' +
                        ' tooltip-placement="{{tooltipLeftDirection}}" uib-tooltip-html="tooltipLeftHtml" class="scrollable-tabs-tooltip"></button>',
                        '<div class="spacer" ng-class="{\'hidden-buttons\': hideButtons}" ng-transclude></div>',
                        '<button type="button" ng-mousedown="scrollButtonDown(\'right\', $event)" ng-mouseup="scrollButtonUp()" ng-hide="hideButtons"' +
                        ' ng-disabled="disableRight" class="btn nav-button right-nav-button"' +
                        ' tooltip-placement="{{tooltipRightDirection}}" uib-tooltip-html="tooltipRightHtml"></button>',
                        '<div class="btn-group" ng-class="::dropDownClass" uib-dropdown dropdown-append-to-body ng-hide="hideDropDown">',
                        '<button type="button" class="btn" uib-dropdown-toggle></button>',
                        '<ul class="dropdown-menu scrollable-tabs" uib-dropdown-menu role="menu" ng-class="::dropDownMenuClass||\'dropdown-menu-right\'">',
                        '<li ng-class="::dropDownHeaderClass" ng-include="::dropDownHeaderTemplateUrl"></li>',
                        '<li role="menuitem" ng-repeat="tab in dropdownTabs" ng-class="{\'disabled\': tab.disabled, \'active\': tab.active}" ng-click="activateTab(tab)">',
                        '<a ui-sref="{{tab.link}}" >',
                        // '<span class="dropDownTabActiveMark" ng-style="{\'visibility\': tab.active?\'visible\':\'hidden\'}"></span>',
                        '{{tab.tabScrollTitle}}',
                        '</a>',
                        '</li>',
                        '</ul>',
                        '</div>',
                        '</div>'
                    ].join(''),

                    link: function ($scope, $el) {

                        $scope.dropdownTabs = [];
                        $scope.hideButtons = true;
                        $scope.hideDropDown = true;
                        $scope.tooltipRightHtml = '';
                        $scope.tooltipRighTabs = [];
                        $scope.tooltipLeftHtml = '';
                        $scope.tooltipLeftTabs = [];
                        $scope.disableLeft = true;
                        $scope.disableRight = true;
                        $scope.tooltipLeftDirection = $scope.tooltipLeftPlacement ? $scope.tooltipLeftPlacement : scrollableTabsetConfig.tooltipLeftPlacement;
                        $scope.tooltipRightDirection = $scope.tooltipRightPlacement ? $scope.tooltipRightPlacement : scrollableTabsetConfig.tooltipRightPlacement;
                        $scope.mouseDownInterval = null;
                        $scope.isHolding = false;
                        $scope.winResizeTimeout;
                        $scope.userShowDropDown = $scope.showDropDown ? $scope.showDropDown === 'true' : scrollableTabsetConfig.showDropDown;
                        $scope.userShowTooltips = $scope.showTooltips ? $scope.showTooltips === 'true' : scrollableTabsetConfig.showTooltips == true;
                        $scope.scrollByPixels = parseInt($scope.scrollBy ? $scope.scrollBy : scrollableTabsetConfig.scrollBy);
                        $scope.leftScrollAdditionPixels = parseInt($scope.leftScrollAddition ? $scope.leftScrollAddition : scrollableTabsetConfig.leftScrollAddition);

                        $scope.api = {
                            doRecalculate: function () {
                                $timeout(function () {
                                    $scope.reCalcAll()
                                });
                            },

                            init: function () {
                                $timeout(function () {
                                    $scope.init()
                                });
                            },

                            scrollTabIntoView: function (arg) {
                                $timeout(function () {
                                    $scope.scrollTabIntoView(arg)
                                });
                            }
                        };

                        $scope.scrollTo = function (element, change, duration, callback, isLinear) {
                            var start = element.scrollLeft;
                            var increment = 20;
                            var position = 0;

                            var animateScroll = function (elapsedTime) {
                                elapsedTime += increment;
                                if (isLinear === true) {
                                    position = $scope.linearTween(elapsedTime, start, change, duration);
                                } else {
                                    position = $scope.easeInOutQuad(elapsedTime, start, change, duration);
                                }
                                element.scrollLeft = position;
                                if (elapsedTime < duration) {
                                    setTimeout(function () {
                                        animateScroll(elapsedTime);
                                    }, increment);
                                } else {
                                    callback();
                                }
                            };

                            animateScroll(0);
                        }

                        $scope.linearTween = function (currentTime, start, change, duration) {
                            return change * currentTime / duration + start;
                        };

                        $scope.easeInOutQuad = function (currentTime, start, change, duration) {
                            currentTime /= duration / 2;
                            if (currentTime < 1) {
                                return change / 2 * currentTime * currentTime + start;
                            }
                            currentTime--;
                            return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
                        }

                        $scope.onWindowResize = function () {
                            // delay for a bit to avoid running lots of times.
                            clearTimeout($scope.winResizeTimeout);
                            $scope.winResizeTimeout = setTimeout(function () {
                                $scope.reCalcAll();
                                $scope.scrollTabIntoView();
                                $scope.$apply();
                            }, 250);
                        };

                        $scope.cancelMouseDownInterval = function () {
                            $scope.isHolding = false;

                            if ($scope.mouseDownInterval) {
                                $interval.cancel($scope.mouseDownInterval);
                                $scope.mouseDownInterval = null;
                            }
                        };

                        $scope.scrollButtonDown = function (direction, event) {
                            event.stopPropagation();
                            $scope.isHolding = true;

                            var realScroll = direction === 'left' ? 0 - $scope.scrollByPixels : $scope.scrollByPixels;
                            $scope.scrollTo($scope.tabContainer, realScroll, 150, function () {
                                $timeout(function () {
                                    $scope.reCalcSides();
                                });
                            }, true);

                            $scope.mouseDownInterval = $interval(function () {

                                if ($scope.isHolding) {
                                    $scope.scrollTo($scope.tabContainer, realScroll, 150, function () {
                                        $timeout(function () {
                                            $scope.reCalcSides();
                                        });
                                    }, true);

                                    if (event.target.disabled) {
                                        $scope.cancelMouseDownInterval();
                                    }
                                }
                            }, 100);
                        }

                        $scope.scrollButtonUp = function () {
                            $scope.cancelMouseDownInterval();
                        }

                        $scope.activateTab = function (tab) {
                            if (tab.disabled) return;
                            // tab.select();
                            $scope.updateFn(tab);
                            $timeout(function () {
                                $scope.scrollTabIntoView();
                            }, 500);
                        }

                        $scope.reCalcSides = function () {
                            if (!$scope.tabContainer || $scope.hideButtons) return;
                            $scope.disableRight = $scope.tabContainer.scrollLeft >= $scope.tabContainer.scrollWidth - $scope.tabContainer.offsetWidth;
                            $scope.disableLeft = $scope.tabContainer.scrollLeft <= 0;

                            // if($scope.userShowTooltips){
                            $scope.reCalcTooltips();
                            // }
                        };

                        $scope.reCalcTooltips = function () {
                            if (!$scope.tabContainer || $scope.hideButtons) return;
                            var rightTooltips = [];
                            var leftTooltips = [];

                            var allTabs = $scope.tabContainer.querySelectorAll('ul.nav-tabs > li');
                            angular.forEach(allTabs, function (tab) {

                                var rightPosition = parseInt(tab.getBoundingClientRect().left + tab.getBoundingClientRect().width - $scope.tabContainer.getBoundingClientRect().left);
                                var leftPosition = tab.getBoundingClientRect().left - $scope.tabContainer.getBoundingClientRect().left;
                                var heading = tab.getAttribute("data-tabScrollHeading");
                                var ignore = tab.getAttribute("data-tabScrollIgnore");

                                if (rightPosition > $scope.tabContainer.offsetWidth && !ignore) {
                                    if (heading) {
                                        rightTooltips.push(heading)
                                    } else if (tab.textContent) rightTooltips.push(tab.textContent);
                                }

                                if (leftPosition < 0 && !ignore) {
                                    if (heading) {
                                        leftTooltips.push(heading)
                                    } else if (tab.textContent) leftTooltips.push(tab.textContent);
                                }

                            });

                            if ($scope.userShowTooltips) {
                                var rightTooltipsHtml = rightTooltips.join('<br>');
                                $scope.tooltipRightHtml = $sce.trustAsHtml(rightTooltipsHtml);
                                var leftTooltipsHtml = leftTooltips.join('<br>');
                                $scope.tooltipLeftHtml = $sce.trustAsHtml(leftTooltipsHtml);
                            }

                            $scope.tooltipRighTabs = leftTooltips;


                            $scope.tooltipLeftTabs = rightTooltips;

                            //TODO
                            if (!$scope.hideButtons) {

                                if (!$scope.hideDropDown) {
                                    var allTabs = $scope.tabContainer.querySelectorAll('ul.nav-tabs > li');
                                    $scope.dropdownTabs = [];
                                    angular.forEach(allTabs, function (tab) {
                                        var ignore = tab.getAttribute("data-tabScrollIgnore");
                                        if (!ignore) {
                                            var heading = tab.getAttribute("data-tabScrollHeading");
                                            var tabScope = angular.element(tab).scope();
                                            //push new field to use as title in the drop down.
                                            if(tabScope){
                                                tabScope.tabScrollTitle = heading ? heading : tab.textContent.trim();
                                                tabScope.link = $(tab).attr('ui-sref');
                                                if ($scope.partOf($scope.tooltipRighTabs, tabScope.tabScrollTitle) || $scope.partOf($scope.tooltipLeftTabs, tabScope.tabScrollTitle)) {
                                                    $scope.dropdownTabs.push(tabScope);
                                                }
                                            }

                                        }
                                    });
                                } else {
                                    $scope.dropdownTabs = [];
                                }
                            } else {
                                $scope.dropdownTabs = [];
                            }


                        };

                        $scope.partOf = function (arr, str) {
                            let found = false;
                            arr.forEach(function (item) {
                                if (item.includes(str)) {
                                    found = true;
                                }
                            });
                            return found;
                        }

                        $scope.scrollTabIntoView = function (arg) {
                            if (!$scope.tabContainer || $scope.hideButtons) return;

                            var argInt = parseInt(arg);
                            var tabToScroll;

                            // first we find the tab element.
                            if (argInt) { // scroll tab index into view
                                var allTabs = $scope.tabContainer.querySelectorAll('ul.nav-tabs > li');
                                if (allTabs.length > argInt) { // only if its really exist
                                    tabToScroll = allTabs[argInt];
                                }
                            } else { // scroll selected tab into view
                                var activeTab = $scope.tabContainer.querySelector('li.active');
                                if (activeTab) {
                                    tabToScroll = activeTab;
                                }
                            }

                            // now let's scroll it into view.
                            if (tabToScroll) {
                                var rightPosition = parseInt(tabToScroll.getBoundingClientRect().left + tabToScroll.getBoundingClientRect().width - $scope.tabContainer.getBoundingClientRect().left);
                                var leftPosition = tabToScroll.getBoundingClientRect().left - $scope.tabContainer.getBoundingClientRect().left;
                                if (leftPosition - $scope.leftScrollAdditionPixels < 0) {
                                    // var dif = leftPosition - 20 - $scope.leftScrollAdditionPixels;
                                    var dif = leftPosition - $scope.leftScrollAdditionPixels;
                                    $scope.scrollTo($scope.tabContainer, dif, 700, function () {
                                        $timeout(function () {
                                            $scope.reCalcSides();
                                        });
                                    });
                                } else if (rightPosition > $scope.tabContainer.offsetWidth) {
                                    // var dif = rightPosition - $scope.tabContainer.offsetWidth + 20;
                                    var dif = rightPosition - $scope.tabContainer.offsetWidth;
                                    $scope.scrollTo($scope.tabContainer, dif, 700, function () {
                                        $timeout(function () {
                                            $scope.reCalcSides();
                                        });
                                    });
                                }
                            }
                        };

                        // init is called only once!
                        $scope.init = function () {
                            $scope.tabContainer = $el[0].querySelector('.spacer ul.nav-tabs');
                            if (!$scope.tabContainer) return;

                            var autoRecalc = $scope.autoRecalculate ? $scope.autoRecalculate === 'true' : scrollableTabsetConfig.autoRecalculate;
                            if (autoRecalc) {
                                var tabsetElement = angular.element($el[0].querySelector('.spacer div'));
                                $scope.$watchCollection(
                                    function () {
                                        return tabsetElement.isolateScope() ? tabsetElement.isolateScope().tabs : false;
                                    },
                                    function () {
                                        $timeout(function () {
                                            $scope.reCalcAll()
                                        });
                                    }
                                );
                            }

                            $scope.reCalcAll();
                            $timeout(function () {
                                $scope.scrollTabIntoView();
                            }, 500);

                            // attaching event to window resize.
                            angular.element($window).on('resize', $scope.onWindowResize);
                        };

                        // re-calculate if the scroll buttons are needed, than call re-calculate for both buttons.
                        $scope.reCalcAll = function () {
                            if (!$scope.tabContainer) return;

                            $scope.hideButtons = $scope.tabContainer.scrollWidth <= $scope.tabContainer.offsetWidth;
                            $scope.hideDropDown = $scope.userShowDropDown ? $scope.hideButtons : true;
                            $scope.isButtonsVisible = !$scope.hideButtons;

                            if (!$scope.hideButtons) {

                                if (!$scope.hideDropDown) {
                                    var allTabs = $scope.tabContainer.querySelectorAll('ul.nav-tabs > li');
                                    $scope.dropdownTabs = [];
                                    angular.forEach(allTabs, function (tab) {
                                        var ignore = tab.getAttribute("data-tabScrollIgnore");
                                        if (!ignore) {
                                            var heading = tab.getAttribute("data-tabScrollHeading");
                                            var tabScope = angular.element(tab).scope();
                                            //push new field to use as title in the drop down.
                                            if(tabScope){
                                                tabScope.tabScrollTitle = heading ? heading : tab.textContent.trim();
                                                $scope.dropdownTabs.push(tabScope);
                                            }
                                        }
                                    });
                                } else {
                                    $scope.dropdownTabs = [];
                                }

                                $scope.reCalcSides();
                            } else {
                                $scope.dropdownTabs = [];
                            }
                        };

                        // this is how we init for the first time.
                        $timeout(function () {
                            $scope.init();
                        });

                        // when scope destroyed
                        $scope.$on('$destroy', function () {
                            angular.element($window).off('resize', $scope.onWindowResize);
                            $scope.dropdownTabs = [];
                        });

                    }
                };
            }])

        // Global click out side used for global me selection dropdown(left nav panel)...
        .directive('globalMeClickOutSide',['$document','$timeout', function($document,$timeout){
            return {
                restrict: 'EA',
                require: '^globalMeSelection',
                scope: {
                    showMenu: '='
                },

                replace: true,
                link: function(scope, element, attr, ctrl){
                    $document.bind('click', checkShowHide);
                    function checkShowHide(event){
                        var isClickedElementChildOfPopup = element.find(event.target).length > 0;
                        if (isClickedElementChildOfPopup ){
                            return;
                        }else if( ctrl.showMenu === true ){
                            ctrl.showMenu = false;
                            scope.$apply();
                        }
                    }
                    scope.$on('$destroy', function () {
                        $($document).unbind('click',checkShowHide);
                    });
                }
            };
        }])
        .directive('ngEnter', function () {
            return function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.$eval(attrs.ngEnter);
                        });
                        event.preventDefault();
                    }
                });
            };
        })
        .directive('focusSelect', function () {
            return function (scope, element, attrs) {
                setTimeout(()=>{
                    element[0].focus();
                    element[0].select();
                })
            };
        })

        //LazyLoad directive to load more content based on scroller height..
        // .directive('gtLazyLoad', function() {
        //     return {
        //       restrict: 'A',
        //       link: function(scope, elem) {
        //         var scroller = elem[0]
        //         $(scroller).bind('scroll', function() {
        //           if (window.innerHeight + scroller.scrollTop + scroller.offsetHeight >= scroller.scrollHeight) {
            // scroller.scrollTop + scroller.offsetHeight >= scroller.scrollHeight - window.innerHeight put this in if conditiion
        //             scope.$apply('fetchMoreLazyLoadList()')
        //           }
        //         })
        //       }
        //     }
        // })

        .directive('gtLazyLoad', function() {
            return {
              restrict: 'A',
              link: function(scope, elem) {
                var scroller = elem[0]
                $(scroller).bind('scroll', function(ev) {
                    var sel = $(this);
                    var lasto = sel.find('li:last');
                    var s = sel.position().top + sel.height();
                    var o = lasto.height() + lasto.position().top - 4;
                    //setTimeout(function(){
                        if(o < s){
                            scope.$apply('fetchMoreLazyLoadList()')
                        }
                   // }, 2200);
                })
              }
            }
        })
        
        // Virtual scroller/Lazy Load dropdwon List directive..
        .directive('lazyLoadDropdown', [function () {
            return {
                restrict: 'EA',
                controllerAs: 'ctrl',
                scope: {
                    dropdownList: '=',
                    callBackMethod: '&',
                    passFormName: '@',
                    drpKeyName: '@',
                    callBackNgModel: '='
                },
                template:
                    '<div class="gt-lazy-load-drp-btn dropdown-toggle" data-toggle="dropdown" ng-model= "account.selected" name= "{{passFormName}}" required>' +
                        // '<span ng-if="showSelectedName == \'show\'">{{ctrl.selectedItem}}</span>' +    
                        '<span ng-if="callBackNgModel">{{callBackNgModel[drpKeyName]}}</span>' +
                        '<i class="fa fa-caret-down" style="float: right; padding: 7px 5px;"></i>' +
                    '</div>' +
                    '<div class="gt-lazy-dropdown-list dropdown-menu">' +
                        '<div class="sticky-search-bar">' +
                            '<input type="text" class="input-style" placeholder="Search..." ng-model="searchItem" ng-change="ctrl.searching(searchItem)" />' +
                            '<i class="fa fa-search search-icn"></i>' +
                        '</div>' +
                        '<ul class="list-ul" gt-lazy-load>' +
                            '<li ng-if="dropdownList.cachedDrpList && dropdownList.cachedDrpList.length > 0" class="gt-lazy-load-list-trunc" ng-repeat="account in dropdownList.cachedDrpList track by $index" ng-click="ctrl.selectedListItem(account);"> {{ account[drpKeyName] }}</li>' +
                            '<li ng-if="!(dropdownList.cachedDrpList && dropdownList.cachedDrpList.length > 0)" style="padding: 4px 8px; font-size: 13px; text-align: center;"><i>No search results found for \"{{searchItem}}\"</i></li>' +
                        '</ul>' +
                    '</div>',

                controller: 'lazyLoadCtrl'
            };
        }])
        .controller('lazyLoadCtrl', ['$scope', '$window', function ($scope, $window) {
            var vm = this;

            vm.searchResults = [];
            vm.searchValue = "";
            var listIndx = 0;

            vm.selectedListItem = function(selectedAccount) {
                $scope.callBackNgModel = selectedAccount;
                // vm.selectedItem = selectedAccount[$scope.drpKeyName];
                $scope.callBackMethod({arg1: selectedAccount});
            }

            // Dropdown list will generated based on the search creteria..
            vm.searching = function(string) {
                vm.searchValue = string.toLowerCase();
                if(vm.searchValue && vm.searchValue.length > 0) {
                    // var upperCase = string.toUpperCase();
                    vm.searchResults = _.filter($scope.dropdownList.totalDrpList, function(itr) {
                        var label = itr[$scope.drpKeyName].toLowerCase();
                        return label.indexOf(vm.searchValue)!== -1;
                    });
                    $scope.dropdownList.cachedDrpList = vm.searchResults.slice(0, 10);
                } else {
                    vm.resetList();
                }
            }

            // Called to load more list after reaching present content list..
            $scope.fetchMoreLazyLoadList = function() {
                listIndx = $scope.dropdownList.cachedDrpList.length;
                var r = 10;
                // if (listIndx + 10 > $scope.dropdownList.totalDrpList.length) {
                //     r = $scope.dropdownList.totalDrpList.length - listIndx;
                // }
                if(vm.searchValue && vm.searchValue.length > 0) {
                    $scope.dropdownList.cachedDrpList = _.concat($scope.dropdownList.cachedDrpList, (vm.searchResults.slice(listIndx, r + listIndx)));
                    // console.log($scope.dropdownList.cachedDrpList, "cachedList");
                    // console.log(vm.searchResults, "totalList");
                } else {
                    // used to show the list when ddropdown intial fetched..
                    $scope.dropdownList.cachedDrpList = _.concat($scope.dropdownList.cachedDrpList, ($scope.dropdownList.totalDrpList.slice(listIndx, r + listIndx)));
                    // console.log($scope.dropdownList.cachedDrpList, "intial fetched-cachedList");
                    // console.log($scope.dropdownList.totalDrpList, "intial fetched -totalList");
                }
            }

            // Clears Out the dropdown list..
            vm.resetList = function(){
                listIndx = 0
                $scope.dropdownList.cachedDrpList = $scope.dropdownList.totalDrpList.slice(0, 10);
            }

            // Used to reset dropdown list to default after intialising a new dropdown..
            vm.resetList();

        }])
        //End of Virtual scroller/Lazy Load dropdwon List directive..


        .directive('gtLazyEfileLoad', function() {
            return {
              restrict: 'EA',
              link: function(scope, elem) {
                var scroller = elem[0];
                var previousScroll = 0;
                $(scroller).bind('scroll', function(ev) {
                    var currentScroll = $(this).scrollTop();
                    var sel = $(this);
                    var index;
                    if(currentScroll > previousScroll && (scope.treeData.cachedTreeData.length != scope.ctrl.formTree.length)){
                        scope.$apply('fetchMoreLazyEfileLoadList()')
                        
                        //scope.$apply('lazyEfileLoadList()');
                        
                    }
                    previousScroll =  currentScroll;
                })
              }
            }
        })

        .directive('vrScrollTo',['$document','$rootScope',function($document,$rootScope) {
            return {
                restrict: 'A',
                scope: {
                    alldata : '='

                },                
                link: function(scope, elem, attr) {
                    var element = elem[0];
                    var previousScroll = 0;

                    function scrollHandler($event) {
                        let currentScroll = $(this).scrollTop();
                        let visibleList = [];
                        scope.alldata = _.find(scope.$parent.$parent.treeData.cachedTreeData,{FULLELEMENTNAME:element['id'], isTable: true});
                        console.log('scope.alldata',scope.alldata);
                        let visibleDatalength = scope.alldata.visibleRepeatedData.length;
                        let cachedData = _.clone(scope.alldata.repeatedData); //_.clone(scope.alldata.repeatedData.filter(function(e){return e!==undefined}));
                        let data = {};
                       const limit = 10;
                      if((Math.ceil($(this).scrollTop() + $(this).innerHeight())>= $(this)[0].scrollHeight)){ // scroll down
                            if(scope.alldata.visibleRepeatedData[visibleDatalength-1]['SNO'] != undefined){
                                let start = parseInt(scope.alldata.visibleRepeatedData[visibleDatalength-1]['SNO']);
                                let end = start + 10;
                                let visibleData = [];
                                let count = 0;
                               // const visibleData = cachedData.slice(start, end);
                                while(start <= cachedData.length && count<=limit){
                                    if(cachedData[start] != undefined && cachedData[start]['toDelete'] == undefined){
                                        visibleData.push(cachedData[start]);
                                        count++;
                                    }else{
                                        if(cachedData[start] != undefined){
                                            visibleData.push(cachedData[start]);

                                        }
                                    }
                                    start++;
                                }
                                console.log('visibleData',visibleData);       
                                let visibleDataLength = 0;
                                visibleDataLength =_.filter(visibleData,
                                    function(i){return  i && i['toDelete'] == undefined}).length;                       
                                if(visibleDataLength >= 6){
                                    _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                                        if(key['FULLELEMENTNAME'] == scope.alldata['FULLELEMENTNAME']  && key.isTable){
                                        key.visibleRepeatedData = visibleData;
                                        data.data = key;
                                        }
                                    });
                                    $rootScope.$broadcast('tableScroll',data);
                                }else{
                                    let dataArry = [];
                                    _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                                        if(key['FULLELEMENTNAME'] == scope.alldata['FULLELEMENTNAME'] && key.isTable){
                                            dataArry = key.visibleRepeatedData;
                                        }
                                    });
                                    _.each(visibleData,function(i){
                                        if(_.filter(dataArry,{SNO: i['SNO']}).length ==0){
                                            dataArry.push(i);
                                        }
                                    });
                                    _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                                        if(key['FULLELEMENTNAME'] == scope.alldata['FULLELEMENTNAME'] && key.isTable){
                                            key.visibleRepeatedData = dataArry;
                                            data.data = key;

                                        }
                                    });
                                    $rootScope.$broadcast('tableScroll',data);

                                }

                                 if(start >= scope.alldata.repeatedData.length){
                                    element.scrollTop = $(this)[0].scrollHeight;
                                }else{
                                    element.scrollTop = 50;
                                }
                            }

                      }else if(Math.floor($(this).scrollTop() == 0)){  // scroll up
                        if(scope.alldata.visibleRepeatedData[0]['SNO'] != undefined){
                            let start = parseInt(scope.alldata.visibleRepeatedData[0]['SNO']);
                            let arr = [];
                                let count = 0;
                                while(start <= cachedData.length && count<=limit && start > -1){
                                    /* if(cachedData[start] != undefined){
                                        visibleData.unshift(cachedData[start]);
                                        count++;
                                    }
                                    start--; */
                                    if(cachedData[start] != undefined && cachedData[start]['toDelete'] == undefined){
                                        arr.unshift(cachedData[start]);
                                        count++;
                                    }else if(cachedData[start] != undefined){
                                        arr.unshift(cachedData[start]);
                                    }
                                    start--; 
                                }
                                if( parseInt(arr[0]['SNO']) == parseInt(scope.alldata.cachedRepeatedData[1]['SNO'])){
                                    if(cachedData[start] != undefined){
                                        arr.unshift(cachedData[0]);
                                    }
                                }
                                element.scrollTop = 50;
                                console.log('visibleData',arr);

                                if(_.filter(arr,function(i){return i &&  i['toDelete'] == undefined}).length >= 6){                               
                                //if(visibleData.length>=6){
                                    _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                                        if(key['FULLELEMENTNAME'] == scope.alldata['FULLELEMENTNAME'] && key.isTable){
                                        key.visibleRepeatedData = arr;
                                        data.data = key;
                                        }
                                    });
                                    $rootScope.$broadcast('tableScroll',data);
                                }else{
                                 //   element.scrollTop = 0;
                                 let dArry = [];
                                 _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                                     if(key['FULLELEMENTNAME'] == scope.alldata['FULLELEMENTNAME'] && key.isTable){
                                        dArry = key.visibleRepeatedData;
                                     }
                                 });
                                 arr = _.reverse(arr);

                                 _.each(arr,function(i){
                                     if(_.filter(dArry,{SNO: i['SNO']}).length ==0){
                                        dArry.unshift(i);
                                     }
                                 });
                                 _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                                   //  if(key['ELEMENTNAME'] == scope.alldata['ELEMENTNAME']){
                                    if(key['FULLELEMENTNAME'] == element['id'] && key.isTable){
                                         key.visibleRepeatedData = dArry;
                                         data.data = key;
                                     }
                                 });
                                 $rootScope.$broadcast('tableScroll',data);

                                }
                               // if(start >= scope.alldata.repeatedData.length){
                                if(scope.alldata.visibleRepeatedData[0]['SNO'] == 0){
                                    element.scrollTop = 0;
                                }else{
                                    element.scrollTop = 50;
                                }
                            }
                        }
                    }
                      var throttledHandler = _.throttle(scrollHandler, 150);
    
                      element.addEventListener('scroll', throttledHandler);
                      
                      scope.$on('$destroy', function() {
                          console.log('I am fdestryoed');
                        element.removeEventListener('scroll',throttledHandler);
                      });


                  /*  $(scroller).bind('scroll', scrollMe);
                   function scrollMe(){
                        console.log('I am child table scroller');
                        let currentScroll = $(this).scrollTop();
                        let visibleList = [];
                        const rowHeight = document.getElementById(scope.alldata.ELEMENTNAME).getElementsByTagName('tr')[3].clientHeight;
                        let cachedData = _.clone(scope.alldata.repeatedData.filter(function(e){return e!==undefined}));

                        let scrollTop = $(this).scrollTop() + rowHeight;// - rowHeight;
                        const startNode = Math.ceil($(this).scrollTop() / rowHeight);
                        const visibleData = cachedData.slice(startNode, startNode+30);
                        const startNodeHeight = scrollTop + rowHeight;
                        const endNodeHeight = Math.max(0,(cachedData.length * rowHeight - startNodeHeight));

                        console.log('startNode',startNode);
                        console.log('visibleData',visibleData);
                        console.log('startNodeHeight',startNodeHeight);
                        console.log('endNodeHeight',endNodeHeight);
                        let data = {};
                       
                       
                        if(currentScroll != previousScroll && visibleData.length>7 ){
                           _.find(scope.$parent.$parent.treeData.cachedTreeData,function(key,i){
                               if(key['ELEMENTNAME'] == scope.alldata['ELEMENTNAME']){
                                key.visibleRepeatedData = visibleData//visibleList;
                                data.data = key;
                               }
                           });
                           data.startNodeIndex = startNode;
                           data.startNodeHeight =startNodeHeight ;
                           data.endRowHeight = endNodeHeight;
                           data.visibleData = visibleData;//cachedData.filter(function(e){return e!==undefined}).length * rowHeight - data.startNodeHeight
                          // ev.preventDefault();
                            setTimeout(function(){
                                scope.$on('$destroy', function () {
                                    console.log('I am destoyred');
                                    $(scroller).unbind('scroll', scrollMe);
                                });
                            },20)
                            $(scroller).unbind('scroll', scrollMe);

                           $rootScope.$broadcast('tableScroll',data);
                           previousScroll = currentScroll;    
                                              
                        }
                    }
                     */
                }
            }

        }])

        .directive('openModalUnderTrigger', function () {

            /**
             * Add this to modal-header or modal-body of any modal to open it under the triggering element
             */
            return {
                    scope:{
                        triggerElementId:'@'
                    }, 
                    link: function (scope, element, attrs) {
                        if(!scope.triggerElementId){
                            return;
                        }
                        
                        let modalDialog = element.parent().parent();
                        let $trigger =  $('#'+scope.triggerElementId);

                        let position =$trigger.offset();
                        modalDialog.css({
                            position:'absolute',
                            left:position.left - modalDialog.width() + $trigger.width(),
                            top:position.top
                        })
                }
            }
        })
        // Global me selection dropdown list directive...
        .directive('globalMeSelection', ['$window','$log', 'DataFiltersFactory','JsonObjectFactory','AlertService', 'workspaceFactory', function($window, $log, DataFiltersFactory,JsonObjectFactory,AlertService,workspaceFactory) {
            return {
                restrict: 'EA',
                transclude: true,
                controllerAs: 'ctrl',
                templateUrl: 'app/components/dataFilters/templates/me-drop-left-tpl.html',
                scope: {
                    filterss : '='
                },
                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                    var vm = this;
                    vm.menuObj1 = [];
                    vm.childMenuObj = [];
                    vm.totalSelected = 0;
                    vm.selected  = [];
                    vm.selectedArray  = [];
                    vm.showMenu = false;
                    vm.showRightMenu = false;

                    $scope.$watch('filterss.values', function(newVal, oldVal){
                        if( typeof newVal !== "undefined" && newVal !== null){
                            reset();
                            setTreeParent((newVal),'children' );
                            vm.menuObj1 = newVal;
                        }
                        getTotalSelected();
                    });
                    $scope.$watch('filterss.selected', function (newVal, oldVal) {
                        if(DataFiltersFactory.meVar == true) {
                            vm.selected = newVal;
                            vm.selectedArray = newVal;
                        }
                    });



                    function setTreeParent(_collection,_children){
                        for (var i = 0; i < _collection.length; i++) {
                            var thisChildren =  _collection[i][_children];
                            if( thisChildren && thisChildren.length ){
                                setTreeParent(thisChildren,_children);
                            }

                            for(var n in thisChildren){
                                thisChildren[n].parent = _collection[i];
                            }
                        }
                    }

                    function reset(){
                        vm.totalSelected = 0;
                        vm.childMenuObj = [];
                        vm.selected  = [];
                        vm.selectedArray  = [];
                        vm.showMenu = false;
                        vm.showRightMenu = false;
                    }

                    vm.getScrollHeight = function(){
                        if(angular.element('.gt-nav').length>0){
                            var top = angular.element('.gt-nav')[0].getBoundingClientRect().top;
                            return $window.innerHeight - top;
                        }
                        return $window.innerHeight - 68;
                    }

                    vm.change = function () {
                        //HERE WE WILL CHECK FOR SPLIT CALL
                    //   if( workspaceFactory.activeScreen.split_call_param ){
                    //       $scope.filterss.selected = splitProcessArray(vm.menuObj);
                    //   }else{
                          createdSelectedArray();
                          $scope.filterss.selected = vm.selectedArray;
                    //   }
                        DataFiltersFactory.setGlobalMeVar(true);
                        DataFiltersFactory.onChange($scope.filterss);
                    }

                    function splitProcessArray(_collection){
                        var selectedArray = [];
                        var mergedArray = [];


                      //LOOP OVER MENU
                        for (var i = 0; i < _collection.length; i++) {
                            if(_collection[i].selected){
                                mergedArray.push( _collection[i].key);
                            }
                            if(_collection[i].children && _collection[i].children.length){
                                var items = buildSelection( _collection[i].children)
                                if(items.length ){
                                    $.merge(mergedArray, items);
                                }
                            }
                        }

                       function buildSelection(_collection){
                            var selected = [];
                           //console.log("buildSelection: " , _collection);
                           for (var i = 0; i < _collection.length; i++) {
                               if(_collection[i].children &&  _collection[i].children.length){
                                   var children = getSelectedChildren(_collection[i].children);
                                   if(children.length){
                                       if (_collection[i].selected) {children.unshift(_collection[i].key);}
                                       selected.push(children.toString());
                                   }
                               }else{
                                   if (_collection[i].selected) {
                                       selected.push(_collection[i].key);
                                   }
                               }
                           }

                           function getSelectedChildren(_collection){
                               var selectedG = [];
                               for (var i = 0; i < _collection.length; i++) {
                                    if(_collection[i].children &&  _collection[i].children.length){
                                        var subSelect = getSelectedChildren(_collection[i].children);
                                        if (_collection[i].selected) {subSelect.unshift(_collection[i].key);}
                                        if(subSelect.length ){
                                            selectedG.push(subSelect);
                                        }
                                    }else{
                                        if(_collection[i].selected ){
                                            selectedG.push(_collection[i].key);
                                        }
                                    }
                               }
                                   return selectedG;
                           }

                           return selected;
                       }

                        return mergedArray;
                    }




                    function createdSelectedArray(){
                        vm.selectedArray  = [];
                        for (var i = 0; i < vm.selected.length; i++) {
                            var newObj = {}
                            //newObj[$scope.filterss.value_name] = vm.selected[i];
                            newObj.VALUE = vm.selected[i];
                            newObj.parnet = vm.selected[i];
                            newObj.level = vm.selected[i];
                            //console.log("vm.selected[i] :" ,  vm.selected[i] );
                            //console.log("$scope.filter.value_name] : " ,  $scope.filter.value_name );
                            vm.selectedArray.push(newObj);
                        }
                    }



                    var setTreeAttribute = function(_collection,_predicate,_children,value){
                        for (var i = 0; i < _collection.length; i++) {
                            var  n = _collection[i][_predicate];
                            var children = _collection[i][_children];
                            if(children && children.length ){
                                setTreeAttribute(children,_predicate,_children,value );
                            }
                            _collection[i][_predicate] = value;
                        }
                    }


                    vm.selectParentMe = function(item){
                        setTreeAttribute(vm.menuObj1,'active','children',false);
                        item.active = true;
                        vm.childMenuObj = item.children;
                        vm.showRightMenu = true;
                    };

                    vm.toggleMenu = function(){
                        vm.showMenu = !vm.showMenu;
                        if(!vm.showMenu ){
                            vm.showRightMenu = false;
                        }
                    }

                    vm.selectAll = function(){
                        setTreeAttribute(vm.menuObj1,'selected','children',true);
                        getSelectedAll();
                        vm.change();

                    }

                    function getSelectedAll(){
                        vm.selected  = [];
                        var selected = 0;

                        _.each(vm.menuObj1, function(item){
                            if(item.selected ){
                                selected++;
                                vm.selected.push(item.key);
                            }
                            if(item.children && item.children.length > 0) {
                                item.numSelected = recursiveSelected(item);
                            }
                        })

                        function recursiveSelected(collection){
                            var selected = 0;
                            function recursiveSelectedInner(collection) {
                                _.each(collection.children, function(item){
                                    if(item.selected ){
                                        vm.selected.push(item.key);
                                        selected++;
                                    }
                                    if(item.children && item.children.length > 0) {
                                        recursiveSelectedInner(item);
                                    }
                                });
                            }
                            recursiveSelectedInner(collection)
                            return selected;
                        }
                        return selected;
                    }

                    vm.unselectAll = function(){
                        setTreeAttribute(vm.menuObj1,'selected','children',false);
                        getSelected();
                        vm.change();
                    }


                    function getSelected(){
                        vm.selected  = [];
                        var selected = 0;

                        _.each(vm.menuObj1, function(item){
                            if(item.selected ){
                                selected++;
                                vm.selected.push(item.key);
                            }
                            if(item.children && item.children.length > 0) {
                                item.numSelected = recursiveSelected(item);
                            }
                        })

                        function recursiveSelected(collection){
                            var selected = 0;
                            function recursiveSelectedInner(collection) {
                                _.each(collection.children, function(item){
                                    if(item.selected ){
                                        vm.selected.push(item.key);
                                        selected++;
                                    }
                                    if(item.children && item.children.length > 0) {
                                        recursiveSelectedInner(item);
                                    }
                                });
                            }
                            recursiveSelectedInner(collection)
                            return selected;
                        }
                        return selected;
                    }

                   function checkParent(_item){
                      var allSelected = true;
                       if( _item.parent ){
                           _.each(_item.parent.children, function(item){
                               if(!item.selected ){
                                   allSelected = false;
                               }
                           });
                           _item.parent.selected = allSelected;

                           if( _item.parent.parent ){
                               checkParent(_item.parent);
                           }
                       }
                    }


                    vm.setChildren1= function(collection){
                        var selected = collection.selected;
                        function setEm(_collection){
                            _.each(_collection.children, function(item){
                                item.selected = selected;
                                if(item.children && item.children.length > 0){
                                    setEm(item);
                                }
                            });
                        }

                        setEm(collection);
                        checkParent(collection);
                        getSelected();
                        vm.change();
                    };

                   function getTotalSelected(){
                       vm.totalSelected = getSelected(vm.menuObj1);
                   }

                }],

                link: function($scope, element, attrs) {

                }
            };
        }])
        // this directive checks grid (gizmo grid) level data against global params, more specifically, it validates taxYear, scenario and issue id
        // on the rowData object, and disables the edit modal Save button if found mismatch with global params.
        // USAGE: simply apply the directive on your Save button element, i.e., <button issue-id-valid>Save</button>
        // NOTE: this directive does not work on buttons that use custom click event bindings, the original "onClick" callback must be registered
        // with vanilla angular directive, that is ng-click or data-ng-click
           .directive('issueIdValid', ['GlobalService', 'workspaceFactory', '$compile', function (GlobalService, workspaceFactory, $compile) {
               return {
                   restrict: 'A',
                   priority: 100, // make sure other directives on the button element that register a click event have a lower priority,
                                  // so the post link function can properly unlink the click event listener
                   scope: {
                    issueIdValid: '='
                   },
                   compile: function () {
                       return {
                           post: function (scope, element, attrs) {
                            if(!validate()){
                                    element.addClass('editDisabled');
                                    element.removeAttr('ng-really-click');
                                    element.removeAttr('ng-click');
                                    element.off('click');
                            }         
                            function validate() {
                                const filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
                                if (!filters) {
                                    return false;
                                }
                                const scenario = filters.filter((filter) => {
                                    return filter.param_name.trim().toLowerCase() === 'scenario'
                                });
                                if(scenario[0].selected.CODE  !== 'EXAM')
                                    return true;

                                const tax_year = filters.filter(i => i.param_name.trim().toLowerCase() === 'tax_year');
                                  
                                return scenario[0].selected.LABEL === scope.issueIdValid.SCENARIO;
                            }                     
                           }
                       };
                   }
               };
           }])

           .directive('issueIdSave', ['GlobalService', 'workspaceFactory', 'AlertService','$compile', function (GlobalService, workspaceFactory,AlertService, $compile) {
               return {
                   restrict: 'A',
                   priority: 100,
                   scope: {
                    issueIdSave: '=',
                    ctrlAlias : '=',
                   },
                   compile: function () {
                       return {
                           post: function (scope, element, attrs) {
                               scope.editFlag = false;
                               const ctrlAlias = scope.ctrlAlias ? scope.ctrlAlias : 'ctrl';
                               scope.rowDataObj = scope.issueIdSave;
                               scope.$on('table.edit', (event, data) => {
                                   scope.editFlag = true;
                                   validate(data, scope.editFlag);
                               });

                               function checkExamScenario() {
                                   const filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
                                   if (!filters) {
                                       return false;
                                   }
                                   scope.tax_year = filters.filter(i => i.param_name.trim().toLowerCase() === 'tax_year');
                                   scope.scenario = filters.filter(i => i.param_name.trim().toLowerCase() === 'scenario'); 
                                   const isExamScenario = filters.some((filter) => {
                                       return filter.param_name.trim().toLowerCase() === 'scenario' &&
                                           filter.selected.CODE &&
                                           filter.selected.CODE.trim().toUpperCase() === 'EXAM';
                                   });
                                   return isExamScenario;
                               }

                               function disableSave(reason) {
                                saveButtonEle.attr('disabled', true);
                                scope.editFlag = false;
                                switch (reason) {
                                    case 'taxYear':
                                        saveButtonEle.attr('title', 'Grid tax year mismatch with global filter');
                                        AlertService.add('error', 'Grid tax year mismatch with global filter');
                                        break;
                                    case 'scenario':
                                        saveButtonEle.attr('title', 'Grid scenario mismatch with global filter');
                                        AlertService.add('error', 'Grid scenario mismatch with global filter');
                                        break;
                                    case 'issueID':
                                        saveButtonEle.attr('title', 'Can\'t save because of missing global Issue id');
                                        AlertService.add('error', 'Can\'t save because of missing global Issue id');
                                        break;
                                }
                               }

                               function findBindingScope(scope) {
                                   if (scope === null) {
                                       return null;
                                   }

                                   if (scope[ctrlAlias]) {
                                       return scope;
                                   } else {
                                       return findBindingScope(scope.$parent);
                                   }
                               }

                               function enhanceSaveBtnAndCompile(issueIdValid, rowIssueId) {
                                   if (!saveButtonEle) {
                                       return;
                                   }
                                   if (issueIdValid) {
                                       const ngReallyClickBinding = saveButtonEle.attr('ng-really-click');
                                       if (!ngReallyClickBinding) {
                                           return;
                                       }
                                       saveButtonEle.attr('ng-click', ngReallyClickBinding);
                                       saveButtonEle.removeAttr('ng-really-header');
                                       saveButtonEle.removeAttr('ng-really-message');
                                       saveButtonEle.removeAttr('ng-really-click');
                                   } else {
                                       const ngClickBinding = saveButtonEle.attr('ng-click') || saveButtonEle.attr('data-ng-click');
                                       if (!ngClickBinding) {
                                           return;
                                       }
                                       saveButtonEle.attr('global-issue', GlobalService.globalParams.issue_id);
                                       saveButtonEle.attr('row-issue-id', rowIssueId);
                                       saveButtonEle.attr('is-issue-change-action', true);
                                       saveButtonEle.attr('ng-really-header', 'Warning');
                                       saveButtonEle.attr('ng-really-message', 'You are about to overwrite the existing transaction Issue id (' + rowIssueId + ')'
                                           + ' with the Issue id "' + GlobalService.globalParams.issue_short_desc + '" that you are currently working on. Are you sure you want to proceed?');
                                       saveButtonEle.attr('ng-really-click', ngClickBinding);
                                       saveButtonEle.removeAttr('ng-click');
                                       saveButtonEle.removeAttr('data-ng-click');
                                   }
                                   scope.editFlag = false;
                                   saveButtonEle.removeAttr('issue-id-save'); // remove this directive to avoid infinite compile loop
                                   saveButtonEle.off('click'); // also we need to remove existing click event handler
                                   const bindingScope = findBindingScope(scope);
                                   $compile(saveButtonEle)(bindingScope);
                               }
                               const saveButtonEle = element; 

                               if(checkExamScenario() && scope.editFlag === false ){
                                   validate(scope.rowDataObj, scope.editFlag );
                               }

                               function validate(rowDataObj, showMsg) {
                                   if (!rowDataObj) {
                                       return;
                                   }
                                   if (!checkExamScenario()) {
                                       return;
                                    }
                                    const _globalParams = GlobalService.globalParams;                    
                                    
                                    if (!_globalParams.issue_id ||(_globalParams.issue_id && _globalParams.issue_id.length === 0)) {
                                     disableSave('issueID');
                                     return ;
                                    }
                                   if (GlobalService.selected_scenario_desc === rowDataObj.SCENARIO 
                                         && _globalParams.tax_year === scope.tax_year[0].selected.label) {
                                       // if (rowDataObj.SCENARIO_TYPE_CODE === 'EXAM') {
                                       // check issue id against global params
                                       if (!_globalParams.issue_id) {
                                           disableSave('issueID');
                                       } else if (rowDataObj.ISSUE_ID && (rowDataObj.ISSUE_ID.toString() !== _globalParams.issue_id && showMsg)) {
                                           // add warning message on click of save button
                                           enhanceSaveBtnAndCompile(false, rowDataObj.ISSUE_ID.toString());
                                           return;
                                       }
                                       // }
                                   } else if(_globalParams.tax_year !== scope.tax_year[0].selected.label){
                                       disableSave('taxYear');
                                       return;
                                        }
                                       else if(GlobalService.selected_scenario_desc !== scope.scenario[0].selected.LABEL){
                                           disableSave('scenario');
                                           return;
                                       }
                                //    }
                                   enhanceSaveBtnAndCompile(true);
                               }
                           }
                       }
                   }
               }
           }])
           
        .directive('globalIssueId', ['GlobalService','workspaceFactory', function (GlobalService, workspaceFactory) {
            return {
                restrict: 'A',
                template: '<p ng-if = "isExamScenario"> <strong>Current Issue ID </strong> :{{issue_short_desc}}  </p>',
                link: function($scope, element, attrs) {
                    $scope.issue_id = GlobalService.globalParams.issue_id;
                    $scope.issue_short_desc = GlobalService.globalParams.issue_short_desc;
                    $scope.isExamScenario = GlobalService.inputs.tax_year.selected.scenario_type_code === 'EXAM' && checkExamScenario();
                    function checkExamScenario() {
                        const filters = workspaceFactory.activeScreen.filters && workspaceFactory.activeScreen.filters.getFilters();
                        if (!filters) {
                            return false;
                        }
                        const isExamScenario = filters.some((filter) => {
                            return filter.param_name.trim().toLowerCase() === 'scenario' &&
                                filter.selected.CODE &&
                                filter.selected.CODE.trim().toUpperCase() === 'EXAM';
                        });
                        return isExamScenario;
                    }
                }
            };
        }])

        .directive('issueIdDropdown', ['GlobalService', function (GlobalService) {
            return {
                restrict: 'A',
                scope: {
                    taxYear : '=',
                    scenario : '=',
                    selectedIssue : '='
                },
                template: '<label>Issue Id</label>'+
                '<select class="form-control"'+
                'ng-model="selectedIssue"'+
                'ng-options="i.issue_id + \' - \'+i.issue_short_desc for i in issues"></select>',
                link: function(scope, element, attrs) {
                    GlobalService.getIssue(scope.taxYear, scope.scenario ? scope.scenario : '').then(function(data) {
                        if(data.data.callSuccess == 1) {
                            scope.issues = data.data.issue;
                            scope.selectedIssue = scope.issues[0];
                        } else {
                            AlertService.add('error', 'Failed to load issue ids');
                        }
                    });
                }
            };
        }])
        
        .directive('twoDecimalPlaces', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {

                    element.bind('keypress',function(e){
                        var newVal=$(this).val()+(e.charCode!==0?String.fromCharCode(e.charCode):'');
                        if($(this).val().search(/(.*)\.[0-9][0-9]/)===0 && newVal.length>$(this).val().length){
                            e.preventDefault();
                        }
                    });
                }
            };
        })

        //format and allow fractions only upto decimalDigits
        .directive('formatDecNum', ['$filter', function ($filter) {
            return {
                require: '?ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    if (!ctrl) return;

                    ctrl.$formatters.unshift(function (a) {
                        return $filter(attrs.formatDecNum)(ctrl.$modelValue, attrs.decimalDigits)
                    });

                    ctrl.$parsers.unshift(function (viewValue) {
                        // var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');
                        var plainNumber = viewValue.replace(/[^0-9.-]/g, '');
                        if (plainNumber == "-") {
                            return viewValue;
                        }
                        elem.val($filter(attrs.formatDecNum)(plainNumber));
                        return plainNumber;
                    });
                }
            };
        }])

        .directive('sixDecimalPlaces', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {

                    element.bind('keypress',function(e){
                        var newVal=$(this).val()+(e.charCode!==0?String.fromCharCode(e.charCode):'');
                        if($(this).val().search(/(.*)\.[0-9][0-9][0-9][0-9][0-9][0-9]/)===0 && newVal.length>$(this).val().length){
                            e.preventDefault();
                        }
                    });
                }
            };
        })

        
        .directive('bindHtmlCompile', ['$compile', function ($compile) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    scope.$watch(function () {
                        return scope.$eval(attrs.bindHtmlCompile);
                    }, function (value) {
                        element.html(value);
                        $compile(element.contents())(scope);
                    });
                }
            };
        }])

})
