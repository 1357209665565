/**
 * Created by 502117899 on 8/7/14.
 */

define([
    'js.cookie',
    'angular'
], function (Cookies) {
    'use strict';

    return angular.module( 'app.services',[])


        .factory('GlobalService', ['$rootScope','SERVER_CONFIG','TAX_YEARS','USER_SETTINGS','$http', 'GENERAL_CONFIG', 'AlertService','$location',  function($rootScope,SERVER_CONFIG,TAX_YEARS,USER_SETTINGS,$http,GENERAL_CONFIG, AlertService,$location){

            var global = {}
            global.globalParams  = {};
            global.globalParams.tax_year = "";
            global.globalParams.screen_key = "";
            global.globalParams.scenario = 1;
            global.globalParams.jcd_key = "";
            global.globalParams.jcd_ta_key = "";
            global.globalParams.activity_key = "";
            global.globalParams.screen_type = "";
            global.globalParams.is_locked = "";
            global.global_selected_me = [];

            global.globalParams.issue_id = "";
            global.globalParams.issue_short_desc = "";
            global.globalParams.issue_id_for_tx_save = ""; // this property will be used to store the issue id when working with tx update in the grids in ngReallyClick directive

            global.selected_scenario_desc = "";

            global.global_issues = [];
            global.isDrillDown = false;


            /* call by reference  taxplacemat databuoy not only databuoy we can use it in anywhere int the app to pass the values from controller to controller  8/20/2019*/
            global.sessionData = {};
            global.setUserSession = function(paramName,paramValue){
                global.sessionData[paramName] = paramValue;
            }
            global.getUserSession = function(paramName){
                return global.sessionData[paramName];
            }
            /* ends here */
            global.inputs = {}

            //Iterated on TAX_YEAR as Angular expects Array of Array.
            var TEMP_TAX_YEAR = [];
            _.forEach(TAX_YEARS, function(value) {
                TEMP_TAX_YEAR.push(value);
            });

            global.inputs.tax_year = {
                "name":"tax_year",
                "label":"Tax Year",
                "selected":null,
                "values":TEMP_TAX_YEAR
            }

            if(SERVER_CONFIG.clientConfig.defaultTaxYear ){
                var defaultTaxYear = _.find(global.inputs.tax_year.values, { 'value': SERVER_CONFIG.clientConfig.defaultTaxYear});
                // var defaultTaxYear = global.inputs.tax_year.values[0];
                if(defaultTaxYear === undefined || defaultTaxYear == null){
                    window.location.href =  'app/templates/denied.html';
                } else {

                    global.inputs.tax_year.selected = defaultTaxYear;
                }
            }

            global.setFilters = function(_workspace){
                global.globalParams.jcd_key = _workspace.jcd_key;
                global.globalParams.jcd_ta_key = _workspace.jcd_ta_key;
                global.globalParams.activity_key = _workspace.activity_key;
                global.globalParams.screen_type = _workspace.screen_type;
                global.globalParams.screen_key = _workspace.screen_key;
                $rootScope.$broadcast("GlobalService:setFilters",{});
            }

            global.modalLockFilters = function(lockedFilters){
                global.globalParams.checkLockedbj = {};
                if(!_.isEmpty(lockedFilters)){
                    global.globalParams.checkLockedbj = JSON.stringify(lockedFilters);
                }else{
                    delete global.globalParams['checkLockedbj'];
                }
                
            }

            global.setGlobalSelectedMes = function(selectedMes) {
                global.global_selected_me = selectedMes;
                console.log(selectedMes, "setGlobalSelectedMes");
            }

            $rootScope.$watch(function() {
                return global.inputs
            }, function watchCallback(newValue, oldValue) {
                global.globalParams.tax_year = global.inputs.tax_year.selected.value;
                global.globalParams.scenario = global.inputs.tax_year.selected.scenario;
                global.globalParams.jcd_key = _.find(global.inputs.tax_year.selected.scenarios, {scenario: global.globalParams.scenario}).jcd_key;
                global.globalParams.is_locked = _.find(global.inputs.tax_year.selected.scenarios,{scenario:global.inputs.tax_year.selected.scenario}).is_locked;
                $rootScope.$broadcast('GlobalParams is updated');
            },true);



            global.getScenario = function(tax_year){
                var defaultObj = _.find(global.inputs.tax_year.values, { 'value': tax_year});
                if(defaultObj && defaultObj.scenario){
                    return defaultObj.scenario;
                }
                
            }

            global.isIssueIDEnabled = function(tax_year,scenario){
                let obj = {};
                let foundScenario = '';
                _.find(global.inputs.tax_year.values,function(i){
                     if(i.value == tax_year){
                          foundScenario = _.find(i.scenarios,{scenario:scenario+''});
                      }
                  })
                  if(foundScenario && foundScenario.scenario_type_code){
                    obj.scenarioCode = foundScenario.scenario_type_code;
                    obj.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
                  }
                return obj;
            }

            global.getIssue = function(tax_year, scenario){
                    var promise = $http({
                        method: "GET",
                        url: GENERAL_CONFIG.base_url + '/getIssues?tax_year=' + tax_year + '&scenario=' + scenario                       
                    }).then(function (response) {
                        return response;
                    });
    
                    return promise;
            }


            global.getUserMenu = function(tax_year,scenario,scenario_type_code){
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.base_url + '/getUserNavigator?tax_year='+tax_year+'&scenario='+scenario+'&scenario_type_code='+scenario_type_code                       
                }).then(function (response) {
                    return response;
                });
                return promise;
            }

            global.generateUUID = function(){
                var d = new Date().getTime();
                if(window.performance && typeof window.performance.now === "function"){
                    d += performance.now(); //use high-precision timer if available
                }
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    var r = (d + Math.random()*16)%16 | 0;
                    d = Math.floor(d/16);
                    return (c=='x' ? r : (r&0x3|0x8)).toString(16);
                });
                return uuid;
            }


            // gets the list of tabs and stores the list length based ont he window resize(can be used from anywhere, since moved to service)
            // global.splitTabs = function(tabs, excessWidth, selectedTabIndex) {
            //     var width = 200;
            //     var i=0;
            //     if (!selectedTabIndex || selectedTabIndex ==-1) {
            //         console.log('reset selectedTab', selectedTabIndex);
            //         selectedTabIndex = 0;
            //     }
            //     var res = {
            //     };
            //     if (window.innerWidth < 768) { // Till mobile devices width
            //         res.quantity = 0;
            //     } else if (angular.isArray(tabs)) {
            //         for(var i = 0; i < tabs.length; i++) {
            //             width += (tabs[i].label.length * 5.4) + (excessWidth || 20); // each character assuming 5px width and padding for tab 10+10
            //             if (width > window.innerWidth) {
            //                 res.quantity = i-1;
            //                 break;
            //             }
            //         }
            //     }
            //     if (res.quantity && selectedTabIndex > res.quantity-1) {
            //         [tabs[res.quantity-2], tabs[selectedTabIndex]] = [tabs[selectedTabIndex], tabs[res.quantity-2]];
            //         return global.splitTabs(tabs, excessWidth, res.quantity-2);
            //     }
            //     if(tabs) {
            //         res.dropdownTabs = angular.isDefined(res.quantity) ? tabs.slice(res.quantity, tabs.length) : [];
            //     }
            //     return res;
            // }



           var states = [
                {
                    "name": "Alabama",
                    "code": "AL"
                },
                {
                    "name": "Alaska",
                    "code": "AK"
                },
                {
                    "name": "American Samoa",
                    "code": "AS"
                },
                {
                    "name": "Arizona",
                    "code": "AZ"
                },
                {
                    "name": "Arkansas",
                    "code": "AR"
                },
                {
                    "name": "California",
                    "code": "CA"
                },
                {
                    "name": "Colorado",
                    "code": "CO"
                },
                {
                    "name": "Connecticut",
                    "code": "CT"
                },
                {
                    "name": "Delaware",
                    "code": "DE"
                },
                {
                    "name": "District Of Columbia",
                    "code": "DC"
                },
                {
                    "name": "Federated States Of Micronesia",
                    "code": "FM"
                },
                {
                    "name": "Florida",
                    "code": "FL"
                },
                {
                    "name": "Georgia",
                    "code": "GA"
                },
                {
                    "name": "Guam",
                    "code": "GU"
                },
                {
                    "name": "Hawaii",
                    "code": "HI"
                },
                {
                    "name": "Idaho",
                    "code": "ID"
                },
                {
                    "name": "Illinois",
                    "code": "IL"
                },
                {
                    "name": "Indiana",
                    "code": "IN"
                },
                {
                    "name": "Iowa",
                    "code": "IA"
                },
                {
                    "name": "Kansas",
                    "code": "KS"
                },
                {
                    "name": "Kentucky",
                    "code": "KY"
                },
                {
                    "name": "Louisiana",
                    "code": "LA"
                },
                {
                    "name": "Maine",
                    "code": "ME"
                },
                {
                    "name": "Marshall Islands",
                    "code": "MH"
                },
                {
                    "name": "Maryland",
                    "code": "MD"
                },
                {
                    "name": "Massachusetts",
                    "code": "MA"
                },
                {
                    "name": "Michigan",
                    "code": "MI"
                },
                {
                    "name": "Minnesota",
                    "code": "MN"
                },
                {
                    "name": "Mississippi",
                    "code": "MS"
                },
                {
                    "name": "Missouri",
                    "code": "MO"
                },
                {
                    "name": "Montana",
                    "code": "MT"
                },
                {
                    "name": "Nebraska",
                    "code": "NE"
                },
                {
                    "name": "Nevada",
                    "code": "NV"
                },
                {
                    "name": "New Hampshire",
                    "code": "NH"
                },
                {
                    "name": "New Jersey",
                    "code": "NJ"
                },
                {
                    "name": "New Mexico",
                    "code": "NM"
                },
                {
                    "name": "New York",
                    "code": "NY"
                },
                {
                    "name": "North Carolina",
                    "code": "NC"
                },
                {
                    "name": "North Dakota",
                    "code": "ND"
                },
                {
                    "name": "Northern Mariana Islands",
                    "code": "MP"
                },
                {
                    "name": "Ohio",
                    "code": "OH"
                },
                {
                    "name": "Oklahoma",
                    "code": "OK"
                },
                {
                    "name": "Oregon",
                    "code": "OR"
                },
                {
                    "name": "Palau",
                    "code": "PW"
                },
                {
                    "name": "Pennsylvania",
                    "code": "PA"
                },
                {
                    "name": "Puerto Rico",
                    "code": "PR"
                },
                {
                    "name": "Rhode Island",
                    "code": "RI"
                },
                {
                    "name": "South Carolina",
                    "code": "SC"
                },
                {
                    "name": "South Dakota",
                    "code": "SD"
                },
                {
                    "name": "Tennessee",
                    "code": "TN"
                },
                {
                    "name": "Texas",
                    "code": "TX"
                },
                {
                    "name": "Utah",
                    "code": "UT"
                },
                {
                    "name": "Vermont",
                    "code": "VT"
                },
                {
                    "name": "Virgin Islands",
                    "code": "VI"
                },
                {
                    "name": "Virginia",
                    "code": "VA"
                },
                {
                    "name": "Washington",
                    "code": "WA"
                },
                {
                    "name": "West Virginia",
                    "code": "WV"
                },
                {
                    "name": "Wisconsin",
                    "code": "WI"
                },
                {
                    "name": "Wyoming",
                    "code": "WY"
                }
            ]


            var countries = [
                {name: 'United States', code: 'US'},
                {name: 'Afghanistan', code: 'AF'},
                {name: 'Åland Islands', code: 'AX'},
                {name: 'Albania', code: 'AL'},
                {name: 'Algeria', code: 'DZ'},
                {name: 'American Samoa', code: 'AS'},
                {name: 'Andorra', code: 'AD'},
                {name: 'Angola', code: 'AO'},
                {name: 'Anguilla', code: 'AI'},
                {name: 'Antarctica', code: 'AQ'},
                {name: 'Antigua and Barbuda', code: 'AG'},
                {name: 'Argentina', code: 'AR'},
                {name: 'Armenia', code: 'AM'},
                {name: 'Aruba', code: 'AW'},
                {name: 'Australia', code: 'AU'},
                {name: 'Austria', code: 'AT'},
                {name: 'Azerbaijan', code: 'AZ'},
                {name: 'Bahamas', code: 'BS'},
                {name: 'Bahrain', code: 'BH'},
                {name: 'Bangladesh', code: 'BD'},
                {name: 'Barbados', code: 'BB'},
                {name: 'Belarus', code: 'BY'},
                {name: 'Belgium', code: 'BE'},
                {name: 'Belize', code: 'BZ'},
                {name: 'Benin', code: 'BJ'},
                {name: 'Bermuda', code: 'BM'},
                {name: 'Bhutan', code: 'BT'},
                {name: 'Bolivia', code: 'BO'},
                {name: 'Bosnia and Herzegovina', code: 'BA'},
                {name: 'Botswana', code: 'BW'},
                {name: 'Bouvet Island', code: 'BV'},
                {name: 'Brazil', code: 'BR'},
                {name: 'British Indian Ocean Territory', code: 'IO'},
                {name: 'Brunei Darussalam', code: 'BN'},
                {name: 'Bulgaria', code: 'BG'},
                {name: 'Burkina Faso', code: 'BF'},
                {name: 'Burundi', code: 'BI'},
                {name: 'Cambodia', code: 'KH'},
                {name: 'Cameroon', code: 'CM'},
                {name: 'Canada', code: 'CA'},
                {name: 'Cape Verde', code: 'CV'},
                {name: 'Cayman Islands', code: 'KY'},
                {name: 'Central African Republic', code: 'CF'},
                {name: 'Chad', code: 'TD'},
                {name: 'Chile', code: 'CL'},
                {name: 'China', code: 'CN'},
                {name: 'Christmas Island', code: 'CX'},
                {name: 'Cocos (Keeling) Islands', code: 'CC'},
                {name: 'Colombia', code: 'CO'},
                {name: 'Comoros', code: 'KM'},
                {name: 'Congo', code: 'CG'},
                {name: 'Congo, The Democratic Republic of the', code: 'CD'},
                {name: 'Cook Islands', code: 'CK'},
                {name: 'Costa Rica', code: 'CR'},
                {name: 'Cote D\'Ivoire', code: 'CI'},
                {name: 'Croatia', code: 'HR'},
                {name: 'Cuba', code: 'CU'},
                {name: 'Cyprus', code: 'CY'},
                {name: 'Czech Republic', code: 'CZ'},
                {name: 'Denmark', code: 'DK'},
                {name: 'Djibouti', code: 'DJ'},
                {name: 'Dominica', code: 'DM'},
                {name: 'Dominican Republic', code: 'DO'},
                {name: 'Ecuador', code: 'EC'},
                {name: 'Egypt', code: 'EG'},
                {name: 'El Salvador', code: 'SV'},
                {name: 'Equatorial Guinea', code: 'GQ'},
                {name: 'Eritrea', code: 'ER'},
                {name: 'Estonia', code: 'EE'},
                {name: 'Ethiopia', code: 'ET'},
                {name: 'Falkland Islands (Malvinas)', code: 'FK'},
                {name: 'Faroe Islands', code: 'FO'},
                {name: 'Fiji', code: 'FJ'},
                {name: 'Finland', code: 'FI'},
                {name: 'France', code: 'FR'},
                {name: 'French Guiana', code: 'GF'},
                {name: 'French Polynesia', code: 'PF'},
                {name: 'French Southern Territories', code: 'TF'},
                {name: 'Gabon', code: 'GA'},
                {name: 'Gambia', code: 'GM'},
                {name: 'Georgia', code: 'GE'},
                {name: 'Germany', code: 'DE'},
                {name: 'Ghana', code: 'GH'},
                {name: 'Gibraltar', code: 'GI'},
                {name: 'Greece', code: 'GR'},
                {name: 'Greenland', code: 'GL'},
                {name: 'Grenada', code: 'GD'},
                {name: 'Guadeloupe', code: 'GP'},
                {name: 'Guam', code: 'GU'},
                {name: 'Guatemala', code: 'GT'},
                {name: 'Guernsey', code: 'GG'},
                {name: 'Guinea', code: 'GN'},
                {name: 'Guinea-Bissau', code: 'GW'},
                {name: 'Guyana', code: 'GY'},
                {name: 'Haiti', code: 'HT'},
                {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
                {name: 'Holy See (Vatican City State)', code: 'VA'},
                {name: 'Honduras', code: 'HN'},
                {name: 'Hong Kong', code: 'HK'},
                {name: 'Hungary', code: 'HU'},
                {name: 'Iceland', code: 'IS'},
                {name: 'India', code: 'IN'},
                {name: 'Indonesia', code: 'ID'},
                {name: 'Iran, Islamic Republic Of', code: 'IR'},
                {name: 'Iraq', code: 'IQ'},
                {name: 'Ireland', code: 'IE'},
                {name: 'Isle of Man', code: 'IM'},
                {name: 'Israel', code: 'IL'},
                {name: 'Italy', code: 'IT'},
                {name: 'Jamaica', code: 'JM'},
                {name: 'Japan', code: 'JP'},
                {name: 'Jersey', code: 'JE'},
                {name: 'Jordan', code: 'JO'},
                {name: 'Kazakhstan', code: 'KZ'},
                {name: 'Kenya', code: 'KE'},
                {name: 'Kiribati', code: 'KI'},
                {name: 'Korea, Democratic People\'s Republic of', code: 'KP'},
                {name: 'Korea, Republic of', code: 'KR'},
                {name: 'Kuwait', code: 'KW'},
                {name: 'Kyrgyzstan', code: 'KG'},
                {name: 'Lao People\'s Democratic Republic', code: 'LA'},
                {name: 'Latvia', code: 'LV'},
                {name: 'Lebanon', code: 'LB'},
                {name: 'Lesotho', code: 'LS'},
                {name: 'Liberia', code: 'LR'},
                {name: 'Libyan Arab Jamahiriya', code: 'LY'},
                {name: 'Liechtenstein', code: 'LI'},
                {name: 'Lithuania', code: 'LT'},
                {name: 'Luxembourg', code: 'LU'},
                {name: 'Macao', code: 'MO'},
                {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
                {name: 'Madagascar', code: 'MG'},
                {name: 'Malawi', code: 'MW'},
                {name: 'Malaysia', code: 'MY'},
                {name: 'Maldives', code: 'MV'},
                {name: 'Mali', code: 'ML'},
                {name: 'Malta', code: 'MT'},
                {name: 'Marshall Islands', code: 'MH'},
                {name: 'Martinique', code: 'MQ'},
                {name: 'Mauritania', code: 'MR'},
                {name: 'Mauritius', code: 'MU'},
                {name: 'Mayotte', code: 'YT'},
                {name: 'Mexico', code: 'MX'},
                {name: 'Micronesia, Federated States of', code: 'FM'},
                {name: 'Moldova, Republic of', code: 'MD'},
                {name: 'Monaco', code: 'MC'},
                {name: 'Mongolia', code: 'MN'},
                {name: 'Montserrat', code: 'MS'},
                {name: 'Morocco', code: 'MA'},
                {name: 'Mozambique', code: 'MZ'},
                {name: 'Myanmar', code: 'MM'},
                {name: 'Namibia', code: 'NA'},
                {name: 'Nauru', code: 'NR'},
                {name: 'Nepal', code: 'NP'},
                {name: 'Netherlands', code: 'NL'},
                {name: 'Netherlands Antilles', code: 'AN'},
                {name: 'New Caledonia', code: 'NC'},
                {name: 'New Zealand', code: 'NZ'},
                {name: 'Nicaragua', code: 'NI'},
                {name: 'Niger', code: 'NE'},
                {name: 'Nigeria', code: 'NG'},
                {name: 'Niue', code: 'NU'},
                {name: 'Norfolk Island', code: 'NF'},
                {name: 'Northern Mariana Islands', code: 'MP'},
                {name: 'Norway', code: 'NO'},
                {name: 'Oman', code: 'OM'},
                {name: 'Pakistan', code: 'PK'},
                {name: 'Palau', code: 'PW'},
                {name: 'Palestinian Territory, Occupied', code: 'PS'},
                {name: 'Panama', code: 'PA'},
                {name: 'Papua New Guinea', code: 'PG'},
                {name: 'Paraguay', code: 'PY'},
                {name: 'Peru', code: 'PE'},
                {name: 'Philippines', code: 'PH'},
                {name: 'Pitcairn', code: 'PN'},
                {name: 'Poland', code: 'PL'},
                {name: 'Portugal', code: 'PT'},
                {name: 'Puerto Rico', code: 'PR'},
                {name: 'Qatar', code: 'QA'},
                {name: 'Reunion', code: 'RE'},
                {name: 'Romania', code: 'RO'},
                {name: 'Russian Federation', code: 'RU'},
                {name: 'Rwanda', code: 'RW'},
                {name: 'Saint Helena', code: 'SH'},
                {name: 'Saint Kitts and Nevis', code: 'KN'},
                {name: 'Saint Lucia', code: 'LC'},
                {name: 'Saint Pierre and Miquelon', code: 'PM'},
                {name: 'Saint Vincent and the Grenadines', code: 'VC'},
                {name: 'Samoa', code: 'WS'},
                {name: 'San Marino', code: 'SM'},
                {name: 'Sao Tome and Principe', code: 'ST'},
                {name: 'Saudi Arabia', code: 'SA'},
                {name: 'Senegal', code: 'SN'},
                {name: 'Serbia and Montenegro', code: 'CS'},
                {name: 'Seychelles', code: 'SC'},
                {name: 'Sierra Leone', code: 'SL'},
                {name: 'Singapore', code: 'SG'},
                {name: 'Slovakia', code: 'SK'},
                {name: 'Slovenia', code: 'SI'},
                {name: 'Solomon Islands', code: 'SB'},
                {name: 'Somalia', code: 'SO'},
                {name: 'South Africa', code: 'ZA'},
                {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
                {name: 'Spain', code: 'ES'},
                {name: 'Sri Lanka', code: 'LK'},
                {name: 'Sudan', code: 'SD'},
                {name: 'Suriname', code: 'SR'},
                {name: 'Svalbard and Jan Mayen', code: 'SJ'},
                {name: 'Swaziland', code: 'SZ'},
                {name: 'Sweden', code: 'SE'},
                {name: 'Switzerland', code: 'CH'},
                {name: 'Syrian Arab Republic', code: 'SY'},
                {name: 'Taiwan, Province of China', code: 'TW'},
                {name: 'Tajikistan', code: 'TJ'},
                {name: 'Tanzania, United Republic of', code: 'TZ'},
                {name: 'Thailand', code: 'TH'},
                {name: 'Timor-Leste', code: 'TL'},
                {name: 'Togo', code: 'TG'},
                {name: 'Tokelau', code: 'TK'},
                {name: 'Tonga', code: 'TO'},
                {name: 'Trinidad and Tobago', code: 'TT'},
                {name: 'Tunisia', code: 'TN'},
                {name: 'Turkey', code: 'TR'},
                {name: 'Turkmenistan', code: 'TM'},
                {name: 'Turks and Caicos Islands', code: 'TC'},
                {name: 'Tuvalu', code: 'TV'},
                {name: 'Uganda', code: 'UG'},
                {name: 'Ukraine', code: 'UA'},
                {name: 'United Arab Emirates', code: 'AE'},
                {name: 'United Kingdom', code: 'GB'},
                {name: 'United States Minor Outlying Islands', code: 'UM'},
                {name: 'Uruguay', code: 'UY'},
                {name: 'Uzbekistan', code: 'UZ'},
                {name: 'Vanuatu', code: 'VU'},
                {name: 'Venezuela', code: 'VE'},
                {name: 'Vietnam', code: 'VN'},
                {name: 'Virgin Islands, British', code: 'VG'},
                {name: 'Virgin Islands, U.S.', code: 'VI'},
                {name: 'Wallis and Futuna', code: 'WF'},
                {name: 'Western Sahara', code: 'EH'},
                {name: 'Yemen', code: 'YE'},
                {name: 'Zambia', code: 'ZM'},
                {name: 'Zimbabwe', code: 'ZW'}
            ];

            var currencies = [
                {"cc":"AED","symbol":"\u062f.\u0625;","name":"UAE dirham"},
                {"cc":"AFN","symbol":"Afs","name":"Afghan afghani"},
                {"cc":"ALL","symbol":"L","name":"Albanian lek"},
                {"cc":"AMD","symbol":"AMD","name":"Armenian dram"},
                {"cc":"ANG","symbol":"NA\u0192","name":"Netherlands Antillean gulden"},
                {"cc":"AOA","symbol":"Kz","name":"Angolan kwanza"},
                {"cc":"ARS","symbol":"$","name":"Argentine peso"},
                {"cc":"AUD","symbol":"$","name":"Australian dollar"},
                {"cc":"AWG","symbol":"\u0192","name":"Aruban florin"},
                {"cc":"AZN","symbol":"AZN","name":"Azerbaijani manat"},
                {"cc":"BAM","symbol":"KM","name":"Bosnia and Herzegovina konvertibilna marka"},
                {"cc":"BBD","symbol":"Bds$","name":"Barbadian dollar"},
                {"cc":"BDT","symbol":"\u09f3","name":"Bangladeshi taka"},
                {"cc":"BGN","symbol":"BGN","name":"Bulgarian lev"},
                {"cc":"BHD","symbol":".\u062f.\u0628","name":"Bahraini dinar"},
                {"cc":"BIF","symbol":"FBu","name":"Burundi franc"},
                {"cc":"BMD","symbol":"BD$","name":"Bermudian dollar"},
                {"cc":"BND","symbol":"B$","name":"Brunei dollar"},
                {"cc":"BOB","symbol":"Bs.","name":"Bolivian boliviano"},
                {"cc":"BRL","symbol":"R$","name":"Brazilian real"},
                {"cc":"BSD","symbol":"B$","name":"Bahamian dollar"},
                {"cc":"BTN","symbol":"Nu.","name":"Bhutanese ngultrum"},
                {"cc":"BWP","symbol":"P","name":"Botswana pula"},
                {"cc":"BYR","symbol":"Br","name":"Belarusian ruble"},
                {"cc":"BZD","symbol":"BZ$","name":"Belize dollar"},
                {"cc":"CAD","symbol":"$","name":"Canadian dollar"},
                {"cc":"CDF","symbol":"F","name":"Congolese franc"},
                {"cc":"CHF","symbol":"Fr.","name":"Swiss franc"},
                {"cc":"CLP","symbol":"$","name":"Chilean peso"},
                {"cc":"CNY","symbol":"\u00a5","name":"Chinese/Yuan renminbi"},
                {"cc":"COP","symbol":"Col$","name":"Colombian peso"},
                {"cc":"CRC","symbol":"\u20a1","name":"Costa Rican colon"},
                {"cc":"CUC","symbol":"$","name":"Cuban peso"},
                {"cc":"CVE","symbol":"Esc","name":"Cape Verdean escudo"},
                {"cc":"CZK","symbol":"K\u010d","name":"Czech koruna"},
                {"cc":"DJF","symbol":"Fdj","name":"Djiboutian franc"},
                {"cc":"DKK","symbol":"Kr","name":"Danish krone"},
                {"cc":"DOP","symbol":"RD$","name":"Dominican peso"},
                {"cc":"DZD","symbol":"\u062f.\u062c","name":"Algerian dinar"},
                {"cc":"EEK","symbol":"KR","name":"Estonian kroon"},
                {"cc":"EGP","symbol":"\u00a3","name":"Egyptian pound"},
                {"cc":"ERN","symbol":"Nfa","name":"Eritrean nakfa"},
                {"cc":"ETB","symbol":"Br","name":"Ethiopian birr"},
                {"cc":"EUR","symbol":"\u20ac","name":"European Euro"},
                {"cc":"FJD","symbol":"FJ$","name":"Fijian dollar"},
                {"cc":"FKP","symbol":"\u00a3","name":"Falkland Islands pound"},
                {"cc":"GBP","symbol":"\u00a3","name":"British pound"},
                {"cc":"GEL","symbol":"GEL","name":"Georgian lari"},
                {"cc":"GHS","symbol":"GH\u20b5","name":"Ghanaian cedi"},
                {"cc":"GIP","symbol":"\u00a3","name":"Gibraltar pound"},
                {"cc":"GMD","symbol":"D","name":"Gambian dalasi"},
                {"cc":"GNF","symbol":"FG","name":"Guinean franc"},
                {"cc":"GQE","symbol":"CFA","name":"Central African CFA franc"},
                {"cc":"GTQ","symbol":"Q","name":"Guatemalan quetzal"},
                {"cc":"GYD","symbol":"GY$","name":"Guyanese dollar"},
                {"cc":"HKD","symbol":"HK$","name":"Hong Kong dollar"},
                {"cc":"HNL","symbol":"L","name":"Honduran lempira"},
                {"cc":"HRK","symbol":"kn","name":"Croatian kuna"},
                {"cc":"HTG","symbol":"G","name":"Haitian gourde"},
                {"cc":"HUF","symbol":"Ft","name":"Hungarian forint"},
                {"cc":"IDR","symbol":"Rp","name":"Indonesian rupiah"},
                {"cc":"ILS","symbol":"\u20aa","name":"Israeli new sheqel"},
                {"cc":"INR","symbol":"\u20B9","name":"Indian rupee"},
                {"cc":"IQD","symbol":"\u062f.\u0639","name":"Iraqi dinar"},
                {"cc":"IRR","symbol":"IRR","name":"Iranian rial"},
                {"cc":"ISK","symbol":"kr","name":"Icelandic kr\u00f3na"},
                {"cc":"JMD","symbol":"J$","name":"Jamaican dollar"},
                {"cc":"JOD","symbol":"JOD","name":"Jordanian dinar"},
                {"cc":"JPY","symbol":"\u00a5","name":"Japanese yen"},
                {"cc":"KES","symbol":"KSh","name":"Kenyan shilling"},
                {"cc":"KGS","symbol":"\u0441\u043e\u043c","name":"Kyrgyzstani som"},
                {"cc":"KHR","symbol":"\u17db","name":"Cambodian riel"},
                {"cc":"KMF","symbol":"KMF","name":"Comorian franc"},
                {"cc":"KPW","symbol":"W","name":"North Korean won"},
                {"cc":"KRW","symbol":"W","name":"South Korean won"},
                {"cc":"KWD","symbol":"KWD","name":"Kuwaiti dinar"},
                {"cc":"KYD","symbol":"KY$","name":"Cayman Islands dollar"},
                {"cc":"KZT","symbol":"T","name":"Kazakhstani tenge"},
                {"cc":"LAK","symbol":"KN","name":"Lao kip"},
                {"cc":"LBP","symbol":"\u00a3","name":"Lebanese lira"},
                {"cc":"LKR","symbol":"Rs","name":"Sri Lankan rupee"},
                {"cc":"LRD","symbol":"L$","name":"Liberian dollar"},
                {"cc":"LSL","symbol":"M","name":"Lesotho loti"},
                {"cc":"LTL","symbol":"Lt","name":"Lithuanian litas"},
                {"cc":"LVL","symbol":"Ls","name":"Latvian lats"},
                {"cc":"LYD","symbol":"LD","name":"Libyan dinar"},
                {"cc":"MAD","symbol":"MAD","name":"Moroccan dirham"},
                {"cc":"MDL","symbol":"MDL","name":"Moldovan leu"},
                {"cc":"MGA","symbol":"FMG","name":"Malagasy ariary"},
                {"cc":"MKD","symbol":"MKD","name":"Macedonian denar"},
                {"cc":"MMK","symbol":"K","name":"Myanma kyat"},
                {"cc":"MNT","symbol":"\u20ae","name":"Mongolian tugrik"},
                {"cc":"MOP","symbol":"P","name":"Macanese pataca"},
                {"cc":"MRO","symbol":"UM","name":"Mauritanian ouguiya"},
                {"cc":"MUR","symbol":"Rs","name":"Mauritian rupee"},
                {"cc":"MVR","symbol":"Rf","name":"Maldivian rufiyaa"},
                {"cc":"MWK","symbol":"MK","name":"Malawian kwacha"},
                {"cc":"MXN","symbol":"$","name":"Mexican peso"},
                {"cc":"MYR","symbol":"RM","name":"Malaysian ringgit"},
                {"cc":"MZM","symbol":"MTn","name":"Mozambican metical"},
                {"cc":"NAD","symbol":"N$","name":"Namibian dollar"},
                {"cc":"NGN","symbol":"\u20a6","name":"Nigerian naira"},
                {"cc":"NIO","symbol":"C$","name":"Nicaraguan c\u00f3rdoba"},
                {"cc":"NOK","symbol":"kr","name":"Norwegian krone"},
                {"cc":"NPR","symbol":"NRs","name":"Nepalese rupee"},
                {"cc":"NZD","symbol":"NZ$","name":"New Zealand dollar"},
                {"cc":"OMR","symbol":"OMR","name":"Omani rial"},
                {"cc":"PAB","symbol":"B./","name":"Panamanian balboa"},
                {"cc":"PEN","symbol":"S/.","name":"Peruvian nuevo sol"},
                {"cc":"PGK","symbol":"K","name":"Papua New Guinean kina"},
                {"cc":"PHP","symbol":"\u20b1","name":"Philippine peso"},
                {"cc":"PKR","symbol":"Rs.","name":"Pakistani rupee"},
                {"cc":"PLN","symbol":"z\u0142","name":"Polish zloty"},
                {"cc":"PYG","symbol":"\u20b2","name":"Paraguayan guarani"},
                {"cc":"QAR","symbol":"QR","name":"Qatari riyal"},
                {"cc":"RON","symbol":"L","name":"Romanian leu"},
                {"cc":"RSD","symbol":"din.","name":"Serbian dinar"},
                {"cc":"RUB","symbol":"R","name":"Russian ruble"},
                {"cc":"SAR","symbol":"SR","name":"Saudi riyal"},
                {"cc":"SBD","symbol":"SI$","name":"Solomon Islands dollar"},
                {"cc":"SCR","symbol":"SR","name":"Seychellois rupee"},
                {"cc":"SDG","symbol":"SDG","name":"Sudanese pound"},
                {"cc":"SEK","symbol":"kr","name":"Swedish krona"},
                {"cc":"SGD","symbol":"S$","name":"Singapore dollar"},
                {"cc":"SHP","symbol":"\u00a3","name":"Saint Helena pound"},
                {"cc":"SLL","symbol":"Le","name":"Sierra Leonean leone"},
                {"cc":"SOS","symbol":"Sh.","name":"Somali shilling"},
                {"cc":"SRD","symbol":"$","name":"Surinamese dollar"},
                {"cc":"SYP","symbol":"LS","name":"Syrian pound"},
                {"cc":"SZL","symbol":"E","name":"Swazi lilangeni"},
                {"cc":"THB","symbol":"\u0e3f","name":"Thai baht"},
                {"cc":"TJS","symbol":"TJS","name":"Tajikistani somoni"},
                {"cc":"TMT","symbol":"m","name":"Turkmen manat"},
                {"cc":"TND","symbol":"DT","name":"Tunisian dinar"},
                {"cc":"TRY","symbol":"TRY","name":"Turkish new lira"},
                {"cc":"TTD","symbol":"TT$","name":"Trinidad and Tobago dollar"},
                {"cc":"TWD","symbol":"NT$","name":"New Taiwan dollar"},
                {"cc":"TZS","symbol":"TZS","name":"Tanzanian shilling"},
                {"cc":"UAH","symbol":"UAH","name":"Ukrainian hryvnia"},
                {"cc":"UGX","symbol":"USh","name":"Ugandan shilling"},
                {"cc":"USD","symbol":"US$","name":"United States dollar"},
                {"cc":"UYU","symbol":"$U","name":"Uruguayan peso"},
                {"cc":"UZS","symbol":"UZS","name":"Uzbekistani som"},
                {"cc":"VEB","symbol":"Bs","name":"Venezuelan bolivar"},
                {"cc":"VND","symbol":"\u20ab","name":"Vietnamese dong"},
                {"cc":"VUV","symbol":"VT","name":"Vanuatu vatu"},
                {"cc":"WST","symbol":"WS$","name":"Samoan tala"},
                {"cc":"XAF","symbol":"CFA","name":"Central African CFA franc"},
                {"cc":"XCD","symbol":"EC$","name":"East Caribbean dollar"},
                {"cc":"XDR","symbol":"SDR","name":"Special Drawing Rights"},
                {"cc":"XOF","symbol":"CFA","name":"West African CFA franc"},
                {"cc":"XPF","symbol":"F","name":"CFP franc"},
                {"cc":"YER","symbol":"YER","name":"Yemeni rial"},
                {"cc":"ZAR","symbol":"R","name":"South African rand"},
                {"cc":"ZMK","symbol":"ZK","name":"Zambian kwacha"},
                {"cc":"ZWR","symbol":"Z$","name":"Zimbabwean dollar"}
              ];

            var param = {
                ein_no : '',
                tax_year : ''
            }
            global.setEIN_TAXYEAR = function(ein, tax_year, chart_type){
                param.ein_no = ein;
                param.tax_year = tax_year;
                param.chart_type = chart_type;
            }

            global.getEIN = function(){
                return param.ein_no;
            }

            global.getChartType = function(){
                return param.chart_type;
            }

            var filterGroupData = '';
            global.setFilterGroup = function(data){
                filterGroupData = data;
            }

            global.getFilterGroup = function(){
                return filterGroupData;
            }

            var actionKey = '';
            global.setActionKey = function(key){
                actionKey = key;
            }

            global.getActionKey = function(){
                return actionKey;
            }

            var insuranceSaveBtn = true;
            global.setInsuranceSaveBtnState = function(state){
                insuranceSaveBtn = state;
            }

            global.getInsuranceSaveBtnState = function(){
                return insuranceSaveBtn;
            }

            global.getTAXYEAR = function(){
                return param.tax_year;
            }

            var bsla_filter;
            global.setBslaFilterState = function(state){
                bsla_filter = state;
            }

            global.getBslaFilterState = function(){
                return bsla_filter;
            }

            var selected_client;
            global.setClientSelection = function(data){
                selected_client = data;
            }

            global.getClientSelection = function(){
                return selected_client;
            }

            var raw_data = [];
            global.setRawData = function(data){
                raw_data = data;
            }

            global.getRawData = function(){
                return raw_data;
            }

            var pRaw_data = [];
            global.setpRawData = function(data){
                pRaw_data = data;
            }

            global.getpRawData = function(){
                return pRaw_data;
            }

            global.getStates = function(){
                return states;
            }


            global.getCountryByCode = function(code){
                var filtered =  _.findByKeyPartialValue(countries,code,'code');
                return filtered;
            }

            global.getCountries = function(){
                return countries;
            }

            global.getCurrencies = function() {
                return currencies;
            }


            global.getClients = function(){
                return USER_SETTINGS.user.clients;
            }

            global.getClient = function(){
                return USER_SETTINGS.user.client;
            }

            var detail_diagnostic_data = {};
            global.setDetailDiagData = function(data){
                detail_diagnostic_data = data;
            }

            global.getDetailDiagData = function(){
                return detail_diagnostic_data;
            }

            global.setClient = function (client_key) {

                console.log("client_key : " , client_key);
                // this is for query params in client key;
             /*    var getTabCount = localStorage.getItem('tabCount');
               
                if(parseInt(getTabCount)>1 && parseInt(sessionStorage.client_key) != client_key){
                    alert('Please Logout to the current client in all the tabs and switch to new client');
                    return;
                } */
                const old_client_key = $location.search()['client_key'];
                $location.search('client_key', client_key.toString());

                var params = {client_key:client_key}
                params.fromClientSwitch = true;
                var url = GENERAL_CONFIG.base_url + '/setClient';

                var promise = $http({ method: "post", url:url,data:params}).then(function successCallback(response) {
                    if(response.data.callSuccess !== "1"){
                        $location.search('client_key', old_client_key);
                    }else{
                        $location.search('client_key',response.data.switch_to_client_key.toString())
                    }
                    return response.data;
                }, function errorCallback(response) {
                    $location.search('client_key', old_client_key);
                    AlertService.add("error", "oops  the requested data could not be saved. If this continues please contact support.");
                    return false;
                });

                return promise;


            };

            //gets the bussiness hierarchy data..
            global.getBizHeirarch = function(params) {
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.base_url + '/getBizzHierarchyFilter?tax_year=' + params.tax_year  + '&scenario=' + params.scenario + '&jcd_key=' + params.jcd_key,
                    data: params
                }).then(function (response) {
                    return response;
                });

                return promise;
            }

            global.downloadUserGuide = function() {
                $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url + '/getUserGuide'
                }).then(function (response) {
                    var a = document.createElement("a");
                    a.href = response.data.presignedS3Url;
                    let url = response.data.presignedS3Url.split("?")[0].split("/");
                    a.download = url[url.length - 1];
                    a.target = "_blank";
                    a.click();
                });
            }



        /*this is coming from gridService "save_max_load_user_amt" is case statement for GridSaveFactory*/

        /* function save_max_loan_user_amt(){
            var service = $injector.get('JsonObjectFactory');
            var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=97";
            var affectedRow = _data.rowData;

            var jsonObj = JSON.stringify(affectedRow);

            var jsonSettings = '{}';

            var params = _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  "saveMaxLoanDetails";

            service.saveJSON(url , params).then(function(result){

                if(result.callSuccess === "1"){
                }else{
                    AlertService.add("error", "Any error has occurred. If this error continues please go to the help page and contact support");
                }

                var args = {tax_year:affectedRow.tax_year, scenario:affectedRow.scenario, jcd_key: affectedRow.jcd_key, trans_details_key: affectedRow.trans_details_key, gridFilter: {TAX_YEAR:affectedRow.tax_year, SCENARIO:affectedRow.scenario, JCD_KEY: affectedRow.jcd_key, TRANS_DETAILS_KEY: affectedRow.trans_details_key}};
                $rootScope.$emit('gridUpdate', args);

            });
        } */



            return global;

        }])



        .factory('ContextFactory', [ '$rootScope','$timeout', '$interval','$http', 'GENERAL_CONFIG', function($rootScope,$timeout, $interval,$http, GENERAL_CONFIG) {

            var contextFactory = {};
            var URLS = {FETCH: GENERAL_CONFIG.base_url + '/loadContextObject'};

            contextFactory.contextKey = null;
            contextFactory.contextObject = {};


            contextFactory.clearContext = function(){
                contextFactory.context_key = null;
            }

            contextFactory.setContextKey = function(_context_key){
                contextFactory.context_key = _context_key;
            }

            contextFactory.getContextObj = function () {
                var returnObj = {};
                var promise = $http({
                    method: "post",
                    url: URLS.FETCH,
                    data: {context_key: contextFactory.contextKey}
                }).then(function (response) {
                     //console.log(" response" , response)
                    //contextFactory.contextObject = response.data
                    return response.data;
                });

                return promise;
            };



             return contextFactory;

        }])




 .filter( 'shortNumber', function() {
        return function( number ) {

            if(number  === 0){
                return 0;
            }


            if ( number ) {
                var abs = Math.abs( number );
                if ( abs >= Math.pow( 10, 12 ) ) {
// trillion
                    number = ( number / Math.pow( 10, 12 ) ).toFixed( 1 ) + "T";
                } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
// billion
                    number = ( number / Math.pow( 10, 9 ) ).toFixed( 1 ) + "B";
                } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
// million
                    number = ( number / Math.pow( 10, 6 ) ).toFixed( 1 ) + "M";
                } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
// thousand
                    number = ( number / Math.pow( 10, 3 ) ).toFixed( 1 ) + "K";
                }

                return number;
            }
        };
    } )




        .factory('JCDFactory', [ '$rootScope','$timeout', '$interval','$http','JsonObjectFactory','GENERAL_CONFIG', function($rootScope,$timeout, $interval,$http,JsonObjectFactory, GENERAL_CONFIG) {

            var JCDFactory = {};
            var URLS = {FETCH: GENERAL_CONFIG.base_url + '/loadJCDObject'};

            var params = {};
            params.action_id =  80;
            JCDFactory.jcd_ta_key = null;
            JCDFactory.JCDList = [];
            JCDFactory.taskList = [];
            JCDFactory.lists = {
            };


           function setList(num){

               if( num < 1  ){
                   JCDFactory.lists.jurisdiction = {list: [], selected:null, disabled:false};
               }

               if( num < 2  ){
                   JCDFactory.lists.domain = {
                       list: [],
                       selected:null,
                       disabled:false
                   };
               }

               if( num < 3  ) {
                   JCDFactory.lists.channel = {
                       list: [],
                       selected: null,
                       disabled: false
                   };
               }

               if( num < 4  ) {
                   JCDFactory.lists.task = {
                       list: [],
                       taskList :[],
                       selected: null,
                       disabled: false
                   };
               }

               if( num < 5  ) {
                   JCDFactory.lists.activity = {
                       list: [],
                       selected: null,
                       disabled: true
                   };
               }


           }


            setList(0);

            JCDFactory.setObj = function (num,_jcd_ta_key) {

                setList(num);

                var   _jur_key, _dom_key,_chan_key,_task_key,_activity_key;
                _jur_key = (JCDFactory.lists.jurisdiction.selected) ? JCDFactory.lists.jurisdiction.selected.value : null ;
                _dom_key = (JCDFactory.lists.domain.selected) ? JCDFactory.lists.domain.selected.value : null;
                _chan_key = (JCDFactory.lists.channel.selected) ? JCDFactory.lists.channel.selected.value : null;
                _task_key = (JCDFactory.lists.task.selected) ?  JCDFactory.lists.task.selected : null; // selected - taskkey
                _activity_key = (JCDFactory.lists.activity.selected) ?   JCDFactory.lists.activity.selected.value : null ;

                JCDFactory.lists.task.list=[];
                var jcd_ta = _.find(JCDFactory.JCDList, { 'ACTIVITY_KEY': _activity_key });
                JCDFactory.jcd_ta_key =  (jcd_ta ) ? jcd_ta.JCD_TA_KEY : null;

                if( _jcd_ta_key !== undefined  ){
                   var list =  _.find(JCDFactory.JCDList, { 'JCD_TA_KEY': _jcd_ta_key });
                    if( list  ){
                         _jur_key = list.JURISDICTION_KEY;
                         _dom_key = list.DOMAIN_KEY;
                         _chan_key = list.CHANNEL_KEY;
                         _task_key = list.TASK_KEY;
                        _activity_key = list.ACTIVITY_KEY;
                    }

                }

                _.forEach(JCDFactory.JCDList, function(value) {
                    var jobj = {};
                    if(_.find(JCDFactory.lists.jurisdiction.list, { 'value': value.JURISDICTION_KEY }) === undefined  ){
                        jobj = {name:value.JURISDICTION_CODE, value:value.JURISDICTION_KEY }
                        JCDFactory.lists.jurisdiction.list.push(jobj);
                        if( _jur_key ===  jobj.value ){
                            JCDFactory.lists.jurisdiction.selected = jobj;
                        }
                    }


                    if(_.find(JCDFactory.lists.domain.list, { 'value': value.DOMAIN_KEY }) === undefined  ){
                        jobj = {name:value.DOMAIN_NAME, value:value.DOMAIN_KEY }
                        JCDFactory.lists.domain.list.push(jobj);
                       // JCDFactory.lists.domain.disabled = false;
                        if( _dom_key ===  jobj.value ){
                            JCDFactory.lists.domain.selected = jobj;
                        }
                    }

                    if(_.find(JCDFactory.lists.channel.list, { 'value': value.CHANNEL_KEY }) === undefined ){
                        jobj = {name:value.CHANNEL_NAME, value:value.CHANNEL_KEY }
                     /*  JCDFactory.lists.channel.disabled = false;
                        if( value.DOMAIN_KEY ===  _dom_key  ){
                             JCDFactory.lists.channel.list.push(jobj);
                         } */
                         JCDFactory.lists.channel.list.push(jobj);

                        if( _chan_key ===  jobj.value ){
                            JCDFactory.lists.channel.selected = jobj;
                        }
                    }

                    if(_.find(JCDFactory.lists.task.list, { 'value': value.TASK_KEY }) === undefined && _chan_key ){
                         jobj = {name:value.TASK_NAME, value:value.TASK_KEY, parent_task_key:value.PARENT_TASK_KEY,level:value.level,indent:value.indent}
                        if( value.CHANNEL_KEY ===  _chan_key  ) {
                            JCDFactory.lists.task.list.push(jobj);
                        }
                        JCDFactory.lists.task.disabled = false;

                        if( _task_key ==  jobj.value ){
                            JCDFactory.lists.task.selected = _task_key;
                        }

                    }

                    if(_.find(JCDFactory.lists.activity.list, { 'value': value.ACTIVITY_KEY }) === undefined && _task_key  ){
                        jobj = {name:value.ACTIVITY_NAME, value:value.ACTIVITY_KEY }
                        if( value.TASK_KEY ===  _task_key && value.CHANNEL_KEY ===  _chan_key  ) {
                            JCDFactory.lists.activity.list.push(jobj);
                        }
                         JCDFactory.lists.activity.disabled = false;
                        if( _activity_key ===  jobj.value ){
                            JCDFactory.lists.activity.selected = jobj;
                        }
                    }

                });
               if((JCDFactory.lists.task.list).length > 0){
                    JCDFactory.lists.task.taskList = _.cloneDeep(addIndent(_.cloneDeep(JCDFactory.lists.task.list)));
                }
            }
            function addIndent(list){
                var taskList=[];
                /* task Hierarchy starts here */

                function transformToTree(list){
                    var nodes = {};
                    return list.filter(function(obj){
                        var id = obj["value"],parentId = obj["parent_task_key"];
                        nodes[id] = _.defaults(obj, nodes[id],{children:[]});
                        parentId && (nodes[parentId] = (nodes[parentId] || { children: [] }))["children"].push(obj);
                        return !parentId;
                    });
                }
                /* call the JSON TREE */
                list = transformToTree(list);
                /* task Hierarchy for drop dwon  */
                var times = function (n, str) {
                    var result = '';
                    for (var i = 0; i < n; i++) {
                        result += str;
                    }
                    return result;
                };

                var recur = function (item, level, arr) {
                    arr.push({
                        task_name: item.name,
                        task_key: item.value,
                        level: item.level,
                        indent : times(level, '\u00A0\u00A0\u00A0'),

                    });
                    if (item.children){
                        item.children.forEach(function (item){
                            recur(item, level + 1, arr);
                        });
                    }
                };
                list.forEach(function (item) {
                    recur(item, 0, taskList);
                });
                /* Ends Task Hierarchy Ends Here */
                console.log("task hierarchy",taskList);
                return taskList;
            }

            JCDFactory.loadJCDObj = function () {
                  JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                      JCDFactory.JCDList = data.jsonObject;
                       console.log("JCDFactory.JCDList : " , JCDFactory.JCDList);
                      JCDFactory.setObj();
                  });
            };


            return JCDFactory;
        }])






        .factory('WorkflowTCFactory', [ '$rootScope','$timeout', '$interval','$http','JsonObjectFactory','GENERAL_CONFIG', 'GlobalService', function($rootScope,$timeout, $interval,$http,JsonObjectFactory,GENERAL_CONFIG, GlobalService) {

                var WTCFactory = {};
                var URLS = {
                    FETCH: GENERAL_CONFIG.base_url  + '/getTaxPlacematDetails?clientKey=' + GlobalService.getClient().client_key,
                    GET_ACTIONS: GENERAL_CONFIG.base_url + '/getGridActions?screen_key=',
                    GET_TAX_TYPE_DETAILS: GENERAL_CONFIG.base_url + '/getTaxTypeData'
                };

                WTCFactory.data = null;
                var params = {};
                params.action_id =  80;

                var params = {
                        ein : GlobalService.getEIN(),
                        tax_year : GlobalService.getTAXYEAR(),
                    };
                WTCFactory.loadObject = function (tax_year, tax_type, id) {
                	console.log('tax_type : '+ tax_type);
                    var promise = $http({
                        method: "GET",
                        url: URLS.FETCH + '&taxYear=' + tax_year + '&taxType=' + tax_type + '&placematId=' + id
                    }).then(function (response) {
                        return response.data;
                    });
                    return promise;
                };//

                WTCFactory.getTaxTypeDetails = function () {
                    var promise = $http({
                        method: "GET",
                        url: URLS.GET_TAX_TYPE_DETAILS
                    }).then(function (response) {
                        return response.data;
                    });
                    return promise;
                };

                WTCFactory.loadGridActions = function (screen_key) {
                    var promise = $http({
                        method: "GET",
                        url: URLS.GET_ACTIONS + screen_key
                    }).then(function (response) {
                        return response.data;
                    });
                    return promise;
                };

                return WTCFactory;
            }])






        .factory('ModalFactory', [ '$rootScope','$timeout', '$interval','$http', 'workspaceFactory','GlobalService','GENERAL_CONFIG', 
        function($rootScope,$timeout, $interval,$http, workspaceFactory,GlobalService,GENERAL_CONFIG) {
            var modalFactory = {};
            modalFactory.response = {}
            // create an array of alerts available globally
            var URLS = {
                FETCH: '/getScreenModal',
            }
           
            modalFactory.getModal = function (_modalName,_data) {
                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                console.log("___data",_data);
                var returnObj = {};
                var jsonObj = [];
                if(_.isArray(_data) && _data.length >0){
                    _.each(_data,function(item){
                        jsonObj.push({
                            "leid": item.data.LEID || item.data.SOURCE_LEID,//"IF1139",
                            "me_code": item.data.ME_CODE || item.data.ME ||  item.data.SOURCE_ME_CODE,
                            "cdr_number":item.data.CDR_NO || item.data.TAX_CODE || item.data.SOURCE_CDR_NO,
                            "consol_group_key": item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP,//1,
                            "reporting_period": item.data.REPORTING_PERIOD ||item.data.SOURCE_REPORTING_PERIOD,
                            "combination_key":item.data.COMBINATION_KEY || item.data.COMPL_COMBINATION_KEY || item.data.HO_COMBINATION_KEY//1
                            ,scenario_key:item.data.SCENARIO_KEY ? item.data.SCENARIO_KEY : filterParams.scenario !=null ? filterParams.scenario : GlobalService.globalParams.scenario,

                        })
                    })
                }else{
                    if(_data && _data.rowData){
                        if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.screen_key == 10075){ /* Tax Attribute Screen */
                            jsonObj.push({  
                            "leid":_data.rowData.POSTING_LEID,//"IF1139",
                            "me_code": _data.rowData.POSTING_ME_CODE,
                            "cdr_number":_data.rowData.POSTING_CDR_NO,
                            "reporting_period": _data.rowData.POSTING_REPORTING_PERIOD,
                            "scenario_key":_data.rowData.SCENARIO_KEY ? _data.rowData.SCENARIO_KEY : filterParams.scenario !=null ?filterParams.scenario : GlobalService.globalParams.scenario,

                        })
                     }else{
                            jsonObj.push({
                                "leid":_data.rowData.LEID ,//"IF1139",
                                "me_code": _data.rowData.ME_CODE || _data.rowData.ME,
                                "cdr_number":_data.rowData.CDR_NO || _data.rowData.TAX_CODE|| _data.rowData.HO_CDR_NO,
                                "consol_group_key": _data.rowData.CONSOL_GROUP_KEY || _data.rowData.FILING_GROUP,//1,
                                "reporting_period": _data.rowData.REPORTING_PERIOD|| _data.rowData.SOURCE_REPORTING_PERIOD,
                                "combination_key":_data.rowData.COMBINATION_KEY  || _data.rowData.COMPL_COMBINATION_KEY || _data.rowData.HO_COMBINATION_KEY//1,
                                ,"scenario_key":_data.rowData.SCENARIO_KEY ? _data.rowData.SCENARIO_KEY : filterParams.scenario !=null ?filterParams.scenario : GlobalService.globalParams.scenario,
                            })
                        }
                    }else{
                        jsonObj.push({
                            "scenario_key": filterParams.scenario !=null ?filterParams.scenario : GlobalService.globalParams.scenario,
                        })
                    }
                   
                }
                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url +  URLS.FETCH,
                    data: {
                        modal_name:_modalName,
                        tax_year: filterParams.tax_year !=null ?filterParams.tax_year : GlobalService.globalParams.tax_year,
                        scenario:filterParams.scenario !=null ?filterParams.scenario : GlobalService.globalParams.scenario,
                        jcd_key: GlobalService.globalParams.jcd_key,
                        issue_id: GlobalService.globalParams.issue_id,
                        issue_short_desc: GlobalService.globalParams.issue_short_desc,
                        jsonObj: JSON.stringify(jsonObj)
                    }
                }).then(function (response) {
                    console.log(" response" , response)
                    modalFactory.response = response;
                    return response.data;
                });


                return promise;



                /*
                var returnObj = {};
                if( _modalName === 'reclassBook' ){
                    returnObj.template =  'app/components/reclass/reclass-book-tpl.html';
                    returnObj.controler = 'ReclassBookController';
                    returnObj.size = "lg";
                }else if( _modalName ===  'reclassTaxAdj'){
                    returnObj.template =  'app/components/reclass/reclass-book-tpl.html';
                    returnObj.controler = 'ReclassBookController';
                    returnObj.size = "lg";
                }else if( _modalName ===  'reclassAdjTax'){
                    returnObj.template =  'app/components/reclass/reclass-book-tpl.html';
                    returnObj.controler = 'ReclassBookController';
                    returnObj.size = "lg";
                }else if( _modalName ===  'methodSettlement'){
                    returnObj.template =  'app/components/methodSettlement/methodSettlement-tpl.html';
                    returnObj.controler = 'MethodSettlementController as ctrl';
                    returnObj.size = "lg";
                }else if( _modalName ===  'gofSettlement'){
                    returnObj.template =  'app/components/methodSettlement/gofSettlement-tpl.html';
                    returnObj.controler = 'GofSettlementController as ctrl';
                    returnObj.size = "md";
                }else if( _modalName ===  'project_tag'){
                    returnObj.template =  'app/components/projectTag/project-tag-tpl.html';
                    returnObj.controler = 'ProjectTagController as ctrl';
                    returnObj.size = "lg";
                }
                return returnObj;

                */



            };





            modalFactory.getData = function (_url) {
                    var promise = $http.get(_url).then(function (response) {
                        return response.data;
                    });
                    // Return the promise to the controller
                    return promise;
            }

            return modalFactory;

        }])





        .factory('AlertService', [ '$rootScope','$timeout', '$interval', function($rootScope,$timeout, $interval) {
            var alertService = {};
            // create an array of alerts available globally
            $rootScope.alerts = [];

            alertService.add = function(type, msg, timeout) {
                $rootScope.alerts.push({'type': type, 'msg': msg, 'timeout': timeout });

                /*
                 * $interval is used instead of $timeout for the reason given below (it interferes with protractor testing):
                 *
                 * Reference: https://github.com/angular/protractor/blob/master/docs/faq.md
                 *
                 * Protractor attempts to wait until the page is completely loaded before performing any action (such as finding an element or sending a command to an element).
                 * If your application continuously polls $timeout or $http, it will never be registered as completely loaded.
                 * You should use the $interval service for anything that polls continuously (introduced in Angular 1.2rc3).
                 */
                var alertPromise = undefined;

                if( timeout != undefined && timeout != "" ) {
                    alertPromise = $interval(function () {
                        alertService.closeAlert(0, alertPromise);
                    }, timeout, 1);
                }



            };


            alertService.closeAlert = function(index, alertPromise) {
                $rootScope.alerts.splice(index, 1);
            };


            return alertService;
        }])





        .factory("UserFactory",['$q', '$http', '$stateParams', '$rootScope', '$log', 'GENERAL_CONFIG', function($q, $http, $stateParams, $rootScope, $log, GENERAL_CONFIG) {
            var URLS = {
                    FETCH_USER: GENERAL_CONFIG.base_url + '/getUserSession',
                    UPDATE: GENERAL_CONFIG.base_url +'/updateUser'
                }
                ,user = {}

            var userFactory = {};



            function cacheUser(result) {
                user = result.data.user;
                return user;
            }

            userFactory.getUserSession = function () {
                return (userFactory.user) ? $q.when(user) : $http.get(URLS.FETCH_USER).then(cacheUser);
            };


            userFactory.saveUserTheme = function(data){
                var promise = $http({
                    method: "POST",
                    url: GENERAL_CONFIG.admin_api_url +'/updateUserTheme',
                    data:data
                }).then(function(response){
                        return response;
                });
                return promise;
            }

          /*   userFactory.getUserTheme = function(data){
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.base_url+'/getUserTheme',
                    data:data
                }).then(function(response){
                        return response;
                });
                return promise;
            } */

            return userFactory;

        }])

        .factory("IdleFactory",['$q', '$http', '$stateParams', '$rootScope', '$log', 'GENERAL_CONFIG', function($q, $http, $stateParams, $rootScope, $log, GENERAL_CONFIG) {
            var idleFactory = {};

            idleFactory.keepAlive = function () {

                $http({method: 'GET', url: GENERAL_CONFIG.keep_alive_url}).then(function(result) {
                    if( result.ajaxSuccess = 1){
                    }
                });
            }


            return idleFactory;
        }])



        .factory("AdminFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', function($q, $http, $log, GENERAL_CONFIG) {

            var adminFactory = {};
            var URLS = {
                GET_SERVER_SETTINGS: GENERAL_CONFIG.base_url+ '/secureConfigSettings',
                RESET_CACHE: GENERAL_CONFIG.admin_api_url  + '/resetCache',
                SAVE_SYSTEM_SETTINGS: GENERAL_CONFIG.base_url + '/saveSystemSetting',
                SAVE_APP_SETTINGS: GENERAL_CONFIG.base_url + '/saveAppConfigSetting  ',
                SAVE_CLIENT_SETTINGS: GENERAL_CONFIG.base_url + '/saveClientSystemSetting',
                GET_USER_DETAILS: GENERAL_CONFIG.base_url + '/userDetails',
                GET_APP_KEYS: GENERAL_CONFIG.base_url + '/getAppKey',
                GET_APP_SETTINGS: GENERAL_CONFIG.base_url + '/appConfigSettings',
                GET_CLIENT_SETTINGS: GENERAL_CONFIG.base_url + '/clientConfigSettings',
                deleteSystemSetting: GENERAL_CONFIG.base_url + '/deleteSystemSetting',
                deleteAppConfigSetting: GENERAL_CONFIG.base_url + '/deleteAppConfigSetting',
                deleteClientSystemSetting: GENERAL_CONFIG.base_url + '/deleteClientSystemSetting',
            }

            adminFactory.setCachedConfigSetting = function (data) {
                var fd = new FormData();
				fd.append("appKey", data.appKey);
                fd.append("clientKey", data.clientKey);
                fd.append("clientDatasource", data.clientDatasource);
                fd.append("config", data.config);

                var promise = $http.post(URLS.RESET_CACHE, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            adminFactory.getConfigSettings = function(_appKey){
                var promise = $http({
                    method: "GET",
                    url: URLS.GET_SERVER_SETTINGS + '?appKey=' + _appKey,
                    data: {}
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            adminFactory.getAppKeys = function(clientKey){
                var promise = $http({
                    method: "GET",
                    url: URLS.GET_APP_KEYS + '?clientKey=' + clientKey,
                    data: {}
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            adminFactory.loadAppSettings = function(clientKey, clientDatasource, appKey){
                var promise = $http({
                    method: "GET",
                    url: URLS.GET_APP_SETTINGS + '?clientKey=' + clientKey + '&clientDatasource=' + clientDatasource  + '&appKey=' + appKey,
                    data: {}
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            adminFactory.loadClientSettings = function(clientKey, clientDatasource){
                var promise = $http({
                    method: "GET",
                    url: URLS.GET_CLIENT_SETTINGS + '?clientKey=' + clientKey + '&clientDatasource=' + clientDatasource,
                    data: {}
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            adminFactory.getuserDetails = function(_params){
                var promise = $http({
                    method: "POST",
                    url: URLS.GET_USER_DETAILS,
                    data: _params

                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }

            adminFactory.postUserDetails = function(newUser){
                var promise = $http({
                    method: "GET",
                    url: GENERAL_CONFIG.admin_api_url  +'/changeSSO/'+newUser,
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            }



            adminFactory.clobInsert = function(url,_params){

                //console.log("//////////////// called ////////////////////")


                var promise = $http({
                    method: "post",
                    url: url,
                    data: _params
                }).then(function (response) {
                    var status = response.status;
                    return response.data;
                });

                return promise;
            }

            adminFactory.saveSystemSetting = function (data, type, client, appKey) {
                var fd = new FormData();
                var url = "";
                fd.append("key", data.key);
                fd.append("value", data.value);
                
                if(type == 'appconfigSettings'){
                    url = URLS.SAVE_APP_SETTINGS;
                    fd.append("clientKey ", client.client_key);
                    fd.append("appKey ", appKey);
                    fd.append("clientDatasource", client.client_datasource);
                }else if (type == 'clientconfigSettings'){
                    url = URLS.SAVE_CLIENT_SETTINGS;
                    fd.append("clientKey ", client.client_key);
                    fd.append("clientDatasource", client.client_datasource);
                }else{
                    url = URLS.SAVE_SYSTEM_SETTINGS;
                }

                var promise = $http.post(url, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };

            adminFactory.deleteSystemSetting = function (type, key, client, appKey) {
                var url = "";
                var fd = new FormData();
                fd.append("key", key);
                
                if(type == 'appconfigSettings'){
                    url = URLS.deleteAppConfigSetting;
                    fd.append("clientKey", client.client_key);
                    fd.append("appKey ", appKey);
                    fd.append("clientDatasource", client.client_datasource);
                }else if (type == 'clientconfigSettings'){
                    url = URLS.deleteClientSystemSetting;
                    fd.append("clientKey", client.client_key);
                    fd.append("clientDatasource", client.client_datasource);
                }else{
                    url = URLS.deleteSystemSetting;
                }

                var promise = $http.post(url, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function (response) {
                    return response.data;
                });
                return promise;
            };


            return adminFactory;


        }])


        .factory("settingFactory",['$q', '$http', '$log', 'GENERAL_CONFIG', function($q, $http, $log, GENERAL_CONFIG) {

            var URLS = {
                FETCH: '/getUserSettings',
                UPDATE: '/saveUserSettings'
            }

            var settingFactory = {};

            settingFactory.updateSettings = function(_data){

                var promise = $http({
                    method: "post",
                    url: GENERAL_CONFIG.base_url + URLS.UPDATE,
                    data: {jsonObj:JSON.stringify(_data)}

                }).then(function (response) {
                        return response.data.ajaxSuccess;
                    });

                return promise;

            }

            settingFactory.getSettings = function(){
                // $http returns a promise, which has a then function, which also returns a promise
                var promise = $http.get(GENERAL_CONFIG.base_url + URLS.FETCH).then(function (response) {
                    // The then function here is an opportunity to modify the response

                    // The return value gets picked up by the then in the controller.
                    return response.data;

                });
                // Return the promise to the controller
                return promise;
            }

            return settingFactory;
        }])



        .factory('JsonObjectCache', ['$cacheFactory', function($cacheFactory) {
            return $cacheFactory('json-object-cache');
        }])







        .factory("JsonObjectFactory",['$q', '$http', '$stateParams', '$rootScope', '$log', 'GENERAL_CONFIG','GlobalService','JsonObjectCache', 'AlertService', 'workspaceFactory',
         function($q, $http, $stateParams, $rootScope, $log, GENERAL_CONFIG,GlobalService,JsonObjectCache,AlertService,workspaceFactory) {
            var JsonObjectFactory = {};




            JsonObjectFactory.saveJSONWParams = function (_url,_data) {

                var defer = $q.defer();

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var paramsMerge = _.merge({}, filterParams   , _data);
                var params = _.merge({}, GlobalService.globalParams, paramsMerge);

                ///////// TEMP HACK /////////////: This will not be needed when scenario is added to filter try; && (params.scenario == ""|| params.scenario == undefined || params.scenario == null)
                if(params.tax_year && params.tax_year.length ){
                    params.scenario = GlobalService.getScenario(params.tax_year);
                }
                
                var checkLockedbj = [];
                var rowData = workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.selected
                              && workspaceFactory.activeScreen.data.selected.rowData;
                if(_.isArray(rowData) && rowData.length>0){
                    _.each(rowData,function(item){
                        checkLockedbj.push({
                            "leid": item.data.LEID || item.data.SOURCE_LEID,//"IF1139",
                            "me_code": item.data.ME_CODE || item.data.ME || item.data.SOURCE_ME_CODE,
                            "cdr_number":item.data.CDR_NO || rowData.TAX_CODE || item.data.SOURCE_CDR_NO,
                            "consol_group_key": item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP,//1,
                            "reporting_period": item.data.REPORTING_PERIOD || item.data.SOURCE_REPORTING_PERIOD,
                            "combination_key":item.data.COMBINATION_KEY || item.data.COMPL_COMBINATION_KEY || item.data.HO_COMBINATION_KEY, //1,
                            "scenario_key": item.data.SCENARIO_KEY ? item.data.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                        })
                    })
                }else if(rowData){
                    checkLockedbj.push({
                        "leid":rowData.LEID ||rowData.SOURCE_LEID ,//"IF1139",
                        "me_code": rowData.ME_CODE || rowData.ME || rowData.SOURCE_ME_CODE ,
                        "cdr_number":rowData.CDR_NO || rowData.TAX_CODE || rowData.SOURCE_CDR_NO,
                        "consol_group_key": rowData.CONSOL_GROUP_KEY || rowData.FILING_GROUP,//1,
                        "reporting_period": rowData.REPORTING_PERIOD || rowData.SOURCE_REPORTING_PERIOD,
                        "combination_key":rowData.COMBINATION_KEY || rowData.COMPL_COMBINATION_KEY || rowData.HO_COMBINATION_KEY,
                        "scenario_key": rowData.SCENARIO_KEY ? rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                    })
                }else{
                    checkLockedbj.push({
                        "scenario_key":  _data.scenario? _data.scenario : filterParams.scenario

                    })
                }
               
                params.checkLockedbj = JSON.stringify(checkLockedbj);


                console.log("params : " , params);


                let scenarioCode = '';
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.filters  && typeof workspaceFactory.activeScreen.filters.getFilters === "function"){
                    /*  scenarioCode =  workspaceFactory.activeScreen.filters.getFilters().length>1 ? 
                     _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected : '';//_.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
 
                     _data.scenarioCode = scenarioCode.CODE; */
                     let foundScenario =  _.find(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'});
                     if(foundScenario && foundScenario.selected){
                         scenarioCode = foundScenario.selected;
                         params.scenarioCode = scenarioCode.CODE;
                         params.is_issue_key_enabled = scenarioCode.ISSUE_ID_ENABLED;

                     }
                 }else if(params.scenario){
                    let foundScenario = '';
                    _.find(GlobalService.inputs.tax_year.values,function(i){
                      if(i.value == params.tax_year){
                          foundScenario = _.find(i.scenarios,{scenario:params.scenario});
                      }
                    })
                    if(foundScenario && foundScenario.scenario_type_code){
                        params.scenarioCode = foundScenario.scenario_type_code;
                        params.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
                    }
                 }
               
                var promise = $http({ method: "post", url:_url,data:params}).then(function successCallback(response) {
                    return response.data;
                }, function errorCallback(response) {
                    AlertService.add("error", "oops  the requested data could not be saved. If this continues please contact support.");
                    return false;
                });


                return promise;


            };







            JsonObjectFactory.getJSONObj = function (_url,_params, filter, grp_key) {

                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var paramsMerge = _.merge({}, filterParams   , _params);
                var params = _.merge({}, GlobalService.globalParams, paramsMerge);
                delete params.checkLockedbj;
                var promise;

                ///////// TEMP HACK /////////////: initially scenario becomes null or undefined when we change the tax_year,so setting up the scenario
               if(filterParams.tax_year && filterParams.tax_year.length){
                   if(filterParams && filterParams.scenario == undefined || filterParams.scenario== null){
                    if(GlobalService.getScenario(params.tax_year)){
                        params.scenario = GlobalService.getScenario(params.tax_year);
                        }
                    
                   }
                   
                } 
                if(grp_key == 25){
                    var params = {
                        ein : GlobalService.getEIN(),
                        tax_year : GlobalService.getTAXYEAR(),
                        entityType : GlobalService.getChartType(),
                    };
                    if(filter.refresh_list !== null){
                        var refresh_list =  filter.refresh_list.split(',');
                        if ( refresh_list.length == 1){
                            params.bsla_key = filterParams.BSLA || 0;
                        }else if ( refresh_list.length == 2){
                            params.reporting_period = filterParams.Reporting_period || 0;
                            params.bsla_key = filterParams.BSLA || 0;
                        }
                    }
                    filterApiCall(_url, params);
                }else if (grp_key == 37){
                    var params = {
                        ein : GlobalService.getEIN(),
                        tax_year : GlobalService.getTAXYEAR(),
                        entityType : GlobalService.getChartType(),
                    };
                    params = _.merge(params, GlobalService.globalParams, paramsMerge);
                    params.bsla_key = params.bsla || 0;
                    params.leid = params.LEID || 0;
                    params.reporting_period = params.reporting_p || 0;
                    // if(filter.refresh_list !== null){
                    //     var refresh_list =  filter.refresh_list.split(',');
                    //     if ( refresh_list.length == 3){
                    //         params.bsla_key = filterParams.bsla || 0;
                    //     }else if ( refresh_list.length == 4){
                    //         params.leid = filterParams.LEID || 0;
                    //         params.reporting_period = filterParams.reporting_p || 0;
                    //         params.bsla_key = filterParams.bsla || 0;
                    //     }else if ( refresh_list.length == 5){
                    //         params.reporting_period = filterParams.reporting_p || 0;
                    //         params.bsla_key = filterParams.bsla || 0;
                    //     }
                    // }
                    filterApiCall(_url, params);
                }
                else{
                    filterApiCall(_url, params);
                }

                function filterApiCall(_url, params) {
                    promise = $http.get(_url,{params:params}).then(function successCallback(response) {
                        return response.data;
                    }, function errorCallback(response) {
                        if( response.status && response.status === -1 ){ // No Network
                            return false;
                        }
                        AlertService.add("error", "oops  the requested data could not be delivered. If this continues please contact support.");
                        return false;
                    });
                };
                return promise;
            };


            /**
             * Following function converts given object to Upper Case
             * Author: Mallesh
             * Since: 01/02/2018
             */
            JsonObjectFactory.objToUpper = function (_data) {

                var upperObj = _.transform(_data, function (result, val, key) {
                    result[key.toUpperCase()] = val;
                });


                return upperObj;
            }

            /**
             * Following function converts given array object to Upper Case
             * Author: Mallesh
             * Since: 01/02/2018
             */
            JsonObjectFactory.arrObjToUpper = function (_data) {

                var dataTrans = _.map(_data, function (x) {
                    var upperObj = _.transform(x, function (result, val, key) {
                        result[key.toUpperCase()] = val;
                    });
                    return upperObj;
                });
                return dataTrans;
            }


            JsonObjectFactory.objToLower = function (_data) {

                var lowerObj = _.transform(_data, function (result, val, key) {
                    result[key.toLowerCase()] = val;
                });


                return lowerObj;
            }


            JsonObjectFactory.arrObjToLower = function (_data) {

                var dataTrans = _.map(_data, function (x) {
                    var lowerObj = _.transform(x, function (result, val, key) {
                        result[key.toLowerCase()] = val;
                    });
                    return lowerObj;
                });
                return dataTrans;
            }



            JsonObjectFactory.saveJSONObj = function (_url,_data) {
                var defer = $q.defer();

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                console.log("___data",_data);
                var returnObj = {};
                var checkLockedbj = [];
                var rowData = workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.selected
                              && workspaceFactory.activeScreen.data.selected.rowData;
                if(_.isArray(rowData) && rowData.length>0){
                    _.each(rowData,function(item){
                        checkLockedbj.push({
                            "leid": item.data.LEID || item.data.SOURCE_LEID,//"IF1139",
                            "me_code": item.data.ME_CODE || item.data.ME || item.data.SOURCE_ME_CODE,
                            "cdr_number":item.data.CDR_NO || rowData.TAX_CODE || item.data.SOURCE_CDR_NO,
                            "consol_group_key": item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP,//1,
                            "reporting_period": item.data.REPORTING_PERIOD || item.data.COMPL_REPORTING_PERIOD,
                            "combination_key":item.data.COMBINATION_KEY || item.data.COMPL_COMBINATION_KEY || item.data.HO_COMBINATION_KEY, //1,
                            "scenario_key": item.data.SCENARIO_KEY ? item.data.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario
                        })
                    })
               }else if(rowData){
                    checkLockedbj.push({
                        "leid":rowData.LEID ||rowData.SOURCE_LEID ,//"IF1139",
                        "me_code": rowData.ME_CODE || rowData.ME || rowData.SOURCE_ME_CODE ,
                        "cdr_number":rowData.CDR_NO || rowData.TAX_CODE || rowData.SOURCE_CDR_NO ,
                        "consol_group_key": rowData.CONSOL_GROUP_KEY ||  rowData.FILING_GROUP,//1,
                        "reporting_period": rowData.REPORTING_PERIOD || rowData.SOURCE_REPORTING_PERIOD,
                        "combination_key":rowData.COMBINATION_KEY || rowData.COMPL_COMBINATION_KEY || rowData.HO_COMBINATION_KEY,
                    "scenario_key" : rowData.SCENARIO_KEY ? rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                    })

                }else{
                    checkLockedbj.push({
                        "scenario_key":  _data.scenario? _data.scenario : filterParams.scenario

                    })
                }
           
               
                _data.tax_year=  _data.tax_year ? _data.tax_year : filterParams.tax_year;
                _data.scenario = _data.scenario ? _data.scenario : filterParams.scenario;
                _data.jcd_key = GlobalService.globalParams.jcd_key;
                _data.issue_id = GlobalService.globalParams.issue_id;
                _data.issue_short_desc = GlobalService.globalParams.issue_short_desc;
                _data.checkLockedbj = JSON.stringify(checkLockedbj);

               

                let scenarioCode = '';
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.filters  && typeof workspaceFactory.activeScreen.filters.getFilters === "function"){
                    /*  scenarioCode =  workspaceFactory.activeScreen.filters.getFilters().length>1 ? 
                     _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected : '';//_.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
 
                     _data.scenarioCode = scenarioCode.CODE; */
                     let foundScenario =  _.find(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'});
                     if(foundScenario && foundScenario.selected){
                         scenarioCode = foundScenario.selected;
                         _data.scenarioCode = scenarioCode.CODE;
                         _data.is_issue_key_enabled = scenarioCode.ISSUE_ID_ENABLED;
                     }
                 }else if(_data.scenario){
                    let foundScenario = '';
                    _.find(GlobalService.inputs.tax_year.values,function(i){
                          if(i.value == _data.tax_year){
                              foundScenario = _.find(i.scenarios,{scenario:_data.scenario});
                          }
                      })
                      if(foundScenario && foundScenario.scenario_type_code){
                          _data.scenarioCode = foundScenario.scenario_type_code;
                          _data.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
                      }
                 }

                var promise =   $http({
                    method: "post",
                    url: _url,
                    data: {jsonObj:JSON.stringify(_data)}
                }).then(function (response) {
                    return response.data;
                });

                // Return the promise tso the controller
                return promise;
            };

            JsonObjectFactory.saveJSON = function (_url,_data) {
                var defer = $q.defer();

                var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
                console.log("___data",_data);
                var returnObj = {};
                var checkLockedbj = [];
                var rowData = workspaceFactory.activeScreen.data && workspaceFactory.activeScreen.data.selected
                              && workspaceFactory.activeScreen.data.selected.rowData;
                if(_.isArray(rowData) && rowData.length>0){
                    _.each(rowData,function(item){
                        checkLockedbj.push({
                            "leid": item.data.LEID || item.data.SOURCE_LEID,//"IF1139",
                            "me_code": item.data.ME_CODE || item.data.ME || item.data.SOURCE_ME_CODE,
                            "cdr_number":item.data.CDR_NO || rowData.TAX_CODE || item.data.SOURCE_CDR_NO,
                            "consol_group_key": item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP,//1,
                            "reporting_period": item.data.REPORTING_PERIOD || item.data.SOURCE_REPORTING_PERIOD,
                            "combination_key":item.data.COMBINATION_KEY || item.data.COMPL_COMBINATION_KEY || item.data.HO_COMBINATION_KEY, //1,
                            "scenario_key": item.data.SCENARIO_KEY ? item.data.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                        })
                    })
                }else if(rowData){
                    checkLockedbj.push({
                        "leid":rowData.LEID ||rowData.SOURCE_LEID,//"IF1139",
                        "me_code": rowData.ME_CODE || rowData.ME || rowData.SOURCE_ME_CODE ,
                        "cdr_number":rowData.CDR_NO || rowData.TAX_CODE || rowData.SOURCE_CDR_NO ,
                        "consol_group_key": rowData.CONSOL_GROUP_KEY || rowData.FILING_GROUP,//1,
                        "reporting_period": rowData.REPORTING_PERIOD || rowData.SOURCE_REPORTING_PERIOD,
                        "combination_key":rowData.COMBINATION_KEY || rowData.COMPL_COMBINATION_KEY || rowData.HO_COMBINATION_KEY,
                        scenario_key : rowData.SCENARIO_KEY ? rowData.SCENARIO_KEY  : _data.scenario? _data.scenario : filterParams.scenario

                    })
                } else{
                    checkLockedbj.push({
                        "scenario_key":  _data.scenario? _data.scenario : filterParams.scenario
                    })
                }
                if(workspaceFactory.activeScreen && (workspaceFactory.activeScreen.screen_key == 10305 
                    ||workspaceFactory.activeScreen.screen_key == 10072)){/* these screens have only filing group locking FIR*/
                    checkLockedbj = [];
                    checkLockedbj.push({consol_group_key: rowData.FILING_GROUP});                
                } 
                _data.tax_year=  _data.tax_year ? _data.tax_year : filterParams.tax_year;
                _data.scenario = _data.scenario ? _data.scenario : filterParams.scenario;
                _data.jcd_key = GlobalService.globalParams.jcd_key;
                _data.issue_id = GlobalService.globalParams.issue_id;
                _data.issue_short_desc = GlobalService.globalParams.issue_short_desc;
                _data.checkLockedbj = JSON.stringify(checkLockedbj);
     

                let scenarioCode = '';
                if(workspaceFactory.activeScreen && workspaceFactory.activeScreen.filters  && typeof workspaceFactory.activeScreen.filters.getFilters === "function"){
                   /*  scenarioCode =  workspaceFactory.activeScreen.filters.getFilters().length>1 ? 
                    _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected : '';//_.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
                    _data.scenarioCode = scenarioCode.CODE; */
                    let foundScenario =  _.find(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'});
                    if(foundScenario && foundScenario.selected){
                        scenarioCode = foundScenario.selected;
                        _data.scenarioCode = scenarioCode.CODE;
                        _data.is_issue_key_enabled = scenarioCode.ISSUE_ID_ENABLED;
                    }
                }else if(_data.scenario){
                   //let foundScenario = _.find(GlobalService.inputs.tax_year.values,{scenario:_data.scenario});
                  let foundScenario = '';
                  _.find(GlobalService.inputs.tax_year.values,function(i){
                        if(i.value == _data.tax_year){
                            foundScenario = _.find(i.scenarios,{scenario:_data.scenario});
                        }
                    })
                    if(foundScenario && foundScenario.scenario_type_code){
                        _data.scenarioCode = foundScenario.scenario_type_code;
                        _data.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
                    }
                }
              

                var promise = $http({ method: "post", url:_url,data:_data}).then(function successCallback(response) {
                    return response.data;
                }, function errorCallback(response) {
                    AlertService.add("error", "oops  the requested data could not be delivered. If this continues please contact support.");
                    return false;
                });


                return promise;

                // Return the promise tso the controller
            };

            return JsonObjectFactory;

        }])


        .factory("FileAttachementFactory",['GENERAL_CONFIG','AlertService',function(GENERAL_CONFIG,AlertService){
            var fileUploadConfig={
                attachmentTO : {
                      FILE_KEY: '',
                      FILE_NAME: '',
                      FILE_PATH: '',
                      FILE_TYPE: '',
                      FILE_SIZE: '',
                      CONTENT_TYPE: '',
                      ATTACHMENT_KEY: '',
                      isUpdateClicked:false,
                      comment_attachment_change_success: false,
                      saveClicked: false,logoFilesMarkedForDeletion: [],
                      datasource_connection_msg: '',datasource_connection_status: '',flow: {},
                      attachment_file: '',
                      attachmented_files:[],
                      stored_files:[]
                },failCount : 0,getClientLogoFileName : function (vm) {

                      if (angular.isUndefined(vm.attachmentTO.FILE_NAME) ||
                            (vm.attachmentTO.FILE_NAME == null) ||
                            (vm.attachmentTO.FILE_NAME == '')
                      ) {
                            return '';
                      }
                      return vm.attachmentTO.FILE_NAME.substr(vm.attachmentTO.FILE_NAME.lastIndexOf('/') + 1);

                },removeAttachment : function (vm,fileName) {
                      var objContext={
                            destDir:"temp",
                            flowFilename: fileName
                      };
                      vm.MessageFactory.CommentCrud(  GENERAL_CONFIG.base_url + '/removeAttachment', objContext).then(function (data) {
                            if (data.uploadStatus == 'callSuccess') {
                                  vm.attachmentTO.attachment_file = '';
                                  vm.attachmentTO.comment_attachment_change_success = true;
                                  vm.attachmentTO.attachment_file = '';
                                 try{
                                       vm.attachmentTO.flow.removeFile(_.find(vm.attachmentTO.flow.files,{name:fileName}));
                                  }catch(e){}
                                  vm.attachmentTO.attachmented_files = _.without(vm.attachmentTO.attachmented_files, fileName);
                                  if(vm.attachmentTO.stored_files && vm.attachmentTO.stored_files.length)
                                    vm.attachmentTO.stored_files=_.cloneDeep(vm.attachmentTO.attachmented_files);
                            } else {
                                  return;
                            }
                      }, function () {
                            AlertService.add("error", "Problem removing attachment.");
                      });
                },downloadAttachmentFile :function (fileName,comment_key,vm) {
                      if(vm.title=="Add Comment")
                            return;
                      var secondaryWindow = null;
                      if(comment_key)
                            secondaryWindow=window.open( GENERAL_CONFIG.base_url + '/downloadCommentAttachement?fileName='+fileName+"&commentKey="+commentKey+"&client_key="+sessionStorage.getItem('client_key'));
                      else
                            secondaryWindow=window.open( GENERAL_CONFIG.base_url + '/downloadTempCommentAttachement?fileName='+fileName+"&commentKey="+comment_key+"&client_key="+sessionStorage.getItem('client_key'));
                      if (secondaryWindow == null) {
                            return;
                      }
                      // Check if there was a problem downloading the logo.
                      secondaryWindow.onload = function () {
                            if (secondaryWindow.document.body.innerHTML.length > 0) {
                                  $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                                  secondaryWindow.close();
                            }
                      };
                },downloadAttachmentFile1 : function (fileName,parent_key) {
                    // if(fileName.indexOf(".pdf")>-1||fileName.indexOf(".jpg")>-1 ||
                    // fileName.indexOf(".jpeg")>-1||fileName.indexOf(".png")>-1 ||
                    // fileName.indexOf(".svg")>-1){
                    //       vm.previewModal(fileName);
                    //       return;
                    // }
                    var secondaryWindow = window.open(GENERAL_CONFIG.base_url + '/downloadCommentAttachement?fileName=' +fileName+"&commentKey="+parent_key+"&client_key="+sessionStorage.getItem('client_key'));
                    if (secondaryWindow == null) {
                          return;
                    }
                    // Check if there was a problem downloading the attachment.
                    secondaryWindow.onload = function () {
                          if (secondaryWindow.document.body.innerHTML.length > 0) {
                                $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                                secondaryWindow.close();
                          }
                    };
              },fileAdded : function (vm,file, event, flow) {
                      var fileExtension = file.getExtension().toLowerCase();
                      var allowedFileExtensions = ',jpg,jpeg,png,svg,pdf,xls,xlsx,txt,doc,docx,ico,ppt,pptx,ods,wpd,rtf';
                      var isAllowedFileExtension = (allowedFileExtensions.indexOf(',' + fileExtension + ',') >= 0);
                      var isFileSizeWithinLimit = (file.size <= vm.SERVER_CONFIG.configSettings.fileUploadMaxSize);
                      var isFileAdded = (isAllowedFileExtension && isFileSizeWithinLimit);
                      file.error = false;
                      file.message = '';
                      vm.attachmentTO.isError=false;
                      vm.attachmentTO.errorMessage='';
                      file.name =  file.name.replace(/[^a-zA-Z0-9\s.]/gi, '_');
                      var num = file.name.match(/\./g) !=null ? file.name.match(/\./g).length: 0; // check the periods in file
                      if(num == 1){
                        if (!isFileAdded ) {
                            if (!isAllowedFileExtension) {
                                  file.error = true;
                                  file.message = file.name + ' is invalid.';
                                  flow.cancel();
                                  vm.attachmentTO.isError=true;
                                  vm.attachmentTO.errorMessage=file.name + ' is invalid.';
                                  setTimeout(function(){vm.attachmentTO.isError=false;
                                        vm.attachmentTO.errorMessage='';},1000);
                                  return false;
                            }
                            if (!isFileSizeWithinLimit) {
                                  file.error = true;
                                  file.message = file.name + ' exceeds the allowed maximum size of ';
                                 // console.log(file.name + ' exceeds the allowed maximum size of ' + file.size);
                                  vm.attachmentTO.isError=true;
                                  vm.attachmentTO.errorMessage=file.name + ' exceeds the allowed maximum size of ';
                                  setTimeout(function(){vm.attachmentTO.isError=false;
                                        vm.attachmentTO.errorMessage='';},6000);
                                  flow.cancel();
                                  return false;
                            }
                        }
                      }else if(num ===0){
                        file.error = true;
                        vm.attachmentTO.isError=true;
                        file.message = 'Unsupported File';
                      }
                      else{
                          file.error = true;
                          vm.attachmentTO.isError=true;
                          file.message = file.name + ' is invalid, has multiple periods.';
                          //flow.cancel();
                      }
                },setFiles : function (vm,files, flow) {
                    var x = false;
                    var xy = [];
                    _.each(vm.attachmentTO.attachmented_files,
                        function(item,key){
                            /* var fileName ="";
                            fileName =  (files[0] && files[0].length==0) ? files[0].name.replace(/[^a-zA-Z0-9\\+.]/gi, '_') :
                                            flow.files[0].file.name.replace(/[^a-zA-Z0-9\\+.]/gi, '_');
                            if(_.isEqual(item,fileName)){
                                x = true;
                                xy.push(item);
                            } */

                            if((files && files.length>0 || (flow  && flow.files && flow.files.length>0))){
                                var arr = files && files.length>0 ? files: flow.files;
                                _.each(arr,function(i){
                                    var fn = i && i.name !=undefined ? i.name :i.file.name.replace(/[^a-zA-Z0-9\s.]/gi, '_');
                                    if(_.isEqual(item,fn)){
                                        x = true;
                                        xy.push(item);
                                    }
                                })
                            }
                    })

                   //if(vm.attachmentTO && vm.attachmentTO.attachmented_files.indexOf(flow.files[0].file.name)>-1){
                  if(xy && xy.length>0){
                        AlertService.add("warning","Same file name exist, if the file has changes,please delete the exiting document and re-upload or else rename the file",4000);
                  }else  if ((files[0] && files[0].error == false && files[0].error != undefined)) {
                        flow.upload();
                    }else if((files[0] && files[0].error == true && files[0].message != undefined)) {
                        _.remove(flow.files,{name:files[0].name});
                         vm.attachmentTO.stored_files= _.uniq(vm.attachmentTO.attachmented_files);
                        AlertService.add("warning",files[0].message,4000);
                    }
                //     else{
                //        AlertService.add("warning","You have already uploaded the same file name",4000);
                //   }
                },setErrorFile : function (vm,file, message, flow) {
                      file.error = true;
                       file.message = file.name + ' is invalid';
                       AlertService.add("warning","You have already uploaded the same file name",4000);//or exceeds allowed maximum size of ' + FileSizeService.readableFileSize(SERVER_CONFIG.configSettings.fileUploadMaxSize);
                      flow.cancel();
                },fileSuccess : function (vm,file, message, flow) {
                      var parsedMessage = JSON.parse(message);
                      if (parsedMessage.uploadStatus == 'success') {
                            vm.attachmentTO.logoFilesMarkedForDeletion.push(vm.attachmentTO.attachment_file);
                            var fileName = parsedMessage.flowFilename;
                            var fileParam = {
                                  name: fileName
                            };
                            //vm.attachmentTO.attachment_file = 'upload/' + tempFolder + '/' + fileName;
                            //'uploaded_attachments/' + '/' +
                            vm.attachmentTO.attachment_file = fileName;
                            if(vm.attachmentTO.stored_files.length==0){
                                  vm.attachmentTO.attachmented_files= _.uniq(_.map(flow.files,"name"));
                            }else{
                                  vm.attachmentTO.attachmented_files= _.cloneDeep(vm.attachmentTO.stored_files);
                                  vm.attachmentTO.attachmented_files = vm.attachmentTO.attachmented_files.
                                  concat(_.uniq(_.map(flow.files,"name")));
                                  vm.attachmentTO.attachmented_files= _.uniq(vm.attachmentTO.attachmented_files);
                            }
                            vm.attachmentTO.attachment_file_change_success = true;
                            vm.attachmentTO.logoFilesMarkedForDeletion = _.without(vm.attachmentTO.logoFilesMarkedForDeletion, vm.attachmentTO.attachment_file);
                      } else if (parsedMessage.uploadStatus == 'error') {
                            file.error = true;
                            file.message = parsedMessage.message;
                            AlertService.add("error",parsedMessage.errorMessage,4000);
                      }
                },dragEnter : function (vm) {
                      vm.style = {
                            border: '2px solid #37d631'
                      };
                },
                dragLeave : function (vm) {
                      vm.style = {};
                }
            };
            var FileAttachementFactory={};
            FileAttachementFactory.fileUploadConfig=fileUploadConfig;
            FileAttachementFactory.newAttachmentCopy=function(){
                FileAttachementFactory=angular.copy(fileUploadConfig);
            }
            return FileAttachementFactory;
        }])

        .factory("CommonUtilitiesFactory",['$q', '$http', '$log', 'JsonObjectFactory','GlobalService','SERVER_CONFIG','GENERAL_CONFIG', function($q, $http, $log, JsonObjectFactory, GlobalService, SERVER_CONFIG, GENERAL_CONFIG) {
            var commonUtilityService = {};
           commonUtilityService.loadCommonData = function() {
                var params = {};
                //console.log("SERVER_CONFIG----", SERVER_CONFIG);
               /* params.tax_year = GlobalService.inputs.tax_year.selected.value;
                params.scenario = GlobalService.inputs.tax_year.selected.scenario;
                params.action_code= "f5sne6";
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(function (data) {
                    commonUtilityService.filingGroups = data.jsonObject;
                    return commonUtilityService.filingGroups;
                });*/
            }


            return commonUtilityService;
        }])

        .factory("MessageService",['GENERAL_CONFIG','USER_SETTINGS','AlertService','$rootScope','$window',
            function(GENERAL_CONFIG,USER_SETTINGS,AlertService,$rootScope,$window) {
            var messageService = {};
            var stompClients = [];
            // var endPoint = GENERAL_CONFIG.base_url + '/gtw-websockets';
            var connectionEstablished = false;
            var defaultChannels = [
             //   '/notify/'+USER_SETTINGS.user.sso_id
            ];
            var subscriptions = [];
            var subscriptionRequestQueue = [];
            var retryCount = 0;
            // var state = {};
            const DISCONNECTED = "disconnected";
            const CONNECTING = "connecting";
            const CONNECTED = "connected";

            var SockJS = require('SockJS');

            function processRequestQueue (stompClient, endpoint) {
                for (var key in _.filter(subscriptionRequestQueue,["endPoint",endpoint])) {
                    var request = subscriptionRequestQueue.shift(key);
                    var subscription = stompClient.client.subscribe(request.channel, request.callback);
                    stompClient.subscriptions.push({
                        endPoint: request.endPoint,
                        channel: request.channel,
                        subscription: subscription
                     });
                }
            }

            messageService.establishConnection = function(endPoint, connEstablishedCallback) {
                var client = _.find(stompClients, function (stompClient) {
                    return stompClient.endPoint === endPoint;
                });

                if (client && client.state === CONNECTED) {
                    console.log('Connection already established.');
                    return;
                }
//                if (endPoint && USER_SETTINGS.user.user_type_key === 5) {
                    // setTimeout(function() { Added delay to mock network latency in local
                    try{
                        var socket = new SockJS(endPoint + '?georaclehrid='+USER_SETTINGS.user.sso_id);
                        let stompClient = Stomp.over(socket);
                        client && (client.state = CONNECTING);
                        let clientObj = client || {
                            endPoint:endPoint,
                            client:stompClient,
                            subscriptions:[],
                            state:CONNECTING
                        };
                        !client && stompClients.push(clientObj);
                        stompClient.connect({}, function (frame) {
                            console.log("Socket connection Established ", frame);
                            if (typeof(connEstablishedCallback) === 'function')
                                connEstablishedCallback(clientObj);
                            connectionEstablished = true;
                            retryCount = 0;
                            processRequestQueue(clientObj,endPoint);
                            // for (var index in defaultChannels) {
                            //     messageService.subscribeToChannel(defaultChannels[index]);

                            //     console.log("Subsribed to " + defaultChannels[index]);
                            // }

                            $window.onbeforeunload  = function() {
                                messageService.unsubscribeAllChannels();
                            }

                            clientObj.state = CONNECTED;
                        }, function(message) {
                            clientObj.state = DISCONNECTED;
                            // check message for disconnect
                            if(retryCount >= 3){
                                //AlertService.add("error","Some thing went wrong with the socket connection. Try refreshing to page.");
                                $rootScope.$apply();
                                // AlertService.add("error", "The server is not responding. Redirecting to login page!");
                                // window.location = GENERAL_CONFIG.redirect_url;
                                // setTimeout(function(){window.location.href = GENERAL_CONFIG.redirect_url;},1000);
                                return;
                            }
                            retryCount++;
                            console.log("Attempting to re-establish the lost connection " + retryCount+ "/3");


                            messageService.establishConnection(endPoint, connEstablishedCallback);
                        });

                        
                    // },10000);
                    }catch(error){
                        console.error(error);
                    }
//                } else {
//                    console.log('------------>Socket connection skipped because user is not a super admin.');
//                }
            }

            messageService.sendMessageToChannel = function(channel, payload) {
                console.log("Message send to channel: " + channel);
                 stompClient.send(channel, {}, payload);

            }
            messageService.subscribeToChannelWithCallback = function(endPoint, channel, callback,stompClient) {
                if(!stompClient) {
                    stompClient = _.find(stompClients, function (stompClient) {
                        return stompClient.endPoint === endPoint;
                    });

                    if(!stompClient){
                        console.log("Please establish a connection before trying to subscribe!!");
                        return;
                    }
                };
                //if (USER_SETTINGS.user.user_type_key === 5) {
                    if(stompClient.state === CONNECTING 
                        && _.findIndex(subscriptionRequestQueue,["channel",channel]) === -1){
                        subscriptionRequestQueue.unshift({
                            endPoint: endPoint,
                            channel: channel,
                            callback: callback
                        });
                        return;
                    }else if(stompClient.state === DISCONNECTED) return;
                    
                    var sub = _.find(stompClient.subscriptions, function (subscription) {
                        return subscription.endPoint === endPoint && subscription.channel === channel;
                    });
                    if (sub) {
                        console.log("already subscribed to this channel", endPoint + channel);
                        return;
                    }

                    var subscription = stompClient.client.subscribe(channel, callback);
                    stompClient.subscriptions.push({
                        endPoint: endPoint,
                        channel: channel,
                        subscription: subscription
                    });

                //}
            }
            messageService.subscribeToChannel = function(stompClient,channel) {

                var sub = _.find(stompClient.subscriptions, ['channel', channel]);
                if (sub) {
                    console.log("already subscribed to this channel",channel);
                    return;
                }

                //if (USER_SETTINGS.user.user_type_key === 5) {

                    var subscription = stompClient.subscribe(channel, function(payload) {
                        var notification = JSON.parse(payload.body);
                        AlertService.add(notification.type, notification.notificationMessage,4000);
                        $rootScope.$broadcast('pushNotifications', JSON.parse(payload.body));
                        $rootScope.$apply();
                    });
                    subscriptions.push({
                        channel:channel,
                        subscription: subscription
                    });

                //}
            }

            messageService.unsubscribeAllChannels = function() {
                _.each(stompClients,function(client) {
                    for (var i = 0; i < client.subscriptions.length; i++) {
                        client.subscriptions[i].subscription.unsubscribe();
                    }
                })
                
            }

            messageService.unsubscribeChannel = function(client,channel) {

                    var channelToUnsubscribe = client.subscriptions.find(function(item) {
                        return item.channel === channel;
                    })
                    if( channelToUnsubscribe){
                        channelToUnsubscribe.subscription.unsubscribe();
                        console.log("channel  " + channel + " is unsubscribed.");
                    }

            }
            return messageService;

        }])

        .factory('notificationService',["GENERAL_CONFIG",
        "AlertService",
        "$http",
        function notificationService (GENERAL_CONFIG,
            AlertService,
            $http) {
            var notificationService = {};
            var URLS = {
                GET_NOTIFICATIONS_BY_USER : GENERAL_CONFIG.base_url + "/getUserNotifications?ssoId=",
                MARK_NOTIFICATIONS_AS_READ : GENERAL_CONFIG.base_url + "/markUserNotificationsAsRead?notificationKeys="
            }

            notificationService.getNotifications = function(ssoId) {

                var promise = $http({
                    method: "POST",
                    url: URLS.GET_NOTIFICATIONS_BY_USER + ssoId,
                }).then(function (response) {
                    if(response.data.callSuccess === "0" ){
                        AlertService.add("error", response.data.errorMessage);
                    }
                    return response.data;
                });
                return promise;
            }

            notificationService.markUserNotificationsAsRead = function(notificationKeys) {

                var promise = $http({
                    method: "POST",
                    url: URLS.MARK_NOTIFICATIONS_AS_READ + notificationKeys,
                }).then(function (response) {
                    if(response.data.callSuccess === "0" ){
                        AlertService.add("error", response.data.errorMessage);
                    }
                    return response.data;
                });
                return promise;
            }

            return notificationService;

        }])

        .factory("ClientSwitch",['GENERAL_CONFIG','USER_SETTINGS','AlertService','$rootScope','$window','GlobalService','$uibModal',
            function(GENERAL_CONFIG,USER_SETTINGS,AlertService,$rootScope,$window,GlobalService,$uibModal) {

                let service = {};

                service.changeClientAlert = function(clientKey, oldLocation){
                    
                    $uibModal.open({
                        animation: true,
                        templateUrl: 'app/templates/modals/client-backbutton-action.html',
                        controller: ['$scope', '$stateParams', '$uibModalInstance',
                            function ($scope, $stateParams, $uibModalInstance) {
                                var selected_client = clientKey;
                                var audio = new Audio('assets/sound/voice_alert.mp3');
                                audio.play();
                                $scope.cancel = function () {
                                    // failedToChangeClient();
                                    $rootScope.isBrowserBackButton = false;
                                    history.forward();
                                    $uibModalInstance.close();
                                }

                                $scope.confirm = function () {
                                   

                                    $uibModalInstance.close();
                                    location.reload(true);
                                    // GlobalService.setClient(selected_client.client_key).then(function (data) {
                                    //     if (data.callSuccess === "1") {
                                    //         $rootScope.donotLogoutUser =true; // shouldn't logout on reload flag used in GlobalController
                                    //         location.reload();
                                    //     } else {
                                    //         failedToChangeClient();
                                    //     }
                                    // });

                                    // function failedToChangeClient() {
                                    //     history.forward();
                                    // }

                                    // $uibModalInstance.close();
                                }

                            }
                        ],
                        size: 'md',
                        backdrop: 'static'
                    });                       
                };

                return service;

        }])
    });
